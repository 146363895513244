import React, { useState, useEffect } from 'react';
import { Box, Button } from '@mui/material';
import styled from 'styled-components';
import { AWS_BUCKET_URL } from './../Config';

const Img = styled.img`
width:100px;
height:100px;
object-fit:cover;
border-radius:50%;
margin-right:30px;
`
const ImgIconBox = styled.div`
width:100px;
height:100px;
border-radius:50%;
background-color:#f5f5f5;
margin-right:30px;
display:flex;
align-items:center;
justify-content:center;
`

function ImageUploader(props) {
    const [ImgSource, setImgSource] = useState('');

    useEffect(() => {
        if (!!props.imgSrc && props.isEditMode) {
            setImgSource(AWS_BUCKET_URL + props.imgSrc)
        }
    }, [props.imgSrc]);

    const handleCHange = (e) => {
        if (e.target.files.length > 0) {
            var src = URL.createObjectURL(e.target.files[0]);
            setImgSource(src);
            props.setUserImage(e.target.files[0]);
        }
    }
    const clearImg = () => {
        setImgSource('');
    }

    return (
        <>
            <Box display="flex" justifyContent="start" alignItems="center" sx={{ px: 3, pt: 1 }} className="flex-between-mob px-0-mob flex-col-center-sx">
                <div>
                    {!!ImgSource ?
                        <Img src={ImgSource} width='100' />
                        :
                        <ImgIconBox>
                            <img src='/images/imagePalceholder.png' width='40' />
                        </ImgIconBox>
                    }

                </div>
                <div>
                    <input
                        accept="image/*"
                        style={{ display: 'none' }}
                        id="raised-button-file"
                        type="file"
                        onChange={(e) => handleCHange(e)}
                    />
                    {!!ImgSource ?
                        <Button sx={{ mr: 2 }} className='btn-light mb-img-uploader' onClick={clearImg}>
                            Bild löschen
                        </Button>
                        :
                        ""
                    }
                    <label htmlFor="raised-button-file">
                        <Button component="span" className='btn-light mt-10px-sx'>
                            Avatar auswahlen
                        </Button>
                    </label>
                </div>
            </Box>
        </>
    );
}

export default ImageUploader;