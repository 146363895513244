import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    attachmentAddition:{},
    attachmentDeleted: null,
    commentAddOrEdit:{},
    commentDelete:null,
    mentionedAttachment:{},
    replyComment:{},
    updatedDescription:{}
}

export const commentSlice = createSlice({
  name: 'comment',
  initialState,
  reducers: {
    setAttachmentAddition: (state, action) => {
      state.attachmentAddition = action.payload
    },
    setAttachmentDeleted: (state, action) => {
      state.attachmentDeleted = action.payload
    },
    setCommentAddOrEdit: (state, action) => {
      state.commentAddOrEdit = action.payload
    },
    setCommentDelete: (state, action) => {
      state.commentDelete = action.payload
    },
    setMentionedAttachment: (state, action) => {
      state.mentionedAttachment = action.payload
    },
    setReplyComment: (state, action) => {
      state.replyComment = action.payload
    },
    setUpdatedDescription: (state, action) => {
      state.updatedDescription = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setAttachmentAddition, setAttachmentDeleted, setCommentAddOrEdit, setCommentDelete, setMentionedAttachment, setReplyComment, setUpdatedDescription } = commentSlice.actions;

export default commentSlice.reducer