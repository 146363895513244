import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import DoneIcon from '@mui/icons-material/Done';
import CloseIconWrap from '../CloseIconWrap';
import { DialogContentWrapper } from './WarDieAntwort';

export const GedecktWrap = styled.div`
padding-left:4.4%;
padding-right:4.4%;
`
const Avatar = styled.div`
border-radius:13px;
background-color:#e7eceb;
width:45px;
height:45px;
color:#63c2a6;
`
const AvatarCross = styled.div`
border-radius:13px;
background-color:#fff1f9;
width:45px;
height:45px;
color:#d8315e;
`
function RechenzentrumGedecktModal(props) {

    const { setPurchaseRequest, setOpenAmountCovered, setMakeCall, openAmountCovered, setShowClearPaymentCheckbox } = props;

    const onAccept = () => {
        setPurchaseRequest(true);
        setOpenAmountCovered(false);
        setShowClearPaymentCheckbox(false);
        setMakeCall(true);
    }

    const onReject = () => {
        setPurchaseRequest(true);
        setOpenAmountCovered(false);
        setShowClearPaymentCheckbox(true);
        setMakeCall(true);
    }

    return (
        <>
            <div>
                <Dialog
                    fullWidth={true}
                    maxWidth={'sm'}
                    open={openAmountCovered}
                    onClose={() => setOpenAmountCovered(false)}
                    className="antwort-width-600"
                    aria-labelledby="responsive-dialog-title"
                >
                    <div className='closeInner-btn' onClick={() => setOpenAmountCovered(false)}>
                        <CloseIconWrap />
                    </div>
                    <DialogContentWrapper>
                        <GedecktWrap>
                            <DialogTitle id="responsive-dialog-title" align="center" sx={{ pb: 0, letterSpacing: '0.0em', paddingLeft: '0px', paddingRight: '0px' }} className="antwort-title">
                                {"War der Betrag beim Rechenzentrum gedeckt?"}
                            </DialogTitle>
                            <DialogContent>
                                <Typography variant="subtitle2" align="center" className='subtitle-modal'>
                                    Ansonsten muss eine Vorabzahlung vereinbart werden
                                </Typography>
                                <DialogContentText sx={{ pt: 5 }}>
                                    <List sx={{ bgcolor: 'background.paper' }}>
                                        <ListItem alignItems="flex-start" className='list-bg flex-col-mob-center' onClick={() => onAccept()}>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <DoneIcon className='cross-icon m-icon' />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <div className='text-center-mob'>
                                                <ListItemText
                                                    className='badge-text-20'
                                                    primary="Anfrage wurde akzeptiert"
                                                />
                                                <Typography
                                                    sx={{ lineHeight: 0.6 }}
                                                    component="span"
                                                    color="text.secondary"
                                                    className='fs-17'
                                                >
                                                    Der Betrag wurde beim Rechenzentrum gedeckt
                                                </Typography>
                                            </div>
                                        </ListItem>
                                        <ListItem alignItems="flex-start" className='list-bg flex-col-mob-center' onClick={() => onReject()}>
                                            <ListItemAvatar>
                                                <AvatarCross>
                                                    <CloseIcon className='cross-icon m-icon' />
                                                </AvatarCross>
                                            </ListItemAvatar>
                                            <div className='text-center-mob'>
                                                <ListItemText
                                                    className='badge-text-20'
                                                    primary="Anfrage wurde abgelehnt"
                                                />
                                                <Typography
                                                    sx={{ lineHeight: 0.6 }}
                                                    component="span"
                                                    color="text.secondary"
                                                    className='fs-17'
                                                >
                                                    Martina Zorn muss eine Vorabzahlung leisten
                                                </Typography>
                                            </div>
                                        </ListItem>
                                    </List>
                                </DialogContentText>
                            </DialogContent>
                        </GedecktWrap>
                    </DialogContentWrapper>
                </Dialog>
            </div>
        </>
    )
}
export default RechenzentrumGedecktModal;