import { React, useState } from 'react';
import styled from 'styled-components';
import { ChatHead, ChatHeadBox, DisplayFlex, ChatBack } from './ChatWhatsApp';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { setWelcome, setSameNumber, setChatList, setWhatsAppChat, setChatSMS, setchatNotContact } from '../../Redux/Slice/chatSlice';
import { useDispatch } from 'react-redux';
import { DropdownWrapper } from '../HeaderDropdownWrapper';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { ChatAvatarBox, ChatTitle } from './ChatSMS';
import ChatConsent from '../Dialogs/ChatConsentModal';
import Avatar from '../Avatar';
import { Box } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

const ChatContactBox = styled.div`
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
height:450px;
`
const ChatContactTitle = styled.h4`
margin:15px 0px;
`
const ChatContactText = styled.div`
color:#BDBABD;
text-align:center;
font-size:15px;
`

function ChatNotContact() {
    const [ConsentModal, setConsentModal] = useState(false);
    let dispatch = useDispatch();
    const handleBackClick = () => {
        dispatch(setWelcome(false))
        dispatch(setChatList(true))
        dispatch(setWhatsAppChat(false))
        dispatch(setSameNumber(false))
        dispatch(setChatSMS(false))
        dispatch(setchatNotContact(false))
    }
    return (
        <>
            <ChatHead>
                <ChatHeadBox>
                    <DisplayFlex>
                        <ChatBack>
                            <NavigateBeforeIcon onClick={handleBackClick} />
                        </ChatBack>
                        <ChatAvatarBox>
                            <Avatar noMargin usernameProps="Peter Beispiel" className="table" width={21} height={21} />
                        </ChatAvatarBox>
                        <ChatTitle>
                            Peter Beispiel
                        </ChatTitle>
                        <ReportProblemIcon sx={{ color: '#AB9892', fontSize: '22px' }} />
                    </DisplayFlex>
                    <Box display="flex" justifyContent="center" alignItems="center">
                        <DropdownWrapper InnerText="Einverstandnis andem" bodyType={"ChatAction"} userId="" setConsentModal={setConsentModal} noarrow iconBg={false} icon={<MoreHorizIcon style={{ color: '#AB9892' }} />} />
                    </Box>
                </ChatHeadBox>
            </ChatHead>
            <ChatContactBox>
                <img alt="Not Contact" src='/images/ChatEye.png' />
                <ChatContactTitle>
                    Patient:in kann nicht kontaktiert werden
                </ChatContactTitle>
                <ChatContactText>
                    Damit Abdo Alraei von dir im Chat kontaktiert werden kann, muss eine gültige Telefonnummer in deiner PVS hinterlegt sein.
                </ChatContactText>

            </ChatContactBox>

            <ChatConsent isShow={ConsentModal} hideConsentModal={setConsentModal} />
        </>
    );
}

export default ChatNotContact;