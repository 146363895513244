import { createSlice } from '@reduxjs/toolkit'
const initialState = {
    welcome: true,
    chatList: true,
    whatsAppChat: false,
    sameNumber: false,
    chatSMS: false,
    chatNotContact: false,
    chatDisable: false,
    openChat: false,
    groupChat: false,
    selectedConversationId: null,
    messageReceive: {},
    selectedTemplateId: null,
    templateToUse: null,
    selectedNonConversation: {}
}
export const chatSlice = createSlice({
    name: 'chat',
    initialState,
    reducers: {
        setWelcome: (state, action) => {
            state.welcome = action.payload;
        },
        setChatList: (state, action) => {
            state.chatList = action.payload;
        },
        setWhatsAppChat: (state, action) => {
            state.whatsAppChat = action.payload;
        },
        setSameNumber: (state, action) => {
            state.sameNumber = action.payload;
        },
        setChatSMS: (state, action) => {
            state.chatSMS = action.payload;
        },
        setchatNotContact: (state, action) => {
            state.chatNotContact = action.payload;
        },
        setChatDisable: (state, action) => {
            state.chatDisable = action.payload;
        },
        setOpenChat: (state, action) => {
            state.openChat = action.payload;
        },
        setGroupChat: (state, action) => {
            state.groupChat = action.payload;
        },
        setSelectedConversationId: (state, action) => {
            state.selectedConversationId = action.payload;
        },
        setMessageReceive: (state, action) => {
            state.messageReceive = action.payload;
        },
        setSelectedTemplateId: (state, action) => {
            state.selectedTemplateId = action.payload;
        },
        setTemplateToUse: (state, action) => {
            state.templateToUse = action.payload;
        },
        setSelectedNonConversation: (state, action) => {
            state.selectedNonConversation = action.payload;
        },
    }
});

export const { setWelcome, setChatList, setWhatsAppChat, setSameNumber, setChatSMS, setchatNotContact, setChatDisable, setOpenChat, setGroupChat, setSelectedConversationId,
    setMessageReceive, setSelectedTemplateId, setTemplateToUse, setSelectedNonConversation } = chatSlice.actions;
export default chatSlice.reducer;
