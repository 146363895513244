import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
import { Box, Button } from '@mui/material';
import { LinkItem } from './AttachmentCommentList';
import BadgeLabelComment from './BadgeLabelComment';
import TextboxSaveBtn from './TextboxSaveBtn';
import moment from "moment";
import jwt_decode from "jwt-decode";
import { useCookies } from 'react-cookie';
import { EditBtnWrapper } from './Dialogs/NotesModal';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from '@mui/icons-material/Delete';
import ReplyIcon from '@mui/icons-material/Reply';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { dynamicGetApi, dynamicPostApi } from './../Api';
import { setReplyComment } from '../Redux/Slice/commentSlice';
import { AWS_BUCKET_URL } from './../Config'

const Description = styled.p`
font-Weight:400 !important;
color:#7c7c7c;
margin-top:8px;
`
const Ulist = styled.ul`
margin: 0px;
display: inline-flex;
padding-left: 25px;
color:#7c7c7c;
font-size:16px;
& .hoverblue:hover{
    color:#120ec5
}
`
const ItemBox = styled.div`
display: block;
width: 97%;
padding: 0.375rem 0.75rem;
background-clip: padding-box;
-webkit-appearance: none;
-moz-appearance: none;
appearance: none;
transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
font-size:15px;
background-color: #ffffff;
box-shadow:var(--ds-shadow-raised,0 1px 2px -1px #091e4240,0 0 0 1px var(--ds-border,#091e4214));
`
const DescriptionCommt = styled.p`
font-Weight:400 !important;
color:#7c7c7c;
margin:0px !important;

& a.userMentioned{
    padding-left:5px;
background:#eee;
color:#000;
padding:2px 8px;
border-radius:14px;
margin: 5px 5px 5px 3px;
display:inline-block;
font-size:12px;
}
`
const NameHeader = styled.div`
display:flex;
align-items:center;
justify-content:start;
`
const UserBadge = styled.a`
padding-left:5px;
background:#eee;
color:#000;
padding:2px 8px;
border-radius:14px;
margin: 5px 5px 5px 3px;
display:inline-block;
font-size:12px;
`
const AttachmentLinks = styled.a`
padding-right:5px;
`

function CommentTextBadge(props) {
    const [cookies, setCookie, removeCookie] = useCookies(['authToken']);
    let dispatch = useDispatch();
    let navigate = useNavigate();

    const { data, index, editOrUnEditComment, planId, userList } = props;

    const deleteComment = async (commentId) => {
        let url = "api/comment/deleteComment?commentId=" + commentId;
        let res = await dynamicGetApi(url, navigate, setCookie, dispatch);
    }

    const mentionedUser = (comment, attachmentList) => {
        let prevString = comment;
        var s = comment;
        var re = /(?:^|\W)@(\w+)(?!\w)/g, match, matches = [];
        while (match = re.exec(s)) {
            matches.push(match[1]);
        }
        if (matches.length > 0) {
            matches.map(x => {
                prevString = prevString.replace(`@${x}`, `<a class="userMentioned">@${x}</a>`)
            }
            )
        }
        var a = prevString;
        //var rea = /(?:^|\W)~(\w+)(?!\w)/g, matcha, matchesa = [];
        var rea = /(?:\[)(.*?)(?=\])/g, matcha, matchesa = [];
        while (matcha = rea.exec(a)) {
            matchesa.push(matcha[1]);
        }
        if (matchesa.length > 0) {
            matchesa.map((x, i) => {
                prevString = prevString.replace(`[${x}]`, `<a href=${AWS_BUCKET_URL + attachmentList[i].key} target="_blank">${x}</a>`)
            }
            )
        }
        return prevString
    }

    return (
        <>
            <Box>
                <div className='d-flex ' container spacing={0}>
                    <div>
                        <div className='mt-18'>
                            <BadgeLabelComment letter={data.userName} />
                        </div>
                    </div>
                    <div className='flex-1'>
                        <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ pt: 2 }} className="flex-between-mob px-0-mob flex-col-center-sx">
                            <NameHeader>
                                <div className='d-flex align-items-center justify-content-start'>
                                    <ListItemText
                                        className='badge-text-20 text-center-mob'
                                        sx={{ mr: 1 }}
                                        primary={data.userName}
                                    />
                                </div>
                                <div>
                                    <Typography variant='p'>
                                        <Description className='mt-0px'>{moment(new Date(data.createdAt)).format("MMM DD")} at {moment(new Date(data.createdAt)).format("hh:mm A")}</Description>
                                    </Typography>
                                </div>
                                {!!data.updatedAt && <span style={{ paddingLeft: "2px" }}>{"(edited)"}</span>}
                            </NameHeader>
                            <Box display="flex" justifyContent="start" alignItems="center" sx={{ pt: 1 }} className="flex-between-mob px-0-mob flex-col-center-sx">
                                <div>
                                    <Ulist className='p-0px'>
                                        {parseInt(jwt_decode(cookies.authToken).UserID) !== data.createdBy ?
                                            <>
                                                <div className='comment-action mr-5px' onClick={() => dispatch(setReplyComment(data))}>
                                                    <div className="">
                                                        <EditBtnWrapper>
                                                            <ReplyIcon fontSize='inherit' className='hoverblue' />
                                                        </EditBtnWrapper>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <>
                                                <div className='comment-action mr-8px' onClick={() => editOrUnEditComment(index)}>
                                                    <EditBtnWrapper>
                                                        <BorderColorIcon fontSize='inherit' className='hoverblue' />
                                                    </EditBtnWrapper>
                                                </div>
                                                <div className='comment-action' onClick={() => deleteComment(data.id)}>
                                                    <EditBtnWrapper>
                                                        <DeleteIcon fontSize='inherit' className='hoverblue' />
                                                    </EditBtnWrapper>
                                                </div>
                                            </>
                                        }
                                    </Ulist>
                                </div>
                            </Box>
                        </Box>
                        {data.isEditable === true ? <TextboxSaveBtn commentData={data} index={index} editOrUnEditComment={editOrUnEditComment} planId={planId} userList={userList} /> :
                            <ItemBox>
                                <div>
                                    {/* {data.attachments.map(x =>
                                        <AttachmentLinks href=''>{x.name}</AttachmentLinks>
                                    )} */}
                                    <Typography variant='p' component='p'>
                                        <DescriptionCommt dangerouslySetInnerHTML={{ __html: mentionedUser(data.comment, data.attachments) }}>
                                            {/* {mentionedUser(data.comment)} */}

                                            {/* {data.users.map(x =>
                                                <UserBadge>@{x.userName.toLowerCase()}</UserBadge>
                                            )} */}
                                        </DescriptionCommt>
                                    </Typography>
                                </div>
                            </ItemBox>
                        }
                    </div>
                </div>
            </Box>
        </>
    )
}
export default CommentTextBadge;

export const EditableTextbox = () => {

    const [previewText, setPreviewText] = useState('');
    const [showPreview, setShowPreview] = useState(false);

    const handleReplyClick = () => {
        setPreviewText('This is a preview text.');
        setShowPreview(true);
    };

    const handleTextChange = (event) => {
        setPreviewText(event.target.value);
    };

    return (
        <>
            <div>
                <LinkItem href="javascript:void(0)" onClick={handleReplyClick}>Loschen</LinkItem>
                {showPreview ?
                    <textarea value={previewText} onChange={handleTextChange} />
                    :
                    <ItemBox>
                        <div>
                            <div>
                                <Button className='btn-light-tag practiceSetting-btn-mt'>@essdevui</Button>
                            </div>
                            <Typography variant='p' component='p'>
                                <DescriptionCommt>some updates for the backend proyect.</DescriptionCommt>
                            </Typography>
                        </div>
                    </ItemBox>
                }
            </div>
        </>
    )
}