import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import ModalLeftSidebar from './ModalLeftSide';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DoneIcon from '@mui/icons-material/Done';
import Checkboxes from '../Inputs/Checkboxes';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import TodayIcon from '@mui/icons-material/Today';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import TimeLineItem from '../TimeLineItem';
import { CloseLeftCornor } from '../CloseIconWrap';
import { dynamicGetApi, dynamicPostApi } from './../../Api';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import WarDieAntwortModal from './WarDieAntwort';
import BeispielGesagtModal from './BeispielGesagtModal';
import PlanAbgelehntModal from './PlanAbgelehntModal';
import WiedervorlageSetzenModal from './WiedervorlageSetzenModal';
import AntwortDerKasseModal from './AntwortDerKasseModal';
import BehandlungZugestimmtModal from './BehandlungZugestimmtModal';
import { setDisableButton, setRecall, setDelayPlan } from './../../Redux/Slice/commonSlice';
import { CheckedIcon, UnCheckedIcon } from '../Inputs/Checkboxes';
import BoltIcon from '@mui/icons-material/Bolt';
import Checkbox from '@mui/material/Checkbox';
import NotesModal, { EditBtn, EditBtnWrapper, EditBtnPosition } from './NotesModal';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import CustomRadio from '../Inputs/Radio';
import RodioButton from '../RodioButton';
import CommentsModal from './CommentsModal';

const Avatar = styled(Stack)(() => ({
    borderRadius: '13px',
    backgroundColor: '#e9e6ee',
    width: '45px',
    height: '45px',
    color: '#120ec5',
    marginTop: '0px',
}));

function ZustimmungModal(props) {
    const checkboxJsonL1 = [
        {
            label: 'HKP an Pat übergeben',
            value: '1',
            child: [
                {
                    label: 'HKP bei Pat',
                    value: 'HKP bei Pat',
                    isChecked: false,
                    isDisabled: false
                },
                {
                    label: 'HKP zu KK geschickt (von Pat)',
                    value: '2',
                    isChecked: false,
                    isDisabled: false
                }
            ],
            isChecked: false,
            isDisabled: false
        },
        {
            label: 'HKP bei KK (von Praxis geschickt)',
            value: '3',
            isChecked: false,
            isDisabled: false
        }
    ]
    const checkboxJsonL2 = [
        {
            label: 'Genehmigt',
            value: 'Genehmigt',
            child: [
                {
                    label: 'Bei Patient',
                    value: '4',
                    isChecked: false,
                    isDisabled: false
                },
                {
                    label: 'In Praxis',
                    value: '5',
                    isChecked: false,
                    isDisabled: false
                }
            ],
            isChecked: false,
            isDisabled: false
        },
        {
            label: 'Abgelehnt',
            value: '7',
            isChecked: false,
            isDisabled: true
        }
    ]
    const [cookies, setCookie, removeCookie] = useCookies(['authToken', 'selectedVersion']);
    let navigate = useNavigate();
    let dispatch = useDispatch();

    const { handleClose, planId } = props;

    const [warDieAntwortOpen, setWarDieAntwortOpen] = useState(false);
    const [modalData, setModalData] = useState({});
    const [ifPlanImplementCheck, setIfPlanImplementCheck] = useState(false);
    const [isPlanSent, setIsPlanSent] = useState(false);
    const [selectedAction, setSelectedAction] = useState("");
    const [beispielGesagt, setBeispielGesagt] = useState(false);
    const [isFundApproved, setIsFundApproved] = useState(false);
    const [abgelehnt, setAbgelehnt] = useState(false);
    const [rejectionAppealed, setRejectionAppealed] = useState(false);
    const [approvalAvailable, setApprovalAvailable] = useState(false);
    const [areInPractice, setAreInPractice] = useState(false);
    const [wieder, setWieder] = useState(false);
    const [makeCall, setMakeCall] = useState(false);
    const [enterApprovalDate, setEnterApprovalDate] = useState(false);
    const [antwort, setAntwort] = useState(false);
    const [zugestimmt, setZugestimmt] = useState(false);
    const [consentGranted, setConsentGranted] = useState(false);
    const [kvaSigned, setKvaSigned] = useState(false);
    const [showNotesMoadel, setShowNotesMoadel] = useState(false);
    const [checkboxJSONl1, setcheckboxJSONl1] = useState(checkboxJsonL1);
    const [checkboxJSONl2, setcheckboxJSONl2] = useState(checkboxJsonL2);
    const [gutachterCheck, setGutachterCheck] = useState(false);
    const [wirdAngefochtenCheck, setWirdAngefochtenCheck] = useState(false);
    const [comment, setComment] = useState(false);
    const [version, setVersion] = useState('');

    let delayPlan = useSelector(state => state.common.delayPlan);

    useEffect(() => {
        if (!!planId) {
            getData(planId);
        }
    }, [planId]);

    useEffect(() => {
        if (selectedAction === "Send Plan") {
            setIsPlanSent(true);
        }
        else if (selectedAction === "No Change") {
            setIsPlanSent(false);
        }
        else {
            setIsPlanSent(false);
        }
    }, [selectedAction]);

    useEffect(() => {
        if (ifPlanImplementCheck) {
            valueChange("ifPlanImplementCheck", ifPlanImplementCheck);
        }
    }, [ifPlanImplementCheck]);

    useEffect(() => {
        if (rejectionAppealed) {
            valueChange("isFundApproved", rejectionAppealed);
        }
    }, [rejectionAppealed]);

    useEffect(() => {
        if (approvalAvailable) {
            valueChange("approvalAvailable", approvalAvailable);
        }
    }, [approvalAvailable]);

    useEffect(() => {
        if (areInPractice) {
            valueChange("areInPractice", areInPractice);
            setEnterApprovalDate(true);
            dispatch(setDisableButton(false));
        }
    }, [areInPractice]);

    useEffect(() => {
        if (makeCall) {
            getData(planId);
        }
    }, [makeCall]);

    useEffect(() => {
        if (!!cookies.selectedVersion) {
            setVersion(cookies.selectedVersion);
        }
        else {
            setVersion("old");
        }
    }, [cookies.selectedVersion]);

    useEffect(() => {
        if (Object.keys(modalData).length > 0 && modalData.private_Plan && modalData.kva_unterschrieben && modalData.consentGranted) {
            dispatch(setDisableButton(false));
        }
        else{
            dispatch(setDisableButton(true));
        }
    }, [modalData]);

    useEffect(() => {
        if(enterApprovalDate){
            dispatch(setDisableButton(false));
        }
    }, [enterApprovalDate]);

    const getData = async (planId) => {
        let url = "api/kanban/getKanbanItemById?itemId=" + planId;
        let res = await dynamicGetApi(url, navigate, setCookie, dispatch);
        debugger
        setModalData(res.data);
        setGutachterCheck(res.data.gutachter);
        setWirdAngefochtenCheck(res.data.widerspruch);
        progressChange(res.data);
    }

    const postKanbanData = async (data) => {
        let url = "api/kanban/updateKanbanItem";
        let res = await dynamicPostApi(data, url, navigate, setCookie, dispatch);
        setMakeCall(false);
    }

    const checkChanges = (call) => {
        if (modalData.progress === 1) {
            setWarDieAntwortOpen(true);
        }
    }

    const valueChange = (call) => {
        let newData = {};
        if (call === "ifPlanImplementCheck") {
            newData = { ...modalData, progress: 2 };
        }
        if (call === "isFundApproved") {
            newData = { ...modalData, progress: 8 };
        }
        if (call === "approvalAvailable") {
            newData = { ...modalData, progress: 4 };
        }
        if (call === "areInPractice") {
            newData = { ...modalData, progress: 5 };
        }
        if (call === "consentGranted") {
            newData = { ...modalData, consentGranted: true };
        }
        if (call === "kvaSigned") {
            newData = { ...modalData, kva_unterschrieben: true };
        }
        if (call === "disablePlan") {
            newData = { ...modalData, isPlanDisable: true, requiredAttentionDate: null };
        }
        if (call === "delayPlan") {
            newData = { ...modalData, weidervorlage: delayPlan, requiredAttentionDate: null };
        }
        if (call === "kva_unterschrieben") {
            newData = { ...modalData, kva_unterschrieben: modalData.kva_unterschrieben ? false : true };
        }
        if (call === "consentGranted") {
            newData = { ...modalData, consentGranted: modalData.consentGranted ? false : true };
        }
        if (call === "terminationDone") {
            newData = { ...modalData, terminationDone: modalData.terminationDone ? false : true };
        }
        if (call === "gutachterCheck") {
            newData = { ...modalData, gutachter: !gutachterCheck };
            setGutachterCheck(!gutachterCheck);
            progressChange(newData);
        }
        if (call === "wirdAngefochtenCheck") {
            newData = { ...modalData, widerspruch: !wirdAngefochtenCheck };
            setWirdAngefochtenCheck(!wirdAngefochtenCheck);
            progressChange(newData);
        }
        postKanbanData(newData);
        setModalData(newData);
        if (call === "delayPlan" || call === "disablePlan") {
            dispatch(setRecall(true));
            if (call === "delayPlan") {
                dispatch(setDelayPlan(2));
            }
        }
        if (call === "kvaSigned") {
            dispatch(setDisableButton(false));
            setKvaSigned(true);
        }
    }

    const progressChange = (data) => {
        if (data.progress === 5) {
            setEnterApprovalDate(true);
            dispatch(setDisableButton(false));
        }
        if (data.progress === 1) {
            let record = checkboxJSONl1.filter(x => x.value == data.progress);
            record[0].isChecked = true;
            let childList = record?.filter(x => x.value == '1')[0]?.child?.filter(x => x.value == "HKP bei Pat");
            childList[0].isChecked = true;
            setcheckboxJSONl1([...checkboxJSONl1])
        } else if (data.progress === 2) {
            let prevState = [...checkboxJSONl1];
            let record = prevState.filter(x => x.value == '1');
            record[0].isChecked = true;
            let childList = prevState?.filter(x => x.value == '1')[0]?.child?.filter(x => x.value == data.progress);
            childList[0].isChecked = true;
            setcheckboxJSONl1(prevState);
        } else if (data.progress === 3) {
            let record = checkboxJSONl1.filter(x => x.value == data.progress);
            record[0].isChecked = true;
            setcheckboxJSONl1([...checkboxJSONl1]);
        } else if (data.progress === 6) {
            setGutachterCheck(true);
            setcheckboxJSONl1(checkboxJsonL1);
        } else if (data.progress === 4) {
            let prevState = [...checkboxJSONl2];
            let record = prevState.filter(x => x.value == 'Genehmigt');
            record[0].isChecked = true;
            let childList = prevState?.filter(x => x.value == 'Genehmigt')[0]?.child?.filter(x => x.value == data.progress);
            childList[0].isChecked = true;
            setcheckboxJSONl2(prevState);
        } else if (data.progress === 5) {
            let prevState = [...checkboxJSONl2];
            let record = prevState.filter(x => x.value == 'Genehmigt');
            record[0].isChecked = true;
            let childList = prevState?.filter(x => x.value == 'Genehmigt')[0]?.child?.filter(x => x.value == data.progress);
            childList[0].isChecked = true;
            setcheckboxJSONl2(prevState);
        } else if (data.progress === 7) {
            let record = checkboxJSONl2.filter(x => x.value == data.progress);
            record[0].isChecked = true;
            record[0].isDisabled = false;
        } else if (data.progress === 8) {
            let prevState = [...checkboxJSONl2];
            let record = prevState.filter(x => x.value == '7');
            record[0].isChecked = true;
            record[0].isDisabled = false;
            setcheckboxJSONl2(prevState);
            setWirdAngefochtenCheck(true);
        }
        setConsentGranted(data.consentGranted);
        setKvaSigned(data.kva_unterschrieben);
    }

    const onChangePublicCheckbox = (e) => {
        let newData = {};
        if (e.target.value !== "HKP bei Pat" && e.target.value !== "Genehmigt") {
            newData = { ...modalData, progress: e.target.value };
            if (e.target.value !== "7" && e.target.value !== "4" && e.target.value !== "5") {
                setcheckboxJSONl2(checkboxJsonL2);
            }
            else {
                let prevState = [...checkboxJSONl2];
                let record = prevState.filter(x => x.value == 'Genehmigt');
                record[0].isChecked = false;
                let childList = prevState?.filter(x => x.value == 'Genehmigt')[0]?.child;
                childList[0].isChecked = false;
                childList[1].isChecked = false;
                let rec = prevState?.filter(x => x.value == '7');
                rec[0].isChecked = e.target.value !== '7' ? false : true;
                rec[0].isDisabled = false;
                setcheckboxJSONl2(prevState);
            }
        } else if (e.target.value === "Genehmigt") {
            if (modalData.progress === 1 || modalData.progress === 2 || modalData.progress === 6) {
                newData = { ...modalData, progress: 4 };
            }
            if (modalData.progress === 3) {
                newData = { ...modalData, progress: 5 };
            }
            if (modalData.progress === 7 || modalData.progress === 8) {
                newData = { ...modalData, progress: 4 };
            }
            let prevState = [...checkboxJSONl2];
            let rec = prevState?.filter(x => x.value == '7');
            rec[0].isChecked = false;
            rec[0].isDisabled = false;
            setcheckboxJSONl2(prevState);
        } else if (e.target.value === "HKP bei Pat") {
            newData = { ...modalData, progress: 1 };
            setcheckboxJSONl2(checkboxJsonL2);
        }
        setGutachterCheck(false);
        setWirdAngefochtenCheck(false);
        postKanbanData(newData);
        setcheckboxJSONl1(checkboxJsonL1);
        //setcheckboxJSONl2(checkboxJsonL2);
        setMakeCall(true);
    }

    return (
        <>
            <WarDieAntwortModal patientName={modalData.name} open={warDieAntwortOpen} setOpen={setWarDieAntwortOpen} setIfPlanImplementCheck={setIfPlanImplementCheck} setSelectedAction={setSelectedAction} valueChange={valueChange} />
            <BeispielGesagtModal valueChange={valueChange} patientName={modalData.name} open={beispielGesagt} setOpen={setBeispielGesagt} setAbgelehnt={setAbgelehnt} />
            <PlanAbgelehntModal valueChange={valueChange} setOpen={setAbgelehnt} open={abgelehnt} setRejectionAppealed={setRejectionAppealed} setIsFundApproved={setIsFundApproved} />
            <WiedervorlageSetzenModal valueChange={valueChange} setOpen={setWieder} open={wieder} />
            <AntwortDerKasseModal setOpen={setAntwort} open={antwort} valueChange={valueChange} />
            <BehandlungZugestimmtModal valueChange={valueChange} patientName={modalData.name} setOpen={setZugestimmt} open={zugestimmt} setConsentGranted={setConsentGranted} />
            {comment && <CommentsModal setOpen={setComment} open={comment} planId={modalData.id} />}
            {/* {showNotesMoadel && <NotesModal planId={modalData.id} openNotesMoadel={showNotesMoadel} setOpenNotesModal={setShowNotesMoadel} />} */}
            <div>
                <Grid container spacing={4} className="grid-main-zustimm">
                    <Grid item md={4} sm={12} xs={12} className="grid-4-col-zustimm">
                        <div onClick={handleClose}>
                            <CloseLeftCornor />
                        </div>
                        <ModalLeftSidebar modalData={modalData} dispatch={dispatch} />
                    </Grid>
                    <Grid item md={8} sm={12} xs={12} className='model-p-mob scrollbar-zustimmung'>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography variant="h4" component="div" className='title-model-right'>
                                Zustimmung
                            </Typography>
                            <div className="EditBtnPlan-zustimmung">
                                <EditBtnWrapper className='mright-15'>
                                    <EditBtn onClick={() => setComment(true)}>
                                        <BorderColorIcon />
                                    </EditBtn>
                                </EditBtnWrapper>
                            </div>
                        </Box>
                        {modalData.isPlanDisable !== true ? version === "old" && <>
                            {modalData.progress === 1 && consentGranted !== true && <Typography variant="h6" component="span" className='desc-model-right'> Plan wurde an {modalData.name} übergeben </Typography>}
                            {modalData.progress === 2 && consentGranted !== true && <Typography variant="h6" component="span" className='desc-model-right'> Plan liegt bei der Krankenkasse </Typography>}
                            {modalData.progress === 1 && modalData.private_Plan && consentGranted && kvaSigned !== true && <Typography variant="h6" component="span" className='desc-model-right'> {modalData.name} stimmt der Behandlung zu </Typography>}
                            {modalData.progress === 1 && modalData.eHkp &&
                                <ListItem sx={{ pl: 0 }}>
                                    <FormGroup>
                                        <FormControlLabel sx={{ marginLeft: '10px' }} control={<Checkboxes checked={ifPlanImplementCheck} onChange={() => checkChanges("ifPlanImplementCheck")} sx={{ fontSize: 28 }} />}
                                            label="Persönlich fragen, ob der Plan durch*führt werden soll" />
                                    </FormGroup>
                                </ListItem>}
                            {modalData.progress === 2 && modalData.eHkp &&
                                <ListItem sx={{ pl: 0 }}>
                                    <FormGroup>
                                        <FormControlLabel sx={{ marginLeft: '10px' }} control={<Checkboxes checked={isFundApproved} onChange={() => setBeispielGesagt(true)} sx={{ fontSize: 28 }} />}
                                            label="Persönlich fragen, ob die Kasse den Plan schon genehmigt hat Vergiss die Privatvereinbarungen und Anlagen nicht" />
                                    </FormGroup>
                                </ListItem>}
                            {modalData.progress === 5 && modalData.eHkp &&
                                <ListItem sx={{ pl: 0 }}>
                                    <FormGroup>
                                        <FormControlLabel sx={{ marginLeft: '10px' }} control={<Checkboxes checked={enterApprovalDate} sx={{ fontSize: 28 }} />}
                                            label="Genehmigungsdatum in dampsoft eintragen" />
                                    </FormGroup>
                                </ListItem>}
                            {modalData.private_Plan && consentGranted !== true && <ListItem sx={{ pl: 0 }}>
                                <FormGroup>
                                    <FormControlLabel sx={{ marginLeft: '10px' }} control={<Checkboxes checked={consentGranted} onChange={() => setZugestimmt(true)} sx={{ fontSize: 28 }} />}
                                        label={"Bei " + modalData.name + " erkundigen, ob es offene Fragen gibt"} />
                                </FormGroup>
                            </ListItem>}
                            {modalData.private_Plan && consentGranted === true && kvaSigned !== true && <ListItem sx={{ pl: 0 }}>
                                <FormGroup>
                                    <FormControlLabel sx={{ marginLeft: '10px' }} control={<Checkboxes checked={kvaSigned} onChange={() => valueChange("kvaSigned")} sx={{ fontSize: 28 }} />}
                                        label={"Behandlung für " + modalData.name + " terminieren Erinnere " + modalData.name + " daran, den unterschriebenen KV mitzubringen"} />
                                </FormGroup>
                            </ListItem>}
                            {/* <ListItem sx={{ pl: 0 }}>
                                <FormGroup>
                                    <FormControlLabel sx={{ marginLeft: '10px' }} control={<Checkboxes defaultChecked sx={{ fontSize: 28 }} />}
                                        label={modalData.name + " kontaktieren und darum bitten, den Plan und alle Unterlagen Praxis zu bringen"} />
                                </FormGroup>
                            </ListItem> */}
                            <Box className='d-flex flex-col-btn'>
                                {modalData.progress < 3 && modalData.eHkp && <Button className='btn-light' sx={{ mr: 0.8 }} onClick={() => setAntwort(true)}>Antwort der Kasse liegt vor</Button>}
                                {modalData.progress === 1 && modalData.eHkp && <Button className='btn-light' onClick={() => valueChange("ifPlanImplementCheck")}>Plan wurde an die Kasse geschickt</Button>}
                                {modalData.private_Plan && consentGranted !== true && <Button className='btn-light' onClick={() => setZugestimmt(true)}>{modalData.name} stimmt der Behandlung zu</Button>}
                                {/* <Button className='btn-light' onClick={() => valueChange('areInPractice')}>Plan Unterlagen eingetroffen</Button> */}
                            </Box>
                            {modalData.progress === 8 && modalData.eHkp &&
                                <List sx={{ pr: 1.5 }}>
                                    <div className='mb-3'>
                                        <Typography variant="h6" component="span" className='desc-model-right'>
                                            Plan Wird angefochten
                                        </Typography>
                                    </div>
                                    <ListItem alignItems="flex-start" className='list-bundle' onClick={() => setApprovalAvailable(true)}>
                                        <ListItemAvatar sx={{ mt: 0 }}>
                                            <div>
                                                <Avatar>
                                                    <DoneIcon className='cross-icon m-icon' />
                                                </Avatar>
                                            </div>
                                        </ListItemAvatar>
                                        <div className='d-block'>
                                            <ListItemText
                                                className='list-bundle-text'
                                                primary="Genehmigung liegt vor"
                                            />
                                            <Typography
                                                sx={{ lineHeight: 0.6 }}
                                                component="span"
                                                color="text.secondary"
                                                className='fs-17'
                                            >
                                                Oer Ablehnung wurde erfolgreich angefochten und die Behandlung kann
                                            </Typography>
                                        </div>
                                    </ListItem>
                                    <ListItem alignItems="flex-start" className='list-bundle' onClick={() => valueChange('disablePlan')}>
                                        <ListItemAvatar sx={{ mt: 0 }}>
                                            <div>
                                                <Avatar>
                                                    <TodayIcon className='cross-icon m-icon' />
                                                </Avatar>
                                            </div>
                                        </ListItemAvatar>
                                        <div className='d-block'>
                                            <ListItemText
                                                className='list-bundle-text'
                                                primary="Plan deaktivieren"
                                            />
                                            <Typography
                                                sx={{ lineHeight: 0.6 }}
                                                component="span"
                                                color="text.secondary"
                                                className='fs-17'
                                            >
                                                Sobald du den Plan deaktivierst, ist er in HKP-Hero nicht mehr sichtbar.
                                            </Typography>
                                        </div>
                                    </ListItem>
                                    <ListItem alignItems="flex-start" className='list-bundle' onClick={() => setWieder(true)}>
                                        <ListItemAvatar sx={{ mt: 0 }}>
                                            <div>
                                                <Avatar>
                                                    <PriorityHighIcon className='cross-icon m-icon' />
                                                </Avatar>
                                            </div>
                                        </ListItemAvatar>
                                        <div className='d-block'>
                                            <ListItemText
                                                className='list-bundle-text'
                                                primary="Ablehnung Wird weiterhin angefochten"
                                            />
                                            <Typography
                                                sx={{ lineHeight: 0.6 }}
                                                component="span"
                                                color="text.secondary"
                                                className='fs-17'
                                            >
                                                Der Plan Wird auf Wiedervorlage gesetzt und HKP-Hero fragt dich bald nach dem Status.
                                            </Typography>
                                        </div>
                                    </ListItem>
                                </List>
                            }
                            {modalData.progress === 4 && modalData.eHkp &&
                                <List sx={{ pr: 1.5 }}>
                                    <div className='mb-3'>
                                        <Typography variant="h6" component="span" className='desc-model-right'>
                                            Plan liegt genehmigt bei {modalData.name}
                                        </Typography>
                                    </div>
                                    <ListItem alignItems="flex-start" className='list-bundle' onClick={() => setAreInPractice(true)}>
                                        <ListItemAvatar sx={{ mt: 0 }}>
                                            <div>
                                                <Avatar>
                                                    <DoneIcon className='cross-icon m-icon' />
                                                </Avatar>
                                            </div>
                                        </ListItemAvatar>
                                        <ListItemText
                                            className='list-bundle-text'
                                            primary="Plan und Unterlagen Sind in der Praxis"
                                        />
                                    </ListItem>
                                    <ListItem alignItems="flex-start" className='list-bundle' onClick={() => setWieder(true)}>
                                        <ListItemAvatar sx={{ mt: 0 }}>
                                            <div>
                                                <Avatar>
                                                    <CloseIcon className='cross-icon m-icon' />
                                                </Avatar>
                                            </div>
                                        </ListItemAvatar>
                                        <div className='d-block'>
                                            <ListItemText
                                                className='list-bundle-text'
                                                primary="Nein, es sind noch nicht alle Dokumente da"
                                            />
                                            <Typography
                                                sx={{ lineHeight: 0.6 }}
                                                component="span"
                                                color="text.secondary"
                                                className='fs-17'
                                            >
                                                Der Plan Wird auf Wiedervorlage gesetzt und HKP-Hero fragt dich bald nach dem Status.
                                            </Typography>
                                        </div>
                                    </ListItem>
                                </List>}
                        </> :
                            <>
                                <ListItem sx={{ pl: 0 }}>
                                    <FormGroup>
                                        <FormControlLabel sx={{ marginLeft: '10px' }} disabled control={<Checkbox style={{ fontSize: 28, padding: "5px 10px 5px 5px" }} checkedIcon={<CheckedIcon />} icon={<UnCheckedIcon><BoltIcon sx={{ fontSize: '22px', color: '#ffb800' }} className='thunder-check' /></UnCheckedIcon>} />} label="Plan deaktivieren" />
                                    </FormGroup>
                                </ListItem>
                            </>}
                        {modalData.eHkp && version === 'new' && <>
                            <ListItem sx={{ pl: 0 }} className="new-version">
                                <RodioButton radioList={checkboxJSONl1} onChange={onChangePublicCheckbox} />
                            </ListItem>
                            <ListItem sx={{ p: 0 }} className="new-version">
                                <FormGroup>
                                    <FormControlLabel sx={{ marginLeft: '10px' }} control={<Checkboxes disabled={modalData.progress < 4 || modalData.progress === 6 ? false : true} checked={gutachterCheck} onChange={() => valueChange("gutachterCheck")} sx={{ fontSize: 28 }} />}
                                        label="Gutachter" />
                                </FormGroup>
                            </ListItem>
                            <ListItem sx={{ pl: 0, pb: 0 }} className="new-version">
                                <RodioButton radioList={checkboxJSONl2} onChange={onChangePublicCheckbox} />
                            </ListItem>
                            <ListItem sx={{ pt: 0, pb: 2 }} className="new-version border-bottom">
                                <FormGroup sx={{ pl: 4 }}>
                                    <FormControlLabel sx={{ marginLeft: '10px' }} control={<Checkboxes disabled={modalData.progress > 6 ? false : true} checked={wirdAngefochtenCheck} onChange={() => valueChange("wirdAngefochtenCheck")} sx={{ fontSize: 28 }} />}
                                        label="Wird angefochten" />
                                </FormGroup>
                            </ListItem>
                        </>}
                        {(modalData.private_Plan || modalData.eHkp) && version === 'new' && <>
                            <ListItem sx={{ pl: 0, pt: 2, pb: 0 }}>
                                <FormGroup>
                                    <FormControlLabel sx={{ marginLeft: '10px' }} control={<Checkboxes checked={modalData.kva_unterschrieben} onChange={() => valueChange("kva_unterschrieben")} sx={{ fontSize: 28 }} />}
                                        label="KVA und Anlagen unterschrieben" />
                                </FormGroup>
                            </ListItem>
                            <ListItem sx={{ p: 0 }}>
                                <FormGroup>
                                    <FormControlLabel sx={{ marginLeft: '10px' }} control={<Checkboxes checked={modalData.consentGranted} onChange={() => valueChange("consentGranted")} sx={{ fontSize: 28 }} />}
                                        label="Patient stimmt der Behandlung zu" />
                                </FormGroup>
                            </ListItem>
                            {/* <ListItem sx={{ p: 0 }}>
                                <FormGroup>
                                    <FormControlLabel sx={{ marginLeft: '10px' }} control={<Checkboxes checked={modalData.terminationDone} onChange={() => valueChange("terminationDone")} sx={{ fontSize: 28 }} />}
                                        label="Patient hat Termin" />
                                </FormGroup>
                            </ListItem> */}
                        </>}

                        {!!modalData && Object.keys(modalData).length > 0 && <TimeLineItem modalData={modalData} />}
                        {/* <List>
                            <ListItem alignItems="flex-start" sx={{ pl: 0, pb: 0 }}>
                                <ListItemAvatar className="check-labels">
                                    <Checkboxes defaultChecked sx={{ pt: 0, padding: 0 }} />
                                </ListItemAvatar>
                                <div>
                                    <ListItemText
                                        className='check-title'
                                        component="span"
                                        primary="Behandlung fur Wolfgang Schafer terminieren"
                                    />
                                    <Typography
                                        sx={{ lineHeight: 0.6 }}
                                        component="span"
                                        color="text.secondary"
                                        className='fs-17'
                                    >
                                        Erinnere Wolfgang Schafer daran, den unterschriebenen KV mitzubringen
                                    </Typography>
                                </div>
                            </ListItem>
                        </List> */}
                    </Grid>
                </Grid>
            </div>
        </>
    )
}
export default ZustimmungModal;