import React, { useState, useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import ForgetPassword from './ForgetPassword';
import EmailConfirmation from './EmailConfirmation';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { loginApi } from './../Api';
import { useDispatch, useSelector } from 'react-redux';


function Login() {
    const [cookies, setCookie, removeCookie] = useCookies(['authToken']);
    let dispatch = useDispatch();
    let navigate = useNavigate();
    let logBtn = useRef();
    const [OpenForgetPass, setOpenForgetPass] = useState(false);
    const [OpenEmailConf, setOpenEmailConf] = useState(false);

    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        validationSchema: Yup.object().shape({
            email: Yup.string().required("Please enter email"),
            password: Yup.string().required("Please enter Password")
        }),
        onSubmit: values => {
            loginApi(values, setCookie, navigate, setCookie, dispatch);
        },
    });

    useEffect(() => {
        onEnter();
    }, []);
    const onEnter = () => {
        document.addEventListener("keydown", function (event) {
            if (event.keyCode === 13) {
                logBtn.current.click();
            }
        })
    }

    const loginCard = {
        boxShadow: '1px 1px 20px #0000001f',
    }
    const allModelClose = () => {
        setOpenForgetPass(false);
        setOpenEmailConf(false);
    }
    return (
        <>
            <Container component="main" maxWidth="xs" className='w-md-550px login-mt'>
                <CssBaseline />
                <Card maxWidth="xs" style={loginCard} sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    borderRadius: '16px',
                }}>
                    <CardContent>
                        <Box>
                            <Box component="form" noValidate sx={{ mt: 1, px: 1 }}>
                                <Typography>
                                    <Box sx={{ fontSize: 'h3.fontSize', textAlign: 'center', fontWeight: '700', color: '#120ec5', my: 4 }} className="Login-Heading">HKP-Hero</Box>
                                </Typography>
                                <FormLabel id="email">E-Mail</FormLabel>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    placeholder='E-Mail'
                                    id="email"
                                    name="email"
                                    size="small"
                                    type="email"
                                    sx={{ mb: 2, height: '45px' }}
                                    error={formik.errors.email && formik.touched.email ? true : false}
                                    helperText={formik.errors.email && formik.touched.email ? formik.errors.email : ""}
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                />

                                <FormLabel id="password">Passwort</FormLabel>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    placeholder='Passwort'
                                    name="password"
                                    type="password"
                                    id="password"
                                    size="small"
                                    sx={{ height: '45px' }}
                                    error={formik.errors.password && formik.touched.password ? true : false}
                                    helperText={formik.errors.password && formik.touched.password ? formik.errors.password : ""}
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                />

                                <Button
                                    type="button"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 6, mb: 3, py: '10px', borderRadius: '20px', backgroundColor: '#120ec5', textTransform: 'capitalize', "&:hover": { backgroundColor: '#090699' } }}
                                    ref={logBtn}
                                    onClick={formik.handleSubmit}
                                >
                                    Einloggen
                                </Button>
                                <Box sx={{ display: 'flex', justifyContent: 'center', color: '#120ec5', mb: 3 }}>
                                    <Link variant="body2" className='over-underline' onClick={() => setOpenForgetPass(true)} >
                                        Passwort vergressen?
                                    </Link>
                                </Box>
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
            </Container >
            <ForgetPassword isShow={OpenForgetPass} hideResetModal={() => setOpenForgetPass(false)} showEmailConf={() => setOpenEmailConf(true)} />
            <EmailConfirmation isShow={OpenEmailConf} hideEmailConfModal={() => setOpenEmailConf(false)} closeAll={() => allModelClose()} />
        </>
    );
}

export default Login;