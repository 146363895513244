import React, {useState, useEffect } from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';


function TimeLineItem(props) {
const {modalData} = props;

    const TimeLineProgress1 = [
        {
            Text: 'Plan wurde an ' + modalData.name + ' übergeben',
            statusCheck: 'done',
            colorText: 'black-text',
            connectionBorder: 'light',
        },
        {
            Text: 'Plan wurde an ' + modalData.name + ' übergeben',
            statusCheck: 'done',
            colorText: 'black-text',
            connectionBorder: 'light',
        },
        {
            Text: 'Plan liegt bei der Krankenkasse',
            statusCheck: 'around',
            colorText: 'gray',
            connectionBorder: 'dark',
        },
        {
            Text: 'Plan liegt genehmigt bel ' + modalData.name,
            statusCheck: 'around',
            colorText: 'gray',
            connectionBorder: 'dark',
        },
        {
            Text: 'Plan liegt genehmigt in der Praxis',
            statusCheck: 'around',
            colorText: 'gray',
            connectionBorder: 'dark',
        }
    ]
    const TimeLineProgress2 = [
        {
            Text: 'Plan wurde an ' + modalData.name + ' übergeben',
            statusCheck: 'done',
            colorText: 'black-text',
            connectionBorder: 'light',
        },
        {
            Text: 'Plan wurde an ' + modalData.name + ' übergeben',
            statusCheck: 'done',
            colorText: 'black-text',
            connectionBorder: 'light',
        },
        {
            Text: 'Plan liegt bei der Krankenkasse',
            statusCheck: 'done',
            colorText: 'black-text',
            connectionBorder: 'light',
        },
        {
            Text: 'Plan liegt genehmigt bel ' + modalData.name,
            statusCheck: 'around',
            colorText: 'gray',
            connectionBorder: 'dark',
        },
        {
            Text: 'Plan liegt genehmigt in der Praxis',
            statusCheck: 'around',
            colorText: 'gray',
            connectionBorder: 'dark',
        }
    ]
    const TimeLineProgress8 = [
        {
            Text: 'Plan wurde an ' + modalData.name + ' übergeben',
            statusCheck: 'done',
            colorText: 'black-text',
            connectionBorder: 'light',
        },
        {
            Text: 'Plan wurde an ' + modalData.name + ' übergeben',
            statusCheck: 'done',
            colorText: 'black-text',
            connectionBorder: 'light',
        },
        {
            Text: 'Plan liegt bei der Krankenkasse',
            statusCheck: 'done',
            colorText: 'black-text',
            connectionBorder: 'light',
        },
        {
            Text: 'Plan Wird angefochten',
            statusCheck: 'cross',
            colorText: 'black-text',
            connectionBorder: 'light',
        },
        {
            Text: 'Plan liegt genehmigt bel ' + modalData.name,
            statusCheck: 'around',
            colorText: 'gray',
            connectionBorder: 'dark',
        },
        {
            Text: 'Plan liegt genehmigt in der Praxis',
            statusCheck: 'around',
            colorText: 'gray',
            connectionBorder: 'dark',
        }
    ]
    const TimeLineProgress4 = [
        {
            Text: 'Plan wurde an ' + modalData.name + ' übergeben',
            statusCheck: 'done',
            colorText: 'black-text',
            connectionBorder: 'light',
        },
        {
            Text: 'Plan wurde an ' + modalData.name + ' übergeben',
            statusCheck: 'done',
            colorText: 'black-text',
            connectionBorder: 'light',
        },
        {
            Text: 'Plan liegt bei der Krankenkasse',
            statusCheck: 'done',
            colorText: 'black-text',
            connectionBorder: 'light',
        },
        {
            Text: 'Plan Wird angefochten',
            statusCheck: 'cross',
            colorText: 'black-text',
            connectionBorder: 'light',
        },
        {
            Text: 'Plan liegt genehmigt bel ' + modalData.name,
            statusCheck: 'done',
            colorText: 'black-text',
            connectionBorder: 'light',
        },
        {
            Text: 'Plan liegt genehmigt in der Praxis',
            statusCheck: 'around',
            colorText: 'gray',
            connectionBorder: 'dark',
        }
    ]
    const TimeLineProgress5 = [
        {
            Text: 'Plan wurde an ' + modalData.name + ' übergeben',
            statusCheck: 'done',
            colorText: 'black-text',
            connectionBorder: 'light',
        },
        {
            Text: 'Plan wurde an ' + modalData.name + ' übergeben',
            statusCheck: 'done',
            colorText: 'black-text',
            connectionBorder: 'light',
        },
        {
            Text: 'Plan liegt bei der Krankenkasse',
            statusCheck: 'done',
            colorText: 'black-text',
            connectionBorder: 'light',
        },
        {
            Text: 'Plan Wird angefochten',
            statusCheck: 'cross',
            colorText: 'black-text',
            connectionBorder: 'light',
        },
        {
            Text: 'Plan liegt genehmigt bel ' + modalData.name,
            statusCheck: 'done',
            colorText: 'black-text',
            connectionBorder: 'light',
        },
        {
            Text: 'Plan liegt genehmigt in der Praxis',
            statusCheck: 'done',
            colorText: 'black-text',
            connectionBorder: 'light',
        }
    ]
    const PrivateTimeLine = [
        {
            Text: 'Plan wurde an Patient übergeben',
            statusCheck: 'done',
            colorText: 'black-text',
            connectionBorder: 'light',
        },
        {
            Text: modalData.name + ' stimmt der Behandlung zu',
            statusCheck: modalData.consentGranted ? 'done' : 'around',
            colorText: modalData.consentGranted ? 'black-text' : 'gray',
            connectionBorder: modalData.consentGranted ? 'light' : 'dark',
        },
        {
            Text: modalData.name + ' wurde terminiert',
            statusCheck: modalData.kva_unterschrieben ? 'done' : 'around',
            colorText: modalData.kva_unterschrieben ? 'black-text' : 'gray',
            connectionBorder: modalData.kva_unterschrieben ? 'light' : 'dark',
        }
    ]
    const DisablePlanTimeLine = [
        {
            Text: 'Plan wurde abgelehnt',
            statusCheck: 'cross',
            colorText: 'black-text',
            connectionBorder: 'dark',
        },
        {
            Text: 'HKP wurde genehmigt',
            statusCheck: 'around',
            colorText: 'gray',
            connectionBorder: 'dark',
        }
    ]

    const [TimeLineData, setTimeLineData] = useState(TimeLineProgress1);

    useEffect(() => {
        if(modalData.isPlanDisable){
            setTimeLineData(DisablePlanTimeLine);
        }
        else{
            if(modalData.progress === 1 && modalData.eHkp){
                setTimeLineData(TimeLineProgress1);
            }
            if(modalData.progress === 2 && modalData.eHkp){
                setTimeLineData(TimeLineProgress2);
            }
            if(modalData.progress === 8 && modalData.eHkp){
                setTimeLineData(TimeLineProgress8);
            }
            if(modalData.progress === 4 && modalData.eHkp){
                setTimeLineData(TimeLineProgress4);
            }
            if(modalData.progress === 5 && modalData.eHkp){
                setTimeLineData(TimeLineProgress5);
            }
            if(modalData.private_Plan){
                setTimeLineData(PrivateTimeLine);
            }
        }
    }, [modalData]);


    return ( 
        <>
         <div>
            <Typography variant="h6" component="span" sx={{fontWeight: 700}}>
             Verlauf
            </Typography>
            <Timeline position="right" sx={{mt: 0}}>
                {TimeLineData.map((item, index) => {
                    return(
                        <>
                        <TimelineItem className='timeline-no' key={index}>
                            <TimelineSeparator>
                            {!!item.statusCheck ? (
                                <div>
                                    {item.statusCheck === "done" ? (
                                        <TimelineDot className='timeline-dots'>
                                        <DoneIcon />
                                      </TimelineDot>
                                    ) : null}
                                    {item.statusCheck === "cross" ? (
                                        <TimelineDot className='timeline-dots' sx={{backgroundColor: '#e91051 !important'}}>
                                         <CloseIcon />
                                        </TimelineDot>
                                    ) : null}
                                    {item.statusCheck === "around" ? (
                                        <TimelineDot variant="outlined" sx={{p: 1.6, m: 0}}>
                                        </TimelineDot>
                                    ) : null}
                                </div>
                            ) : null}
                            {!!item.connectionBorder && TimeLineData.length -1 > index ?
                                <>
                                    {item.connectionBorder === "light" ? 
                                        <TimelineConnector className='timeline-connet' />
                                     :  
                                     <TimelineConnector className="connect-w"></TimelineConnector>
                                     }
                                </>
                             : null}
                            </TimelineSeparator>
                            {!!item.colorText ? (
                                <>
                                    {item.colorText === "black-text" ? (
                                        <TimelineContent className='fs-17' sx={{py: 0}}>{item.Text}</TimelineContent>
                                    ) : null}
                                    {item.colorText === "gray" ? (
                                        <TimelineContent className='fs-17 color-gray' sx={{py: 0}}>{item.Text}</TimelineContent>
                                    ) : null}
                                </>
                            ) : null}
                        </TimelineItem>
                        </>
                    )
                })}
            </Timeline>
        </div>
        </>
     );
}

export default TimeLineItem;