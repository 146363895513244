import React, { useState, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import styled from 'styled-components';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import { Grid } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import InputBase from '@mui/material/InputBase';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { dynamicGetApi, dynamicPostApi } from './../../Api';
import { useDispatch, useSelector } from 'react-redux';
import { setRecall } from './../../Redux/Slice/commonSlice';
import CloseIconWrap from '../CloseIconWrap';



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '50%',
    height:'80%',
    transform: 'translate(-50%, -50%)',
    borderColor: '#fff',
    bgcolor: 'background.paper',
    borderRadius: '25px',
    boxShadow: 24,
    pt: 2,
    pb: 3,
    px: 2,
};
const ModelTitle = styled.h3`
font-size:20px;
font-weght:500 !important;
text-align:center;
margin:0px;
color:#1a1a1a !important;
`
const ModalCloseIcon = styled.span`
display:flex;
align-items:center;
padding:5px;
background-color:#f5f5f5;
border-radius:50%;
`
const SolidTextField = styled(TextField)({
    borderRadius: '8px',
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#f5f5f5',
        },
        '&:hover fieldset': {
            borderColor: '#f5f5f5',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#f5f5f5',
        },
    },
    '& .MuiInputBase-root.Mui-disabled': {
        '& fieldset': {
            borderColor: '#f5f5f5',
        },
        '&:hover fieldset': {
            borderColor: '#f5f5f5',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#f5f5f5',
        },
    },
    "& .MuiInputBase-input.Mui-disabled": {
        WebkitTextFillColor: "black",
        fontWeight: 400,
    },
    "& .MuiFormLabel-root.Mui-disabled": {
        WebkitTextFillColor: "black",
        fontWeight: 600,
    },
    "& .MuiFormHelperText-root.Mui-disabled": {
        marginLeft: '0px',
        marginTop: '8px',
        fontSize: '0.95em',
        lineHeight: 1.1,
        letterSpacing: '0em',
    },
    "& .MuiFormLabel-root": {
        color: 'rgb(0, 0, 0,)',
    },
});

function ChatEinstellungenModal(props) {
    const [cookies, setCookie, removeCookie] = useCookies(['authToken']);
    let dispatch = useDispatch();
    let navigate = useNavigate();

    let reCall = useSelector(state => state.common.reCall);

    const formik = useFormik({
        initialValues: {
            Id: 0,
            Phone: "",
            ChannelId: "",
            DisplayName: "",
            ShortName: "",
            APIKey: "",
            _namespace: "",
            AccountSid: "",
            AuthToken: "",
            TwilioLocalPhone: ""
        },
        validationSchema: Yup.object().shape({
            Phone: Yup.string().required("Required *"),
            ChannelId: Yup.string().required("Required *"),
            DisplayName: Yup.string().required("Required *"),
            ShortName: Yup.string().required("Required *"),
            APIKey: Yup.string().required("Required *"),
            _namespace: Yup.string().required("Required *"),
            AccountSid: Yup.string().required("Required *"),
            AuthToken: Yup.string().required("Required *"),
            TwilioLocalPhone: Yup.string().required("Required *")
        }),
        onSubmit: async values => {
            let url = "api/chatSetting/addOrUpdateChatSetting";
            let reponse = await dynamicPostApi(values, url, navigate, setCookie,dispatch);
            if (reponse.data) {
                props.hideChatModal();
                dispatch(setRecall(true));
            }
        },
    });

    useEffect(() => {
        if (props.isShow) {
            getChatSetting();
        }
    }, [props.isShow]);

    const getChatSetting = async () => {
        let Url = "api/chatSetting/getChatSetting";
        let response = await dynamicGetApi(Url, navigate, setCookie,dispatch);
        formik.setFieldValue("Id", response.data.id);
        formik.setFieldValue("Phone", response.data.phone);
        formik.setFieldValue("ChannelId", response.data.channelId);
        formik.setFieldValue("DisplayName", response.data.displayName);
        formik.setFieldValue("ShortName", response.data.shortName);
        formik.setFieldValue("APIKey", response.data.apiKey);
        formik.setFieldValue("_namespace", response.data._namespace);
        formik.setFieldValue("AccountSid", response.data.accountSid);
        formik.setFieldValue("AuthToken", response.data.authToken);
        formik.setFieldValue("TwilioLocalPhone", response.data.twilioLocalPhone);
    }

    return (
        <>
            <div>
                <Modal
                    open={props.isShow}
                    onClose={props.hideChatModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    className="position-r"
                >
                    <Box sx={style}>
                        <Box className='d-flex justify-content-between align-items-center flex-col-center-sx' sx={{ pb: 1, borderBottom: '1px solid #ebebeb',position:'relative' }}>
                            
                            <Box sx={{ mx: 1 }}>
                                <ModelTitle>Chat-Einstellungen</ModelTitle>
                            </Box>
                            <Box>
                                <Box sx={{position:'absolute',top:'-50px',right:'-55px'}} onClick={props.hideChatModal}>
                                    <CloseIconWrap />
                                </Box>
                                <Button sx={{mr:2}} type='button' onClick={formik.handleSubmit} className='btn-light'>Speichern</Button>
                            </Box>
                        </Box>
                        <Box className='ChatSettings-modal scroll'>
                            <Grid className='p-mob' container spacing={2} sx={{ padding: '30px' }}>
                                <Grid item md={12} sx={{ mb: 0.4 }}>
                                    <FormLabel>Praxisnummer für WhatsApp und SMS</FormLabel>
                                    <SolidTextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        placeholder='+4962711040'
                                        id=""
                                        name="Phone"
                                        size="small"
                                        type="text"
                                        //helperText="Diese Nummer sehen Patient:innn als Absender der WhatsApp Nachrichten."
                                        sx={{ mb: 2, height: '45px', backgroundColor: '#f5f5f5' }}
                                        error={formik.errors.Phone && formik.touched.Phone ? true : false}
                                        helperText={formik.errors.Phone && formik.touched.Phone ? formik.errors.Phone : "Diese Nummer sehen Patient:innn als Absender der WhatsApp Nachrichten."}
                                        value={formik.values.Phone}
                                        onChange={formik.handleChange}
                                    />
                                </Grid>
                                <Grid item md={12} sx={{ mb: 0.4 }}>
                                    <FormLabel>Praxis local number for voice call</FormLabel>
                                    <SolidTextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        placeholder='+4962711040'
                                        id=""
                                        name="TwilioLocalPhone"
                                        size="small"
                                        type="text"
                                        //helperText="Diese Nummer sehen Patient:innn als Absender der WhatsApp Nachrichten."
                                        sx={{ mb: 2, height: '45px', backgroundColor: '#f5f5f5' }}
                                        error={formik.errors.TwilioLocalPhone && formik.touched.TwilioLocalPhone ? true : false}
                                        helperText={formik.errors.TwilioLocalPhone && formik.touched.TwilioLocalPhone ? formik.errors.TwilioLocalPhone : "Praxis local number for voice call"}
                                        value={formik.values.TwilioLocalPhone}
                                        onChange={formik.handleChange}
                                    />
                                </Grid>
                                <Grid item md={12} sx={{ mb: 0.4 }}>
                                    <FormLabel>360 Dialog Channel ID</FormLabel>
                                    <SolidTextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        placeholder='3CO*****'
                                        id=""
                                        name="ChannelId"
                                        size="small"
                                        type="text"
                                        //helperText="Hiermit Wird 360DiaIog der Zugang zur Verwaltung der WhatsApp Nachrichten gewährt"
                                        sx={{ mb: 2, mt: 1, height: '45px', backgroundColor: '#f5f5f5' }}
                                        error={formik.errors.ChannelId && formik.touched.ChannelId ? true : false}
                                        helperText={formik.errors.ChannelId && formik.touched.ChannelId ? formik.errors.ChannelId : "Hiermit Wird 360DiaIog der Zugang zur Verwaltung der WhatsApp Nachrichten gewährt"}
                                        value={formik.values.ChannelId}
                                        onChange={formik.handleChange}
                                    />
                                </Grid>
                                <Grid item md={12} sx={{ mb: 0.4 }}>
                                    <FormLabel>Anzeigename für Textbausteine</FormLabel>
                                    <SolidTextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        placeholder='Gallenbach & Zähne'
                                        id=""
                                        name="DisplayName"
                                        size="small"
                                        type="text"
                                        //helperText="Der Anzeigename sollte folgenden Textbaustem sinnvoll befüllen: Die Praxis (...l möchte Ihnen eine Nachricht schicken."
                                        sx={{ mb: 2, mt: 1, height: '45px', backgroundColor: '#f5f5f5' }}
                                        error={formik.errors.DisplayName && formik.touched.DisplayName ? true : false}
                                        helperText={formik.errors.DisplayName && formik.touched.DisplayName ? formik.errors.DisplayName : "Der Anzeigename sollte folgenden Textbaustem sinnvoll befüllen: Die Praxis (...l möchte Ihnen eine Nachricht schicken."}
                                        value={formik.values.DisplayName}
                                        onChange={formik.handleChange}
                                    />
                                </Grid>
                                <Grid item md={12} sx={{ mb: 0.4, mt: 2.5 }}>
                                    <FormLabel>Kurzname der Praxis</FormLabel>
                                    <SolidTextField
                                        required
                                        fullWidth
                                        placeholder='Gallenbach & Zähne Zahnarztpraxis'
                                        id=""
                                        name="ShortName"
                                        size="small"
                                        type="text"
                                        //helperText="Möglichst kurzer Name, der diese Praxis eindeutig kennzeichnet und als Signatur bei WhatsApp verwendet Wird"
                                        sx={{ mb: 1, mt: 1, height: '45px', backgroundColor: '#f5f5f5' }}
                                        error={formik.errors.ShortName && formik.touched.ShortName ? true : false}
                                        helperText={formik.errors.ShortName && formik.touched.ShortName ? formik.errors.ShortName : "Möglichst kurzer Name, der diese Praxis eindeutig kennzeichnet und als Signatur bei WhatsApp verwendet Wird"}
                                        value={formik.values.ShortName}
                                        onChange={formik.handleChange}
                                    />
                                </Grid>
                                <Grid item md={12} sx={{ mb: 0.4, mt: 2.5 }}>
                                    <FormLabel>360 Dialog API Key</FormLabel>
                                    <SolidTextField
                                        required
                                        fullWidth
                                        placeholder='360 Dialog API Key'
                                        id=""
                                        name="APIKey"
                                        size="small"
                                        type="text"
                                        //helperText="Möglichst kurzer Name, der diese Praxis eindeutig kennzeichnet und als Signatur bei WhatsApp verwendet Wird"
                                        sx={{ mb: 1, mt: 1, height: '45px', backgroundColor: '#f5f5f5' }}
                                        error={formik.errors.APIKey && formik.touched.APIKey ? true : false}
                                        helperText={formik.errors.APIKey && formik.touched.APIKey ? formik.errors.APIKey : "360 Dialog API Key"}
                                        value={formik.values.APIKey}
                                        onChange={formik.handleChange}
                                    />
                                </Grid>
                                <Grid item md={12} sx={{ mb: 0.4, mt: 2.5 }}>
                                    <FormLabel>360 Dialog Namespace</FormLabel>
                                    <SolidTextField
                                        required
                                        fullWidth
                                        placeholder='360 Dialog Namespace'
                                        id=""
                                        name="_namespace"
                                        size="small"
                                        type="text"
                                        //helperText="Möglichst kurzer Name, der diese Praxis eindeutig kennzeichnet und als Signatur bei WhatsApp verwendet Wird"
                                        sx={{ mb: 1, mt: 1, height: '45px', backgroundColor: '#f5f5f5' }}
                                        error={formik.errors._namespace && formik.touched._namespace ? true : false}
                                        helperText={formik.errors._namespace && formik.touched._namespace ? formik.errors._namespace : "360 Dialog Namespace"}
                                        value={formik.values._namespace}
                                        onChange={formik.handleChange}
                                    />
                                </Grid>
                                <Grid item md={12} sx={{ mb: 0.4, mt: 2.5 }}>
                                    <FormLabel>Twilio Account SID</FormLabel>
                                    <SolidTextField
                                        required
                                        fullWidth
                                        placeholder='Twilio Account SID'
                                        id=""
                                        name="AccountSid"
                                        size="small"
                                        type="text"
                                        //helperText="Möglichst kurzer Name, der diese Praxis eindeutig kennzeichnet und als Signatur bei WhatsApp verwendet Wird"
                                        sx={{ mb: 1, mt: 1, height: '45px', backgroundColor: '#f5f5f5' }}
                                        error={formik.errors.AccountSid && formik.touched.AccountSid ? true : false}
                                        helperText={formik.errors.AccountSid && formik.touched.AccountSid ? formik.errors.AccountSid : "Twilio Account SID"}
                                        value={formik.values.AccountSid}
                                        onChange={formik.handleChange}
                                    />
                                </Grid>
                                <Grid item md={12} sx={{ mb: 0.4, mt: 2.5 }}>
                                    <FormLabel>Twilio Auth Token</FormLabel>
                                    <SolidTextField
                                        required
                                        fullWidth
                                        placeholder='Twilio Auth Token'
                                        id=""
                                        name="AuthToken"
                                        size="small"
                                        type="text"
                                        //helperText="Möglichst kurzer Name, der diese Praxis eindeutig kennzeichnet und als Signatur bei WhatsApp verwendet Wird"
                                        sx={{ mb: 1, mt: 1, height: '45px', backgroundColor: '#f5f5f5' }}
                                        error={formik.errors.AuthToken && formik.touched.AuthToken ? true : false}
                                        helperText={formik.errors.AuthToken && formik.touched.AuthToken ? formik.errors.AuthToken : "Twilio Auth Token"}
                                        value={formik.values.AuthToken}
                                        onChange={formik.handleChange}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Modal>
            </div>
        </>
    );
}

export default ChatEinstellungenModal;