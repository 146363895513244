import React, { useState, useEffect, version } from 'react';
import Grid from '@mui/material/Grid';
import { CloseLeftCornor } from '../CloseIconWrap';
import RodioButton from '../RodioButton';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { dynamicGetApi } from '../../Api';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useCookies } from 'react-cookie';

const checkVersionList = [
    {
        label: 'Old Version',
        value: '1',
        isChecked: false
    },
    {
        label: 'New Version',
        value: '2',
        isChecked: false
    }
]
function VersionSelectorModal(props) {
    const [cookie, setCookie, removeCookie] = useCookies(["authToken", "selectedVersion"]);
    let navigate = useNavigate();
    let dispatch = useDispatch();

    const [checkVersion, setCheckVersion] = useState(checkVersionList);

    const handleRadiochange = async (e) => {
        let newlist = [...checkVersion];
        for (let i = 0; i < newlist.length; i++) {
            newlist[i].isChecked = false;
        }
        let index = newlist.findIndex(x => x.value === e.target.value);
        newlist[index].isChecked = true;
        setCheckVersion(newlist);
        let url = "api/account/updateVersion?version=" + e.target.value;
        let res = await dynamicGetApi(url, navigate, setCookie, dispatch);
        if(res.data){
            setCookie("selectedVersion", e.target.value === "1" ? "old" : "new");
            props.handleClose();
        }
    }

    return (
        <>
            <div>
                <Grid container spacing={4} className="grid-main">
                    <Grid item md={12} sm={12} xs={12} className="grid-4-col">
                        <div onClick={props.handleClose}>
                            <CloseLeftCornor />
                        </div>

                        <Box sx={{ mt: 1, mb: 3, pl: 4, pr: 7 }}>
                            <Typography variant="h6" component="div" className='title-model-right'>
                                Which Version you want to use in plan flow?
                            </Typography>
                            <RodioButton radioList={checkVersion} onChange={handleRadiochange} />
                        </Box>
                    </Grid>
                </Grid>
            </div>
        </>
    )
}
export default VersionSelectorModal;