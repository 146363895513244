import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { CloseLeftCornor } from '../CloseIconWrap';
import { Box, Button, Typography } from '@mui/material';
import { SolidTextField } from './EinladenModal';
import FormLabel from '@mui/material/FormLabel';
import styled from '@emotion/styled';
import SelectWithCheck from '../SelectWithCheck';
import { TextArea } from './NotesModal';
import EmojiComponent from '../EmojiComponent';
import OutsideClickHandler from 'react-outside-click-handler';
import EmojiPicker, { EmojiStyle, SkinTones, Theme, Categories, EmojiClickData, Emoji, SuggestionMode, SkinTonePickerLocation } from "emoji-picker-react";
import { AccordionButton } from './TextModulesModal';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { dynamicGetApi, dynamicPostApi } from '../../Api';
import { RequiredText } from './InviteColleague';
import { setSelectedTemplateId } from './../../Redux/Slice/chatSlice';
import { setRecall } from './../../Redux/Slice/commonSlice';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputBase from '@mui/material/InputBase';

const HelperText = styled.div`
font-size:14px;
line-height:22px;
color: rgba(0, 0, 0, 0.6);
`
const EmojiBox = styled.div`
background-color:#f5f5f5;
padding:10px;
border-radius:10px;
position: relative !important;
height:53px;
display:flex !important;
align-items:center;
justify-content:center;
`
const ModalButton = styled.button`
border:none ;
background-color:#f3efec !important;
border-radius:20px;
font-size:14px;
padding:14px 20px;
font-weight:500;
align-self:center;
display: flex;
align-items: center;
cursor:pointer;
color:#000 !important;
margin-bottom:3px;
&:hover{
    background-color:#f3efec !important;
    color:#120ec5 !important;
    outline:none !important;
}
`
const BootstrapInput = styled(InputBase)(({ theme }) => ({
    height: '45px',
    borderRadius: '8px',
    '& .MuiInputBase-input': {
        backgroundColor: '#f5f5f5',
        border: 'none',
        '&:focus': {
            backgroundColor: '#f5f5f5',
            border: 'none',
        },
    },
}));

const SelectData = [
    'Oliver Hansen',
    'Van Henry',
    'April Tucker',
    'Ralph Hubbard',
    'Omar Alexander',
    'Carlos Abbott',
    'Miriam Wagner',
    'Bradley Wilkerson',
    'Virginia Andrews',
    'Kelly Snyder',
];

function CreateTextBlockModal(props) {
    const [cookie, setCookie, removeCookie] = useCookies();
    let navigate = useNavigate();
    let dispatch = useDispatch();

    const { handleClose } = props;

    let selectedTemplateId = useSelector(state => state.chat.selectedTemplateId);

    const [isShowEmoji, setIsShowEmoji] = useState(false);
    const [slectedItem, setSelectedItem] = useState("");
    const [salutationList, setSalutationList] = useState([]);

    const validationSchema = Yup.object().shape({
        title: Yup.string().required("Required *"),
        salutation: Yup.string().required("Required *"),
        contents: Yup.string().required("Required *"),
        grubformel: Yup.string().required("Required *"),
        practiceName: Yup.string().required("Required *"),
        emoji: Yup.string().required("*"),
    });

    const formik = useFormik({
        initialValues: {
            id: 0,
            title: '',
            salutation: '',
            contents: '',
            grubformel: '',
            practiceName: '',
            emoji: '1f601'
        },
        validationSchema: validationSchema,
        onSubmit: async values => {
            let url = 'api/conversationTemplates/createOrUpdate';

            let result = await dynamicPostApi(values, url, navigate, setCookie, dispatch);
            if (result.data) {
                formik.resetForm();
                dispatch(setSelectedTemplateId(null));
                dispatch(setRecall(true));
                handleClose();
            }
        },
    });

    useEffect(() => {
        getSalutationList();
    }, []);

    useEffect(() => {
        if (!!selectedTemplateId) {
            getData();
        }
    }, [selectedTemplateId]);

    useEffect(() => {
        if (!!slectedItem) {
            formik.setFieldValue("salutation", slectedItem[0]);
        }
    }, [slectedItem]);

    const getSalutationList = async () => {
        let url = 'api/salutation/getSalutationList';
        let res = await dynamicGetApi(url, navigate, setCookie, dispatch);
        setSalutationList(res.data);
    }

    const getData = async () => {
        let url = 'api/conversationTemplates/getConversationTemplateById?id=' + selectedTemplateId;
        let res = await dynamicGetApi(url, navigate, setCookie, dispatch);
        if (!!res.data) {
            formik.setFieldValue("id", res.data.id);
            formik.setFieldValue("title", res.data.title);
            formik.setFieldValue("salutation", res.data.salutation);
            formik.setFieldValue("contents", res.data.contents);
            formik.setFieldValue("grubformel", res.data.grubformel);
            formik.setFieldValue("practiceName", res.data.practiceName);
            formik.setFieldValue("emoji", res.data.emoji);
            setSelectedItem([res.data.salutation]);
        }
    }

    return (
        <>
            <div>
                <Grid container spacing={4} className="grid-main">
                    <Grid item md={12} sm={12} xs={12} className='model-p-mob' sx={{ pt: '18px !important' }}>
                        <div onClick={handleClose}>
                            <CloseLeftCornor />
                        </div>
                        <Box sx={{ px: 3, borderBottom: '1px solid rgba(224,224,224,1)' }}>
                            <Grid container spacing={4} sx={{ alignItems: 'center', pb: 1 }}>
                                <Grid item md={6} sm={12} xs={12}>
                                    <Box display="flex" justifyContent="start" alignItems="center">
                                        <Typography variant="h5" component="div" className='title-model-right' sx={{ mb: '0px !important' }}>
                                            Textbaustein erstellen
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item md={6} sm={12} xs={12}>
                                    <Box display="flex" justifyContent="end" alignItems="center">
                                        <ModalButton onClick={formik.handleSubmit}>Speichern</ModalButton>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box sx={{ p: 3 }} className="createTextBlockContent-scroll">
                            <Grid container spacing={2} sx={{ alignItems: 'center' }}>
                                <Grid item md={1.5} sm={12} xs={12}>
                                    <EmojiBox onClick={() => { setIsShowEmoji(true) }}>
                                        <div>
                                            <div className="selected-emoji">
                                                {!!formik.values.emoji ? (
                                                    <Emoji
                                                        unified={formik.values.emoji}
                                                        emojiStyle={EmojiStyle.APPLE}
                                                        size={50}
                                                    />
                                                ) : null}
                                            </div>
                                            <OutsideClickHandler onOutsideClick={() => { setIsShowEmoji(false) }}>
                                                {isShowEmoji ?
                                                    <EmojiPicker
                                                        className="Emoji-customizing"
                                                        onEmojiClick={(e) => formik.setFieldValue("emoji", e.unified)}
                                                        autoFocusSearch={false}
                                                    />
                                                    : null}
                                            </OutsideClickHandler>
                                        </div>
                                    </EmojiBox>
                                    <RequiredText>
                                        {formik.touched.emoji && formik.errors.emoji ? formik.errors.emoji : null}
                                    </RequiredText>
                                </Grid>
                                <Grid item md={10.5} sm={12} xs={12}>
                                    <FormLabel>Titel</FormLabel>
                                    <SolidTextField
                                        margin="normal"
                                        fullWidth
                                        placeholder='Der Name des Textbausteins'
                                        name="title"
                                        size="small"
                                        type="text"
                                        sx={{ pt: '5px', mb: 1, mt: 1, height: '45px', backgroundColor: '#f5f5f5', }}
                                        value={formik.values.title}
                                        onChange={formik.handleChange}
                                        error={formik.errors.title && formik.touched.title ? true : false}
                                        helperText={formik.errors.title && formik.touched.title ? formik.errors.title : ""}
                                    />
                                    <HelperText>Nicht fur die Patient:innen sichtbar</HelperText>
                                </Grid>
                                <Grid item md={12} sm={12} xs={12}>
                                    <FormLabel>Anrede</FormLabel>
                                    <Box sx={{ my: 1 }}>
                                        <Select
                                            labelId="role"
                                            id="demo-simple-select"
                                            value={formik.values.salutation}
                                            name='salutation'
                                            label=""
                                            input={<BootstrapInput />}
                                            sx={{ width: '100%', height: '45px', backgroundColor: '#f5f5f5' }}
                                            onChange={formik.handleChange}
                                        >
                                            {salutationList.map(x =>
                                                <MenuItem value={x.value}>{x.label}</MenuItem>
                                            )}
                                        </Select>
                                        {/* <SelectWithCheck listItems={SelectData} setSelectedItem={setSelectedItem} slectedItem={slectedItem} /> */}
                                    </Box>
                                    <RequiredText>
                                        {formik.touched.salutation && formik.errors.salutation ? formik.errors.salutation : null}
                                    </RequiredText>
                                    <HelperText>Die Ansprache Wird automatisch auf das Geschlecht des Patienten angepasst.</HelperText>
                                </Grid>
                                <Grid item md={12} sm={12} xs={12}>
                                    <FormLabel>Inhalt</FormLabel>
                                    <TextArea
                                        rows="8"
                                        placeholder='Der Inhalt des Textbausteins'
                                        name="contents"
                                        value={formik.values.contents}
                                        onChange={formik.handleChange}
                                    />
                                    <RequiredText>
                                        {formik.touched.contents && formik.errors.contents ? formik.errors.contents : null}
                                    </RequiredText>
                                    <HelperText>Der ext, der an die atient:ln geschickt werden ann. or dem Absenden kann der ext noch ngepasst werden.</HelperText>
                                </Grid>
                                <Grid item md={12} sm={12} xs={12}>
                                    <FormLabel>Grußformel</FormLabel>
                                    <SolidTextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        placeholder='Grußformel'
                                        id="nameTextModule"
                                        name="grubformel"
                                        size="small"
                                        type="text"
                                        sx={{ pt: '5px', mb: 1, mt: 1, height: '45px', backgroundColor: '#f5f5f5', }}
                                        value={formik.values.grubformel}
                                        onChange={formik.handleChange}
                                        error={formik.errors.grubformel && formik.touched.grubformel ? true : false}
                                        helperText={formik.errors.grubformel && formik.touched.grubformel ? formik.errors.grubformel : ""}
                                    />
                                </Grid>
                                <Grid item md={12} sm={12} xs={12}>
                                    <FormLabel>Praxisname</FormLabel>
                                    <SolidTextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        placeholder='Praxisname'
                                        id="nameTextModule"
                                        name="practiceName"
                                        size="small"
                                        type="text"
                                        sx={{ pt: '5px', mb: 1, mt: 1, height: '45px', backgroundColor: '#f5f5f5', }}
                                        value={formik.values.practiceName}
                                        onChange={formik.handleChange}
                                        error={formik.errors.practiceName && formik.touched.practiceName ? true : false}
                                        helperText={formik.errors.practiceName && formik.touched.practiceName ? formik.errors.practiceName : ""}
                                    />
                                    <HelperText>Der Kurzname der Praxis lässt Sich in den Einstellungen zum Chat ändern.</HelperText>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </div>
        </>
    )
}
export default CreateTextBlockModal;