import { TailSpin } from "react-loader-spinner";
const PageLoader = (props) => {
    const {show} = props;
      return(
        <div className={show ? "fullLoader" : ''}>
            <TailSpin
            height="150"
            width="150"
            radius="2"
            color="#fff"
            ariaLabel="tail-spin-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={show}
            />
        </div>
      )
}

export default PageLoader;