import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import { Box, Button } from '@mui/material';
import moment from 'moment';
import { DropdownWrapper } from './HeaderDropdownWrapper';
import { EditBtnWrapper } from './Dialogs/NotesModal';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from '@mui/icons-material/Delete';
import CommentIcon from '@mui/icons-material/Comment';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { dynamicGetApi, dynamicGetFileApi, dynamicPostApi } from './../Api';
import { setMentionedAttachment } from '../Redux/Slice/commentSlice';
import { AWS_BUCKET_URL } from '../Config';

const PaddingLeft = styled.div`
padding-left: 40px;
`
const Description = styled.p`
font-Weight:400 !important;
color:#7c7c7c;
margin-top:8px;
`
const Ulist = styled.div`
margin: 0px;
display: inline-flex;
align-items:center;
padding-left: 25px;
font-size:16px;
& .editIcon{
    font-size:16px !important; 
    color:#7c7c7c
}
& .hoverblue:hover{
    color:#120ec5
}
`
export const LinkItem = styled.a`
color:#7c7c7c;
text-decoration:underline;
margin-right:
`
const attachmentIcons = {
    "image": "/images/ImageIcon.png",
    "xlxs": "/images/XlxIcon.png",
    "docx": "/images/DocIcon.png",
    "pdf": "/images/PdfIcon.png",
    "ppt": "/images/PptIcon.png",
    "mp4": "/images/Mp4Icon.png",
    "gif": "/images/GifIcon.jpg",
    "zip": "/images/zip-img.png",
}

function AttachmentCommentList(props) {
    const [cookies, setCookie, removeCookie] = useCookies(['authToken']);
    let dispatch = useDispatch();
    let navigate = useNavigate();

    const InputREf = useRef(null);

    const { attachment, planId } = props;

    const [showMore, setShowMore] = useState(false);
    const [attachmentList, setAttachmentList] = useState(attachment);

    useEffect(() => {
        if (!!attachment) {
            setAttachmentList(attachment);
        }
    }, [attachment]);

    const handleShowMore = () => {
        setShowMore(!showMore);
    };

    const handleChange = async (e) => {
        if (e.target.files.length > 0) {
            let attachmentData = {
                attachmentId: 0,
                name: "",
                planId: planId,
                attachmentFile: e.target.files[0],
            }
            var form_data = new FormData();
            for (var key in attachmentData) {
                form_data.append(key, attachmentData[key]);
            }
            let url = 'api/comment/createUpdateAttachment';
            let result = await dynamicPostApi(form_data, url, navigate, setCookie, dispatch);
            if (!!result.data && result.data !== 'Fail To Upload Attachment') {
            }
        }
    }

    const deleteAttachment = async (attachmentId) => {
        let url = "api/comment/deleteAttachment?attachmentId=" + attachmentId;
        let res = await dynamicGetApi(url, navigate, setCookie, dispatch);
    }

    const downloadImage = async (attachmentId, type, name) => {
        let url = 'api/comment/downloadAttachment?attachmentId=' + attachmentId
        let res = await dynamicGetFileApi(url, navigate, setCookie, dispatch, type, name);
    }

    return (
        <>
            <Box>
                <PaddingLeft>
                    {attachmentList.map((item, index) => {
                        if (showMore === false) {
                            if (index < 2) {
                                return (
                                    <>
                                        <Box display="flex" justifyContent="start" alignItems="center" key={index} sx={{ pt: 1 }} className="flex-between-mob px-0-mob flex-col-center-sx">
                                            <div>
                                                <a href={AWS_BUCKET_URL + item.key} target={"_blank"}>
                                                    <img alt="Not Contact" src={item.contentType.split("/")[0].toString() === "image" ? AWS_BUCKET_URL + item.key : attachmentIcons[item.contentType.split("/")[1]]} style={{ width: "109px", height: "83px" }} />
                                                </a>
                                                {/* <img alt="Not Contact" src={item.contentType.split("/")[0].toString() === "image" ? AWS_BUCKET_URL + item.key : attachmentIcons[item.contentType.split("/")[1]]} style={{ width: "109px", height: "83px" }} onClick={() => downloadImage(item.attachmentId, item.contentType, item.name)} /> */}
                                            </div>
                                            <div className='ml-15 mb-0-list'>
                                                <Typography variant='p' component='div' sx={{ fontWeight: '600', color: '#3b3b3b' }}>{item.name}</Typography>
                                                <Typography variant='p' className='d-inlineflex'>
                                                    <Description>Added {moment(new Date(item.createdAt)).format("MMM DD")} at {moment(new Date(item.createdAt)).format("hh:mm A")}
                                                        <Ulist>
                                                            <div className='comment-action mr-5px'>
                                                                <div className="">
                                                                    <EditBtnWrapper>
                                                                        <CommentIcon fontSize='inherit' className='hoverblue' onClick={() => dispatch(setMentionedAttachment(item))} />
                                                                    </EditBtnWrapper>
                                                                </div>
                                                            </div>
                                                            <div className='comment-action'>
                                                                <DropdownWrapper bodyType="EditFile" headerTitle="Edit Attachment" inputLabel="Link name" inputType="text" inputValue={item.name} attachmentId={item.attachmentId} planId={planId} btnText="Update" iconBg={false} icon={
                                                                    <EditBtnWrapper className='editIcon'>
                                                                        <BorderColorIcon fontSize='inherit' className='hoverblue' />
                                                                    </EditBtnWrapper>} noarrow />
                                                            </div>
                                                            <div className='comment-action'>
                                                                <div className="">
                                                                    <EditBtnWrapper>
                                                                        <DeleteIcon fontSize='inherit' className='hoverblue' onClick={() => deleteAttachment(item.attachmentId)} />
                                                                    </EditBtnWrapper>
                                                                </div>
                                                            </div>
                                                        </Ulist>
                                                    </Description>
                                                </Typography>
                                            </div>
                                        </Box>
                                    </>
                                )
                            }
                        }
                        else {
                            return (
                                <>
                                    <Box display="flex" justifyContent="start" alignItems="center" key={index} sx={{ pt: 1 }} className="flex-between-mob px-0-mob flex-col-center-sx">
                                        <div>
                                        <a href={AWS_BUCKET_URL + item.key} target={"_blank"}>
                                                    <img alt="Not Contact" src={item.contentType.split("/")[0].toString() === "image" ? AWS_BUCKET_URL + item.key : attachmentIcons[item.contentType.split("/")[1]]} style={{ width: "109px", height: "83px" }} />
                                                </a>
                                            {/* <img alt="Not Contact" src={item.contentType.split("/")[0].toString() === "image" ? AWS_BUCKET_URL + item.key : attachmentIcons[item.contentType.split("/")[1]]} style={{ width: "109px", height: "83px" }} onClick={() => downloadImage(item.attachmentId, item.contentType, item.name)} /> */}
                                        </div>
                                        <div className='ml-15 mb-0-list'>
                                            <Typography variant='p' component='div' sx={{ fontWeight: '600', color: '#3b3b3b' }}>{item.name}</Typography>
                                            <Typography variant='p' className='d-inlineflex'>
                                                <Description>Added {moment(new Date(item.createdAt)).format("MMM DD")} at {moment(new Date(item.createdAt)).format("hh:mm A")}
                                                    <Ulist>
                                                        <div className='comment-action mr-5px'>
                                                            <div className="">
                                                                <EditBtnWrapper>
                                                                    <CommentIcon fontSize='inherit' className='hoverblue' onClick={() => dispatch(setMentionedAttachment(item))} />
                                                                </EditBtnWrapper>
                                                            </div>
                                                        </div>
                                                        <div className='comment-action'>
                                                            <DropdownWrapper bodyType="EditFile" headerTitle="Edit Attachment" inputLabel="Link name" inputType="text" inputValue={item.name} attachmentId={item.attachmentId} planId={planId} btnText="Update" iconBg={false} icon={
                                                                <EditBtnWrapper className='editIcon'>
                                                                    <BorderColorIcon fontSize='inherit' className='hoverblue' />
                                                                </EditBtnWrapper>} noarrow />
                                                        </div>
                                                        <div className='comment-action'>
                                                            <div className="">
                                                                <EditBtnWrapper>
                                                                    <DeleteIcon fontSize='inherit' className='hoverblue' onClick={() => deleteAttachment(item.attachmentId)} />
                                                                </EditBtnWrapper>
                                                            </div>
                                                        </div>
                                                    </Ulist>
                                                </Description>
                                            </Typography>
                                        </div>
                                    </Box>
                                </>
                            )
                        }
                    })}
                    <Box display="block" justifyContent="start" alignItems="center" onClick={handleShowMore}>
                        {showMore ?
                            <Button className='btn-light practiceSetting-btn-mt' sx={{ mt: 2 }}>Show Fewe attachments</Button> :
                            attachmentList.length > 2 && <Button className='btn-light practiceSetting-btn-mt' sx={{ mt: 2 }}>View all attachments {`(${attachmentList.length - 2} hidden)`}</Button>}
                    </Box>
                    <input
                        accept="image/*"
                        style={{ display: 'none' }}
                        id="button-file-upload"
                        type="file"
                        ref={InputREf}
                        onChange={(e) => handleChange(e)}
                    />
                    <Button className='btn-light practiceSetting-btn-mt' onClick={() => InputREf?.current?.click()} sx={{ mt: 2 }}>Anhang hinzufugen </Button>
                </PaddingLeft>
            </Box>
        </>
    )
}
export default AttachmentCommentList;
