import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import DoneIcon from '@mui/icons-material/Done';
import CloseIconWrap from '../CloseIconWrap';
import { DialogContentWrapper } from './WarDieAntwort';


const AntwortWrap = styled.div`
padding-left:4.4%;
padding-right:4.4%;
`
const Avatar = styled.div`
  border-radius:13px;
  background-color:#e7eceb;
  width:45px;
  height:45px;
  color:#63c2a6;
`
const AvatarCross = styled.div`
  border-radius:13px;
  background-color:#fff1f9;
  width:45px;
  height:45px;
  color:#d8315e;
`

function AntwortDerKasseModal(props) {

    const { open, setOpen, valueChange } = props;

    const onAccept = () => {
        debugger
        valueChange("approvalAvailable");
        setOpen(false);
    }

    const onReject = () => {
        setOpen(false);
    }

    return (
        <>
            <Dialog
                fullWidth={true}
                maxWidth={'sm'}
                open={open}
                onClose={() => setOpen(false)}
                className="antwort-width-600"
                aria-labelledby="responsive-dialog-title"
            >
                <div className='closeInner-btn' onClick={() => setOpen(false)}>
                    <CloseIconWrap />
                </div>
                <DialogContentWrapper>
                    <AntwortWrap>
                        <DialogTitle id="responsive-dialog-title" align="center" sx={{ pb: 0 }} className="antwort-title">
                            {"Was ist die Antwort der Kasse?"}
                        </DialogTitle>
                        <DialogContent>
                            <Typography variant="subtitle2" align="center" className='subtitle-modal'>
                                Gib die Entscheidung der Kasse an
                            </Typography>
                            <DialogContentText sx={{ pt: 5, }}>
                                <List sx={{ bgcolor: 'background.paper' }}>
                                    <ListItem alignItems="flex-start" className='list-bg flex-col-mob-center' onClick={() => onAccept()}>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <DoneIcon className='cross-icon m-icon' />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <div className='text-center-mob'>
                                            <ListItemText
                                                className='badge-text-20'
                                                primary="Kasse hat den Plan genehmigt"
                                            />
                                            <Typography
                                                sx={{ lineHeight: 0.6 }}
                                                component="span"
                                                color="text.secondary"
                                                className='fs-17'
                                            >
                                                Der Plan wurde genehmigt. Sobald die Unterlagen in der Praxis Liegen, kann die Behandlung beginnen.
                                            </Typography>
                                        </div>
                                    </ListItem>
                                    <ListItem alignItems="flex-start" className='list-bg flex-col-mob-center' onClick={() => onReject()}>
                                        <ListItemAvatar>
                                            <AvatarCross>
                                                <CloseIcon className='cross-icon m-icon' />
                                            </AvatarCross>
                                        </ListItemAvatar>
                                        <div className='text-center-mob'>
                                            <ListItemText
                                                className='badge-text-20'
                                                primary="Kasse hat den Plan abgelehnt"
                                            />
                                            <Typography
                                                sx={{ lineHeight: 0.6 }}
                                                component="span"
                                                color="text.secondary"
                                                className='fs-17'
                                            >
                                                Die Kasse hat den Plan entweder direkt order nach einem Gutachterprozesss abgelehnt.
                                            </Typography>
                                        </div>
                                    </ListItem>
                                </List>
                            </DialogContentText>
                        </DialogContent>
                    </AntwortWrap>
                </DialogContentWrapper>
            </Dialog>
        </>
    )
}
export default AntwortDerKasseModal;