import React, { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar'
import AttachFileRoundedIcon from '@mui/icons-material/AttachFileRounded';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import SendIcon from '@mui/icons-material/Send';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import Checkboxes from '../Inputs/Checkboxes';
import RechenzentrumGedecktModal from './RechenzentrumGedecktModal';
import GingDerPlanModal from './GingDerPlanModal';
import { dynamicGetApi, dynamicPostApi } from '../../Api';
import { useCookies } from 'react-cookie';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { setDisableButton } from './../../Redux/Slice/commonSlice';
import NotesModal, { EditBtn, EditBtnWrapper, EditBtnPosition } from './NotesModal';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { fontSize } from '@mui/system';
import { Box } from '@mui/material';
import CommentsModal from './CommentsModal';

const Demo = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
}));

function ModalRightSidebar(props) {
    const [cookies, setCookie, removeCookie] = useCookies(['authToken']);
    let navigate = useNavigate();
    let dispatch = useDispatch();

    const { modalData, setModalData } = props;

    const [kanbanData, setKanbanData] = useState({});
    const [openAmountCovered, setOpenAmountCovered] = useState(false);
    const [gingder, setGingder] = useState(false);
    const [purchaseRequest, setPurchaseRequest] = useState(false);
    const [paymentCleared, setPaymentCleared] = useState(false);
    const [costCheck, setCostCheck] = useState(false);
    const [printCheck, setPrintCheck] = useState(false);
    const [estimate, setEstimate] = useState(false);
    const [makeCall, setMakeCall] = useState(false);
    const [showClearPaymentCheckbox, setShowClearPaymentCheckbox] = useState(false);
    const [handoverCheckbox, setHandoverCheckbox] = useState(false);
    const [showNotesMoadel, setShowNotesMoadel] = useState(false);
    const [comment, setComment] = useState(false);

    useEffect(() => {
        if (!!modalData) {
            setKanbanData(modalData);
            setPurchaseRequest(modalData.factoringRequested);
            setPrintCheck(modalData.planPrinted);
            setPaymentCleared(modalData.paymentClarified);
            if (modalData.paymentClarified) {
                setShowClearPaymentCheckbox(modalData.paymentClarified);
            }
            setCostCheck(modalData.hkpShipped);
            setHandoverCheckbox(modalData.kvaHandover);
            if (modalData.progress === 1) {
                setEstimate(true);
                dispatch(setDisableButton(false));
            }
        }
    }, [modalData]);

    useEffect(() => {
        if (makeCall) {
            valueChange("purchaseRequest");
        }
    }, [purchaseRequest]);

    useEffect(() => {
        if (makeCall) {
            valueChange("costCheck");
        }
    }, [costCheck]);

    useEffect(() => {
        if (makeCall) {
            valueChange("handoverCheckbox");
            dispatch(setDisableButton(false));
        }
        else {
            dispatch(setDisableButton(true));
        }
    }, [handoverCheckbox]);

    useEffect(() => {
        if (makeCall) {
            valueChange("printCheck");
        }
    }, [printCheck]);

    useEffect(() => {
        if (makeCall) {
            valueChange("paymentCleared");
        }
    }, [paymentCleared]);

    useEffect(() => {
        if (makeCall && estimate) {
            valueChange("estimate");
            dispatch(setDisableButton(false));
        }
        else {
            dispatch(setDisableButton(true));
        }
    }, [estimate]);

    const postKanbanData = async (data) => {
        let url = "api/kanban/updateKanbanItem";
        let res = await dynamicPostApi(data, url, navigate, setCookie, dispatch);
        setMakeCall(false);
    }

    const valueChange = (call) => {
        debugger
        let newData = {};
        if (call === "purchaseRequest") {
            newData = { ...kanbanData, factoringRequested: purchaseRequest };
        }
        else if (call === "printCheck") {
            newData = { ...kanbanData, planPrinted: printCheck };
        }
        else if (call === "paymentCleared") {
            newData = { ...kanbanData, paymentClarified: paymentCleared };
        }
        else if (call === "costCheck") {
            newData = { ...kanbanData, hkpShipped: costCheck };
        }
        else if (call === "handoverCheckbox") {
            newData = { ...kanbanData, kvaHandover: handoverCheckbox };
        }
        else if (call === "estimate") {
            newData = { ...kanbanData, progress: estimate ? 0 : 1 };
        }
        if (Object.keys(newData).length > 0) {
            setKanbanData(newData)
            setModalData(newData);
            postKanbanData(newData);
        }
    }

    const currCheckboxHandleChange = (inputName) => {
        if (inputName === "purchaseRequest") {
            if (purchaseRequest) {
                setPurchaseRequest(false);
                //setShowClearPaymentCheckbox(false);
                setMakeCall(true);
            }
            else {
                setOpenAmountCovered(true);
            }
        }
        if (inputName === "handoverCheckbox") {
            if (handoverCheckbox) {
                setHandoverCheckbox(false);
                setMakeCall(true);
            }
            else {
                setGingder(true)(true);
            }
        }
        if (inputName === "estimateCheckbox") {
            if (estimate) {
                setEstimate(false);
            }
            else {
                setEstimate(true);
            }
            setMakeCall(true);
        }
        if (inputName === "paymentCleared") {
            if (paymentCleared) {
                setPaymentCleared(false);
            }
            else {
                setPaymentCleared(true);
            }
            setMakeCall(true);
        }
        if (inputName === "costCheck") {
            if (costCheck) {
                setCostCheck(false);
            }
            else {
                setCostCheck(true);
            }
            setMakeCall(true);
            costAndPrintCheck(!costCheck, printCheck);
        }
        if (inputName === "printCheck") {
            if (printCheck) {
                setPrintCheck(false);
            }
            else {
                setPrintCheck(true);
            }
            setMakeCall(true);
            costAndPrintCheck(costCheck, !printCheck);
        }
    }

    const costAndPrintCheck = (cost, print) => {
        if(cost && print){
            dispatch(setDisableButton(false));
        }
        else{
            dispatch(setDisableButton(true));
        }
    }

    return (
        <>
            {comment && <CommentsModal setOpen={setComment} open={comment} planId={modalData.id} />}
            {/* {showNotesMoadel && <NotesModal planId={modalData.id} openNotesMoadel={showNotesMoadel} setOpenNotesModal={setShowNotesMoadel} />} */}
            <RechenzentrumGedecktModal setShowClearPaymentCheckbox={setShowClearPaymentCheckbox} setMakeCall={setMakeCall} openAmountCovered={openAmountCovered} setOpenAmountCovered={setOpenAmountCovered} setPurchaseRequest={setPurchaseRequest} valueChange={valueChange} />
            <GingDerPlanModal closeGingder={setGingder} gingder={gingder} setHandoverCheckbox={setHandoverCheckbox} setMakeCall={setMakeCall} />
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h4" component="div" className='title-model-right'>
                    Planung
                </Typography>
                <div className="EditBtnPlan">
                    <EditBtnWrapper>
                        <EditBtn onClick={() => setComment(true)}>
                            <BorderColorIcon />
                        </EditBtn>
                    </EditBtnWrapper>
                </div>
            </Box>
            <Demo>
                <List>
                    <ListItem sx={{ pl: 0 }}>
                        <ListItemAvatar>
                            <Avatar sx={{ backgroundColor: '#f8f6f5' }}>
                                <AttachFileRoundedIcon className='rotate-50' />
                            </Avatar>
                        </ListItemAvatar>
                        <Typography sx={{ fontWeight: 'bold', textDecoration: purchaseRequest && showClearPaymentCheckbox !== true ? 'line-through' : purchaseRequest && showClearPaymentCheckbox && paymentCleared ? 'line-through' : 'none' }} variant="h6" component="div">
                            Factoring
                        </Typography>
                    </ListItem>
                    <ListItem sx={{ pl: 0 }}>
                        <FormGroup>
                            <FormControlLabel sx={{ marginLeft: '10px', textDecoration: purchaseRequest ? 'line-through' : 'none' }} control={<Checkboxes checked={purchaseRequest} onChange={() => currCheckboxHandleChange("purchaseRequest")} sx={{ fontSize: 28 }} />}
                                label="Ankaufsanfrage stellen" />
                            {showClearPaymentCheckbox ?
                                <FormControlLabel sx={{ marginLeft: '10px', textDecoration: paymentCleared ? 'line-through' : 'none' }} control={<Checkboxes checked={paymentCleared} onChange={() => currCheckboxHandleChange("paymentCleared")} sx={{ fontSize: 28 }} />}
                                    label="Zahlung klaren" />
                                : null}
                        </FormGroup>
                    </ListItem>
                    <ListItem sx={{ pl: 0 }}>
                        <ListItemAvatar>
                            <Avatar sx={{ backgroundColor: '#f8f6f5' }}>
                                <PersonOutlineOutlinedIcon className='icon-dark' />
                            </Avatar>
                        </ListItemAvatar>
                        <Typography sx={{ textDecoration: costCheck ? 'line-through' : 'none' }} variant="h6" component="span">
                            Labor - und Materialkosten
                        </Typography>
                    </ListItem>
                    <ListItem sx={{ pl: 0 }}>
                        <FormGroup>
                            <FormControlLabel sx={{ marginLeft: '10px', textDecoration: costCheck ? 'line-through' : 'none' }} control={<Checkboxes checked={costCheck} onChange={() => currCheckboxHandleChange("costCheck")} sx={{ fontSize: 28 }} />} label="Material- und Laborkosten eintragen" />
                        </FormGroup>
                    </ListItem>
                    <ListItem sx={{ pl: 0 }}>
                        <ListItemAvatar>
                            <Avatar sx={{ backgroundColor: '#f8f6f5' }}>
                                <SendIcon className='rotate-40' />
                            </Avatar>
                        </ListItemAvatar>
                        <Typography sx={{ fontWeight: 'bold', textDecoration: printCheck && (handoverCheckbox || estimate) ? 'line-through' : 'none' }} variant="h6" component="div">
                            Drucken & Versenden
                        </Typography>
                    </ListItem>
                    <ListItem sx={{ pl: 0, pb: 0 }}>
                        <FormGroup>
                            <FormControlLabel sx={{ marginLeft: '10px', textDecoration: printCheck ? 'line-through' : 'none' }} control={<Checkboxes checked={printCheck} onChange={() => currCheckboxHandleChange("printCheck")} sx={{ fontSize: 28 }} />} label="Plan drucken" />
                        </FormGroup>
                    </ListItem>
                    {modalData.eHkp && <ListItem sx={{ pl: 0, pt: 0 }}>
                        <FormGroup>
                            <FormControlLabel sx={{ marginLeft: '10px', textDecoration: handoverCheckbox ? 'line-through' : 'none' }} control={<Checkboxes checked={handoverCheckbox} onChange={() => currCheckboxHandleChange("handoverCheckbox")} sx={{ fontSize: 28 }} />} label={"Plan an " + props.modalData.name + " übermitteln Oder an die Kasse schicken"} />
                        </FormGroup>
                    </ListItem>}
                    {modalData.private_Plan && <ListItem sx={{ pl: 0, pt: 0 }}>
                        <FormGroup>
                            <FormControlLabel sx={{ marginLeft: '10px', textDecoration: estimate ? 'line-through' : 'none' }} control={<Checkboxes checked={estimate} onChange={() => currCheckboxHandleChange("estimateCheckbox")} sx={{ fontSize: 28 }} />} label={"Kostenvoranschlag an " + props.modalData.name + " Ubermitteln"} />
                        </FormGroup>
                    </ListItem>}
                </List>
            </Demo>
        </>
    )
}
export default ModalRightSidebar;