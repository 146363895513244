import * as React from 'react';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';

const Close = styled(Button)(() => ({
  right: '22px',
  marginTop: '15px !important',
  backgroundColor: '#f5f5f5 !important',
  color: '#120ec5 !important',
  borderRadius: '50% !important',
  padding: '6px !important',
  minWidth: '35px !important',
}));


const CloseLeft = styled(Button)((props) => ({
  position: 'absolute !important',
  // left: !props.right ?  '-1.2% !important' : "unset",
  right: "-12px",

  top: '-3.5% !important',
  zIndex: '9999999 !important',
  backgroundColor: '#fff !important',
  color: '#120ec5 !important',
  borderRadius: '50% !important',
  padding: '5px !important',
  minWidth: '25px !important',
  '&:hover': {
      backgroundColor: '#f5f5f5 !important',
      color: '#120ec5 !important',
    },
}));


function CloseIconWrap() {

  

    return ( 
        <>
        <div className='d-flex flex-end'>
          <Close>
            <CloseIcon autoFocus className='cross-icon' />
          </Close>
        </div>
        </>
     );
}

export default CloseIconWrap;



export function CloseLeftCornor (props) {
  const {right} = props;


    return (
        <>
        <CloseLeft right={right}>
            <CloseIcon autoFocus className='cross-icon' />
        </CloseLeft>
        </>
    )
}