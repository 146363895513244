import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { CloseLeftCornor } from '../CloseIconWrap';
import { Box } from '@mui/material';
import { EditBtn, EditBtnWrapper } from './NotesModal';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import Typography from '@mui/material/Typography';
import ControlledAccordions from '../Accordion';
import styled from 'styled-components';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { dynamicGetApi } from '../../Api';
import {setRecall} from './../../Redux/Slice/commonSlice';


const ModalHeaderButton = styled.button`
border:none ;
background-color:transparent !important;
font-size:14px;
padding:12px 15px;
font-weight:500;
align-self:center;
display: flex;
align-items: center;
cursor:pointer;
color:#120ec5 !important;
&:hover{
    background-color:#f3efec !important;
    outline:none !important;
    border-radius:20px;
}
`
export const AccordionButton = styled.button`
border:none ;
background-color:#f3efec !important;
border-radius:20px;
font-size:14px;
padding:12px 15px;
font-weight:500;
align-self:center;
display: flex;
align-items: center;
cursor:pointer;
color:#000 !important;
&:hover{
    background-color:#f3efec !important;
    color:#120ec5 !important;
    outline:none !important;
}
`

function TextModulesModal(props) {
    const [cookie, setCookie, removeCookie] = useCookies();
    let navigate = useNavigate();
    let dispatch = useDispatch();

    const { handleClose, handleCreateTextBlockModal, templateList} = props;

    return (
        <>
            <div>
                <Grid container spacing={4} className="grid-main">
                    <Grid item md={12} sm={12} xs={12} className='model-p-mob' sx={{ pt: '18px !important' }}>
                        <div  onClick={handleClose}>
                            <CloseLeftCornor />
                        </div>
                        <Box sx={{ px: 3, borderBottom: '1px solid rgba(224,224,224,1)' }}>
                            <Grid container spacing={4} sx={{pb:1}}>
                                <Grid item md={6} sm={12} xs={12}>
                                    <Box display="flex" justifyContent="start" alignItems="center">
                                        <Typography variant="h5" component="div" className='title-model-right'>
                                            Textbausteine
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item md={6} sm={12} xs={12}>
                                    <Box display="flex" justifyContent="end" alignItems="center">
                                        <ModalHeaderButton onClick={handleCreateTextBlockModal}>
                                            <BorderColorIcon sx={{ fontSize: '16px !important', mr: 1 }} />
                                            <span>Neuer Textbaustein</span>
                                        </ModalHeaderButton>
                                        {/* <EditBtnWrapper>
                                            <EditBtn onClick={handleCreateTextBlockModal}>
                                                <BorderColorIcon sx={{ fontSize: '16px !important', mr: 1 }} />
                                                <span>Neuer Textbaustein</span>
                                            </EditBtn>
                                        </EditBtnWrapper> */}
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box sx={{ p:3 }} className="createTextBlockContent-scroll">
                            <ControlledAccordions  accordionList={templateList} handleCreateTextBlockModal={handleCreateTextBlockModal}/>
                        </Box>
                    </Grid>
                </Grid>
            </div>
        </>
    )
}
export default TextModulesModal;