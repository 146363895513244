import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import EmailConfirmation from './EmailConfirmation';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { dynamicGetApi } from './../Api';
import { useDispatch, useSelector } from 'react-redux';



const style = {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    borderColor: '#fff',
    borderRadius: '16px',
    boxShadow: 24,
    pt: 4,
    pb: 3,
    px: 2,
};

export default function ForgetPassword(props) {
    const [cookies, setCookie, removeCookie] = useCookies(['authToken']);
    let dispatch = useDispatch();
    let navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            email: ''
        },
        validationSchema: Yup.object().shape({
            email: Yup.string().required("Please enter email")
        }),
        onSubmit: async values => {
            let url = "api/account/forgetPassword?email=" + values.email;
            let result = await dynamicGetApi(url,navigate,setCookie,dispatch);
            if(result.data){
                props.showEmailConf()
            }
        },
    });

    return (
        <div>
            <Modal
                open={props.isShow}
                onClose={props.hideResetModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className='modal-width' >
                    <Typography id="modal-modal-title" variant="h5" component="h5" align='center' sx={{ mt: 2 }}>
                        <Box sx={{ fontWeight: 600 }}>
                            Passwort zurücksetzen
                        </Box>
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2, mb: 3 }} align='center'>
                    HKP-Hero schickt dir eine E-Mail mit einem Link, der dir beim
                        Zurücksetzen deines Passworts hilft.
                    </Typography>
                    <FormLabel id="email">E-Mail</FormLabel>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        placeholder='E-Mail'
                        id="email"
                        name="email"
                        size="small"
                        sx={{ mb: 2, height: '45px' }}
                        error={formik.errors.email && formik.touched.email ? true : false}
                        helperText={formik.errors.email && formik.touched.email ? formik.errors.email : ""}
                        value={formik.values.email}
                        onChange={formik.handleChange}
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2, pt: 2, borderTop: '1px solid #F5F5F5' }}>
                        <Button className='btn-light' onClick={props.hideResetModal}>
                            Abbrechen
                        </Button>
                        <Button className='btn-primary' onClick={formik.handleSubmit}>
                            Weiter
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}