import React, { useState, useEffect, useRef } from 'react';
import FormLabel from '@mui/material/FormLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputBase from '@mui/material/InputBase';
import styled from 'styled-components';
import { setDelayPlan } from '../../Redux/Slice/commonSlice';
import { useDispatch } from 'react-redux';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    height: '45px',
    borderRadius: '8px',
    marginTop: '10px',
    width: '100%', 
    height: '45px', 
    backgroundColor: '#f5f5f5',
    '& .MuiInputBase-input': {
        backgroundColor: '#f5f5f5',
        border: 'none',
        paddingLeft: '20px',
        '&:focus': {
            backgroundColor: '#f5f5f5',
            border: 'none',
        },
    },
}));

function SelectDropdown() {
 
    let dispatch = useDispatch();

    const [reminds, setReminds] = useState("2");

    const handleChange = (e) => {
        setReminds(e.target.value);
        dispatch(setDelayPlan(e.target.value))
    }

    return (
        <>
        <FormLabel id="role" className="form-label" sx={{fontWeight: 600,}}>HKP-Hero erinnert dich in</FormLabel>
        <Select
            labelId="role"
            id="demo-simple-select"
            value={reminds}
            label="Rolle"
            onChange={handleChange}
            input={<BootstrapInput />}
        >
            <MenuItem value={2}>2 Wochen</MenuItem>
            <MenuItem value={3}>3 Wochen</MenuItem>
            <MenuItem value={4}>4 Wochen</MenuItem>
            <MenuItem value={5}>5 Wochen</MenuItem>
        </Select>
        </>
     );
}

export default SelectDropdown;