import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Link from '@mui/material/Link';
import CircleIcon from '@mui/icons-material/Circle';
import PageLoader from '../../pages/PageLoader';
import { useSelector } from 'react-redux';

const flexContainer = {
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    justifyContent: 'center',
    color: '#ad9b95 !important',
    listStyle: 'disc'
};

function AccountFooter() {

    let isLoader = useSelector(state => state.common.isLoader);

    const [show, setShow] = useState(false);

    useEffect(() => {
        if(isLoader){
            setShow(true)
        }
        else{
            setShow(false);
        }
    }, [isLoader]);

    return (
        <>
        <PageLoader show={show}/>
            <Box sx={{ pb: 1, pt: 1 }} className="footer-links">
                <List role="menubar" className='flexContainer'>
                    <ListItem role="none" sx={{ justifyContent: 'center', width: 'auto' }}>
                        <Link href="#" className='text-decoration-none login-grayColor over-underline'>
                            Impressum
                        </Link>
                    </ListItem>
                    <CircleIcon sx={{ fontSize: '7px' }} className='login-grayColor' />
                    <ListItem role="none" sx={{ justifyContent: 'center', width: 'auto' }}>
                        <Link href="#" className='text-decoration-none login-grayColor over-underline'>
                            Datenschutzerklarung
                        </Link>
                    </ListItem>
                    <CircleIcon sx={{ fontSize: '7px' }} className='login-grayColor' />
                    <ListItem role="none" sx={{ justifyContent: 'center', width: 'auto' }}>
                        <Link href="#" className='text-decoration-none login-grayColor over-underline'>
                            Allgemeine Geschaftsbedingungen
                        </Link>
                    </ListItem>
                </List>
            </Box>
        </>
    );
}

export default AccountFooter;