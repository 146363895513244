import React, { useEffect, useState, useRef } from 'react';
import Layout from './Layout';
import { BrowserRouter, Navigate, Outlet, Routes, Route, Link, useLocation, useNavigate } from 'react-router-dom';
import AccountLayout from './Layout/AccountLayout/Index';
import Login from './pages/Login';
import PracticeSetting from './pages/PracticeSetting';
import ResetPassword from './pages/ResetPassword';
import HomePage from './pages/Home';
import { useCookies } from 'react-cookie';
import Team from './pages/Team';
import ChatSetting from './pages/ChatSetting';
import ChatPopover from './Components/Chat/ChatPopover';



function App() {
  let location = useLocation();
  return (
    <>
      <Routes>
        <Route path='/' element={<RestrictLogin><AccountLayout /></RestrictLogin>}>
          <Route index element={<Login />} />
          <Route path='resetPassword' element={<ResetPassword />} />
        </Route>
        <Route element={<PrivateRoute><Layout /></PrivateRoute>} >
          <Route path='/home' element={<HomePage />} />
          <Route element={<PracticeSetting />}>
            <Route path='/practiceSetting/team' element={<Team />} />
            <Route path='/practiceSetting/chatSetting' element={<ChatSetting />} />
          </Route>
        </Route>
      </Routes>
      {location.pathname !== "/" && !location.pathname.includes("resetPassword")?
        <ChatPopover />
        : null
      }

    </>
  );
}

export default App;

function PrivateRoute({ children }) {
  const [cookies, setCookie, removeCookie] = useCookies(['authToken']);

  return (
    <>
      {isAuthenticated(cookies) ? children : <Navigate to="/" replace />}
    </>
  );
}

function RestrictLogin({ children }) {
  const [cookies, setCookie, removeCookie] = useCookies(['authToken']);

  return (
    <>
      {!isAuthenticated(cookies) ? children : <Navigate to="/home" replace />}
    </>
  );
}

const isAuthenticated = (cookies) => {
  let token = cookies.authToken;
  if (!!token) {
    return true
  } else {
    return false
  }
}