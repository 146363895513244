import React, { useState } from 'react';
import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import PrimaryButton from '../PrimaryButton';
import ChatUserList from './ChatUserList';
import { useDispatch } from 'react-redux';
import { setWelcome, setChatList } from '../../Redux/Slice/chatSlice';
const MsgWrapper = styled.div`
background-color:rgb(248,246,248);
padding:20px;
border-radius:20px
`
const Imgbox = styled.div`
display:flex;
justify-content: center;
`

function WelcomeMessage(props) {
    let dispatch = useDispatch();
    const handleWelcomeClick = () => {
        dispatch(setWelcome(false))
        //dispatch(setChatList(true))
    }
    return (
        <>
            <MsgWrapper>
                <Imgbox>
                    <img src='/images/chat.png' width='80' />
                </Imgbox>
                <Typography sx={{ textAlign: 'center', my: 1 }} variant="h6" component="h6">Der Smart-Chat</Typography>
                <Typography sx={{ textAlign: 'center', mb: 2 }} variant="p" component="p">
                    Der Smart-Chat von HKP-Hero hilt ir dabei ie Kommunikation mit Patientinnen effekev und adem zu gestalten
                </Typography>
                <PrimaryButton title="okay" BtnWidth="100%" HandleClick={handleWelcomeClick} />
            </MsgWrapper>
        </>
    );
}
export default WelcomeMessage

