import { React, useState } from 'react';
import styled from 'styled-components';
import { ChatHead, ChatHeadBox, DisplayFlex, ChatBack, ChatBodyInner, MsgTitle, MsgText, MsgTextDivider, MsgTextFrom, MsgDetailBox, MsgDateTime, MsgReadIcon, WhatsAppMsg, ReplyBox, Reply, ReplyTitle, ReplyDetail, ReplyDateTime } from './ChatWhatsApp';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import SendIcon from '@mui/icons-material/Send';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useDispatch } from 'react-redux';
import { setWelcome, setSameNumber, setChatList, setWhatsAppChat, setChatSMS, setchatNotContact, setChatDisable } from '../../Redux/Slice/chatSlice';
import { DropdownWrapper } from '../HeaderDropdownWrapper';
import { Box } from '@mui/material';
import ChatConsent from '../Dialogs/ChatConsentModal';
import Avatar from '../Avatar';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { ChatAvatarBox, ChatTitle, SMSMsgBox } from './ChatSMS';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';

export const DisableChatBody = styled.div`
display: flex;
flex-direction: column;
justify-content: end;
height:100%;
overflow-y:auto;
`
export const DisableBox = styled.div`
padding:10px;
background-color:#fff8dd;
text-align:center;
font-size:15px;
border-radius:7px;
margin-top:30px;
margin-left:24px;
margin-right:24px;
`
function ChatDisable() {
    const [ConsentModal, setConsentModal] = useState(false);
    let dispatch = useDispatch();
    const handleBackClick = () => {
        dispatch(setWelcome(false))
        dispatch(setChatList(true))
        dispatch(setWhatsAppChat(false))
        dispatch(setSameNumber(false))
        dispatch(setChatSMS(false))
        dispatch(setchatNotContact(false))
        dispatch(setChatDisable(false))
    }
    return (
        <>
            <DisableChatBody>
                <div>
                    <SMSMsgBox>
                        <WhatsAppMsg>
                            <DisplayFlex>
                                <Box>
                                    <Avatar noMargin src='https://localhost:44314/AccountImages/dcf95503-2eea-4e9d-a2ba-9a00885d9ef5.jpeg' usernameProps="Ess Patient" className="table" width={25} height={25} />
                                </Box>
                                <Box>
                                    <MsgTitle>Ess Patient</MsgTitle>
                                </Box>
                            </DisplayFlex>
                            <MsgText>HUhu</MsgText>
                            <MsgTextDivider>---</MsgTextDivider>
                            <MsgTextFrom>Gallenbach & Zahne Zahnarztpraxis</MsgTextFrom>
                            <MsgDetailBox>
                                <MsgDateTime>11.12.22, 14:33</MsgDateTime>
                                <MsgReadIcon>
                                    <DoneAllIcon sx={{ fontSize: '18px' }} />
                                </MsgReadIcon>
                            </MsgDetailBox>
                        </WhatsAppMsg>
                    </SMSMsgBox>
                    <ReplyBox>
                        <Reply>
                            <Box>
                                <ReplyTitle>Jap</ReplyTitle>
                                <ReplyDetail sx={{ marginTop: '5px' }}>
                                    <ReplyDateTime>11.12.22  14:33</ReplyDateTime>
                                </ReplyDetail>
                            </Box>
                        </Reply>
                    </ReplyBox>
                </div>
                <DisableBox>
                    Entweder Sven Markus Gallenbach ist in deiner PVS deaktiviert worden oder mittlerweile Teil eines Gruppenchats und kann nur dort Nachrichten empfangen.
                </DisableBox>
            </DisableChatBody>








            {/* <ChatHead>
                <ChatHeadBox>
                    <DisplayFlex>
                        <ChatBack>
                            <NavigateBeforeIcon onClick={handleBackClick} />
                        </ChatBack>
                        <ChatAvatarBox>
                            <Avatar noMargin usernameProps="Peter Beispiel" className="table" width={21} height={21} />
                        </ChatAvatarBox>
                        <ChatTitle>
                            Peter Beispiel
                        </ChatTitle>
                        <ReportProblemIcon sx={{ color: '#AB9892', fontSize: '22px' }} />
                    </DisplayFlex>
                    <Box display="flex" justifyContent="center" alignItems="center">
                        <DropdownWrapper InnerText="Einverstandnis andem" bodyType={"ChatAction"} userId="" setConsentModal={setConsentModal} noarrow iconBg={false} icon={<MoreHorizIcon style={{ color: '#AB9892' }} />} />
                    </Box>
                </ChatHeadBox>
            </ChatHead>
            <DisableChatBody className='scroll'>
                <ChatBodyInner>
                    <div>
                        <SMSMsgBox>
                            <WhatsAppMsg>
                                <DisplayFlex>
                                    <Box>
                                        <Avatar noMargin src='https://localhost:44314/AccountImages/dcf95503-2eea-4e9d-a2ba-9a00885d9ef5.jpeg' usernameProps="Ess Patient" className="table" width={25} height={25} />
                                    </Box>
                                    <Box>
                                        <MsgTitle>Ess Patient</MsgTitle>
                                    </Box>
                                </DisplayFlex>
                                <MsgText>HUhu</MsgText>
                                <MsgTextDivider>---</MsgTextDivider>
                                <MsgTextFrom>Gallenbach & Zahne Zahnarztpraxis</MsgTextFrom>
                                <MsgDetailBox>
                                    <MsgDateTime>11.12.22, 14:33</MsgDateTime>
                                    <MsgReadIcon>
                                        <DoneAllIcon sx={{ fontSize: '18px' }} />
                                    </MsgReadIcon>
                                </MsgDetailBox>
                            </WhatsAppMsg>
                        </SMSMsgBox>
                        <ReplyBox>
                            <Reply>
                                <Box>
                                    <ReplyTitle>Jap</ReplyTitle>
                                    <ReplyDetail sx={{ marginTop: '5px' }}>
                                        <ReplyDateTime>11.12.22  14:33</ReplyDateTime>
                                    </ReplyDetail>
                                </Box>
                            </Reply>
                        </ReplyBox>
                        <DisableBox>
                            Entweder Sven Markus Gallenbach ist in deiner PVS deaktiviert worden oder mittlerweile Teil eines Gruppenchats und kann nur dort Nachrichten empfangen.
                        </DisableBox>
                    </div>
                </ChatBodyInner>
            </DisableChatBody>
            <ChatConsent isShow={ConsentModal} hideConsentModal={setConsentModal} /> */}
        </>
    );
}

export default ChatDisable;