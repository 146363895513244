import { React, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import styled from 'styled-components';
import CloseIconWrap from '../CloseIconWrap';
import { Grid } from '@mui/material';
import CustomRadio from '../Inputs/Radio';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { DisplayFlex } from '../Chat/ChatWhatsApp';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import Avatar from '../Avatar';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { dynamicGetApi, dynamicPostApi } from '../../Api';
import moment from 'moment';
import { setRecall } from '../../Redux/Slice/commonSlice';
import { setSelectedConversationId, setSelectedNonConversation } from '../../Redux/Slice/chatSlice';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '50%',
    transform: 'translate(-50%, -50%)',
    borderColor: '#fff',
    bgcolor: 'background.paper',
    borderRadius: '25px',
    boxShadow: 24,
    pt: 2,
    pb: 3,
    px: 2,
};
const ModelTitle = styled.h3`
font-size:20px;
font-weght:500 !important;
text-align:center;
margin:0px;
color:#1a1a1a !important;
`
const ModelText = styled.div`
color:#747474;
&.font-14{
  font-size:14px;
}
`
const Text18 = styled.div`
color:#747474;
font-size:18px;
`
const TextTitle = styled.h3`
font-size:20px;
font-weght:500 !important;
text-align:center;
margin:0px;
color:#1a1a1a !important;
`
const MB10px = styled.div`
margin-bottom:10px;
`
const ChatAlert = styled.div`
background-color: #efeae6 !important;
border-radius: 7px;
padding: 15px;
`
const AlertText = styled.div`
font-size:14px;
color:#747474;
`
export const FlexEnd = styled.div`
display:flex;
justify-content:end;
`
const WhatsAppRadioList = [
    {
        label: "Eingewilligt",
        value: "WhatsApp-Eingewilligt",
    },
    {
        label: "Abgelehnt",
        value: "WhatsApp-Abgelehnt",
    }
];

const SMSRadioList = [
    {
        label: "Eingewilligt",
        value: "SMS-Eingewilligt",
    },
    {
        label: "Abgelehnt",
        value: "SMS-Abgelehnt",
    }
];

function ChatConsent(props) {

    const [cookie, setCookie, removeCookie] = useCookies(["authToken"]);
    let dispatch = useDispatch();
    let navigate = useNavigate();

    const { hideConsentModal } = props;

    let selectedConversationId = useSelector(state => state.chat.selectedConversationId);
    let selectedNonConversation = useSelector(state => state.chat.selectedNonConversation);

    const [WhatsAppRadioValue, setWhatsAppRadioValue] = useState('');
    const [SMSRadioValue, setSMSRadioValue] = useState('');
    const [conversation, setConversation] = useState({});
    const [isChanged, setIsChanged] = useState(false);

    useEffect(() => {
        if (!!selectedConversationId) {
            getData();
        }
    }, []);

    const getData = async () => {
        let url = 'api/chat/getConversationById?conversationId=' + selectedConversationId;
        let res = await dynamicGetApi(url, navigate, setCookie, dispatch);
        setConversation(res.data);
        if (res.data.type === 1) {
            setWhatsAppRadioValue('WhatsApp-Eingewilligt');
            setSMSRadioValue('');
        }
        else if (res.data.type === 2) {
            setWhatsAppRadioValue('');
            setSMSRadioValue('SMS-Eingewilligt');
        }
        else if (res.data.type === 3) {
            setWhatsAppRadioValue('WhatsApp-Eingewilligt');
            setSMSRadioValue('SMS-Eingewilligt');
        }
        else {
            setWhatsAppRadioValue('');
            setSMSRadioValue('');
        }
    }

    const WhatsAppRadioChange = (e) => {
        setWhatsAppRadioValue(e.target.value);
        setIsChanged(true);
    }

    const SMSRadioChange = (e) => {
        setSMSRadioValue(e.target.value);
        setIsChanged(true);
    }

    const saveData = async () => {
        let url = '';
        let data = {};
        if (!!selectedConversationId) {
            url = 'api/chat/saveChatSetting';
            data = {
                conversationId: selectedConversationId,
                conversationType: !!WhatsAppRadioValue && WhatsAppRadioValue !== 'WhatsApp-Abgelehnt' && !!SMSRadioValue && SMSRadioValue !== 'SMS-Abgelehnt' ? 3 : !!WhatsAppRadioValue && WhatsAppRadioValue !== 'WhatsApp-Abgelehnt' ? 1 : !!SMSRadioValue && SMSRadioValue !== 'SMS-Abgelehnt' ? 2 : null
            }
        }
        else if (Object.keys(selectedNonConversation).length > 0) {
            url = 'api/chat/createConversation';
            data = {
                userId: selectedNonConversation.userId,
                conversationType: !!WhatsAppRadioValue && WhatsAppRadioValue !== 'WhatsApp-Abgelehnt' && !!SMSRadioValue && SMSRadioValue !== 'SMS-Abgelehnt' ? 3 : !!WhatsAppRadioValue && WhatsAppRadioValue !== 'WhatsApp-Abgelehnt' ? 1 : !!SMSRadioValue && SMSRadioValue !== 'SMS-Abgelehnt' ? 2 : null
            }
        }
        let res = await dynamicPostApi(data, url, navigate, setCookie, dispatch);
        if(selectedConversationId == null){
            dispatch(setSelectedConversationId(res.data));
            dispatch(setSelectedNonConversation({}));
        }
        dispatch(setRecall(true));
        setIsChanged(false);
        hideConsentModal(false);
    }

    return (
        <>
            <div>
                <Modal
                    open={props.isShow}
                    onClose={() => { setIsChanged(false); hideConsentModal(false); }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    className="position-r"
                >
                    <Box sx={style}>
                        <Box className='d-flex justify-content-between align-items-center' sx={{ pb: 1, borderBottom: '1px solid #ebebeb', position: 'relative' }}>

                            <Box>
                                <ModelTitle>Chat Einverstandnis</ModelTitle>
                            </Box>
                            <Box>
                                <Box sx={{ position: 'absolute', top: '-50px', right: '-55px' }} onClick={() => hideConsentModal(false)}>
                                    <CloseIconWrap />
                                </Box>
                                <Button sx={{ mr: 2 }} type='button' onClick={() => saveData()} className={`${isChanged !== true ? "btn-light" : "btn-primary-cap"}`}>Fertig</Button>
                            </Box>
                        </Box>
                        <Box sx={{ py: 2, px: 3 }}>
                            <MB10px>
                                <DisplayFlex>
                                    <WhatsAppIcon sx={{ color: '#46C254', fontSize: '23px', mr: 1 }} />
                                    <TextTitle>WhatsApp</TextTitle>
                                </DisplayFlex>
                            </MB10px>
                            <MB10px>
                                <ModelText>Beim ersten Versuch eine WhatsApp Nachricht zu versenden, wird die Patient:in nach</ModelText>
                                <ModelText>Einverständnis gefragt.</ModelText>
                            </MB10px>
                            <Grid container spacing={2}>
                                <Grid item md={6}>
                                    <CustomRadio onChange={(e) => WhatsAppRadioChange(e)} defaultValue={WhatsAppRadioValue} radioList={WhatsAppRadioList} />
                                </Grid>
                                <Grid item md={6}>
                                    {!!conversation.type && conversation.type !== 2 ?
                                        <FlexEnd>
                                            <Box>
                                                <Avatar noMargin src={localStorage.getItem("UserImage")} usernameProps="Ess Patient" className="table" width={25} height={25} />
                                            </Box>
                                            <Box>
                                                <Text18>{localStorage.getItem("practiceName")}</Text18>
                                            </Box>
                                            <Box sx={{ ml: 2 }}>
                                                <Text18>{moment(new Date(conversation.enableTime)).format("DD.MM.YYYY")}</Text18>
                                            </Box>
                                        </FlexEnd>
                                        : null
                                    }
                                </Grid>
                            </Grid>
                            <Box sx={{ mt: 3 }}>
                                <MB10px>
                                    <DisplayFlex>
                                        <img width='23' alt="SMS" src='/images/SMSIcon.png' className='mr-8px' />
                                        <TextTitle>SMS</TextTitle>
                                    </DisplayFlex>
                                </MB10px>
                                <CustomRadio onChange={(e) => SMSRadioChange(e)} defaultValue={SMSRadioValue} radioList={SMSRadioList} />
                            </Box>
                            <Box sx={{ mt: 3 }}>
                                <ChatAlert>
                                    <DisplayFlex>
                                        <ReportProblemIcon sx={{ color: '#AB9892', mr: 1 }} />
                                        <AlertText>
                                            Vorgenommene Änderungen erfordern ein schriftlich vorliegendes Einverständnis.
                                        </AlertText>
                                    </DisplayFlex>
                                </ChatAlert>
                            </Box>
                        </Box>
                    </Box>
                </Modal>
            </div>
        </>
    );
}

export default ChatConsent;