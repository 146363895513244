import React from 'react';
import styled from 'styled-components';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { ChatHead, ChatHeadBox, DisplayFlex, GroupTitle, ChatBack } from './ChatWhatsApp';
import { useDispatch } from 'react-redux';
import { setWelcome, setSameNumber, setChatList, setWhatsAppChat } from '../../Redux/Slice/chatSlice';
import { Box } from '@mui/material';
import Avatar from '../Avatar';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info';

const CustomBox = styled.div`
padding-top:10px;
padding-bottom:10px;
border-bottom: 1px solid rgba(237 237 237);
`
const InfoBox = styled.div`
background-color: rgb(248,246,248);
padding:10px;
display:flex;
align-items:center;
margin-top:30px;
border-radius:5px;
`
const InfoText = styled.div`
margin-left:5px;
color:#ad9b95;
font-size:14px;
`



function UserSameNumber() {
    return (
        <>

            <CustomBox>
                <DisplayFlex>
                    <Box>
                        <Avatar noMargin usernameProps="Heinz Gallenbach" className="table" width={25} height={25} />
                    </Box>
                    <Box>
                        <Typography varient='p' component='p' sx={{ fontSize: '16px', margin: 0 }}>Heinz Gallenbach</Typography>
                    </Box>
                </DisplayFlex>
            </CustomBox>
            <CustomBox>
                <DisplayFlex>
                    <Box>
                        <Avatar noMargin usernameProps="Sven Markus Gallenbach" className="table" width={25} height={25} />
                    </Box>
                    <Box>
                        <Typography varient='p' component='p' sx={{ fontSize: '16px', margin: 0 }}>Sven Markus Gallenbach</Typography>
                    </Box>
                </DisplayFlex>
            </CustomBox>
            <InfoBox>
                <Box>
                    <InfoIcon sx={{ color: '#ad9b95' }} />
                </Box>
                <Box>
                    <InfoText>
                        Jede Nachricht, die du in diese Gruppe schickst, wird Onur ein Mal an die folgende Telefonnummer gesendet: +4916091601602
                    </InfoText>
                </Box>
            </InfoBox>
        </>
    );
}

export default UserSameNumber;