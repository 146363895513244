import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { useTheme } from '@mui/material/styles';
import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import DoneIcon from '@mui/icons-material/Done';
import UpdateRoundedIcon from '@mui/icons-material/UpdateRounded';
import TodayIcon from '@mui/icons-material/Today';
import SelectDropdown from '../Inputs/SelectDropdown';
import CloseIconWrap from '../CloseIconWrap';

const AntwortWrap = styled.div`
padding-left:4.4%;
padding-right:4.4%;
padding-top:2.0%;
`
const Avatar = styled.div`
  border-radius:13px;
  background-color:#e7eceb;
  width:45px;
  height:45px;
  color:#63c2a6;
`
const AvatarRotate = styled.div`
  border-radius:13px;
  background-color:#fcf7fa;
  width:45px;
  height:45px;
  color:#120ec5;
`

const DialogTitle = styled.div`
  padding-bottom:0px;
  font-size:24px;
  font-weight:700;
  text-align:center;
`

function BehandlungZugestimmtModal(props) {

    const { open, setOpen, patientName, valueChange, setConsentGranted } = props;

    const onAccept = () => {
        valueChange("consentGranted");
        setConsentGranted(true);
        setOpen(false);
    }

    return (
        <>
            <Dialog
                fullWidth={true}
                maxWidth={'sm'}
                open={open}
                onClose={() => setOpen(false)}
                className="antwort-width-600"
                aria-labelledby="responsive-dialog-title"
            >
                <div onClick={() => setOpen(false)}>
                    <CloseIconWrap />
                </div>
                <AntwortWrap>
                    <DialogTitle id="responsive-dialog-title" align="center" nowrap={true} className="">
                        {"Hat " + patientName + " der Behandlung zugestimmt?"}
                    </DialogTitle>
                    <Typography variant="subtitle2" align="center" className='subtitle-modal'>
                        Kann die Behandlung beginnen?
                    </Typography>
                    <DialogContent>
                        <DialogContentText>
                            <List sx={{ bgcolor: 'background.paper', pt: 0 }}>
                                <ListItem alignItems="flex-start" className='list-bg flex-col-mob-center' onClick={() => onAccept()}>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <DoneIcon className='cross-icon m-icon' />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <div className='text-center-mob'>
                                        <ListItemText
                                            className='badge-text-20'
                                            primary="Behandlung kann beginnen"
                                            sx={{ mb: 0, color: '#363336' }}
                                        />
                                        <Typography
                                            sx={{ lineHeight: '0.20rem' }}
                                            component="span"
                                            color="text.secondary"
                                            className='fs-17'
                                        >
                                           {patientName} mochte die Behandlung durchführen und kann termimert werden
                                        </Typography>
                                    </div>
                                </ListItem>
                                <ListItem alignItems="flex-start" className='list-bg flex-col-mob-center' onClick={()=>{valueChange('delayPlan');setOpen(false)}}>
                                    <ListItemAvatar>
                                        <AvatarRotate>
                                            <UpdateRoundedIcon className='cross-icon m-icon' sx={{ transform: 'rotate(25deg)' }} />
                                        </AvatarRotate>
                                    </ListItemAvatar>
                                    <div className='text-center-mob'>
                                        <ListItemText
                                            className='badge-text-20'
                                            primary={patientName + " braucht mehr Zeit"}
                                            sx={{ mb: 0, color: '#363336' }}
                                        />
                                        <Typography
                                            sx={{ lineHeight: '0.20rem' }}
                                            component="span"
                                            color="text.secondary"
                                            className='fs-17'
                                        >
                                            Plan Wird auf Wiedervorlage gesetzt. HKP-Hero erinnert dich später daran, erneut nachzufragen.
                                        </Typography>
                                    </div>
                                </ListItem>
                                <ListItem alignItems="flex-start" className='list-bg flex-col-mob-center' onClick={() => {valueChange("disablePlan"); setOpen(false)}}>
                                    <ListItemAvatar>
                                        <AvatarRotate>
                                            <TodayIcon className='cross-icon m-icon' />
                                        </AvatarRotate>
                                    </ListItemAvatar>
                                    <div className='text-center-mob'>
                                        <ListItemText
                                            className='badge-text-20'
                                            primary="Der Plan kann deaktiviert werden"
                                            sx={{ mb: 0, color: '#363336' }}
                                        />
                                        <Typography
                                            sx={{ lineHeight: '0.20rem' }}
                                            component="span"
                                            color="text.secondary"
                                            className='fs-17'
                                        >
                                            Sobald du den Plan deaktivierst. ist er in HKP-Hero nicht mehr sichtbar.
                                        </Typography>
                                    </div>
                                </ListItem>
                                <div class="">
                                    <SelectDropdown />
                                </div>
                            </List>
                        </DialogContentText>
                    </DialogContent>
                </AntwortWrap>
            </Dialog>
        </>
    )
}
export default BehandlungZugestimmtModal;