import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import styled from 'styled-components';
const iOSBoxShadow =
  '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';


const Values = styled.div`
color:#747474
`
const Slide = styled.div`
& .IOS {
    color:#120ec5
}
& .IOS .MuiSlider-thumb:before{
    box-shadow: ${iOSBoxShadow};
    background-color:#fff;
}

`

function valuetext(value) {
  return `${value}°C`;
}

export default function RangeSlider(props) {
  const { unit, maxPlanValue, afterChangeValue } = props;
  const [value, setValue] = React.useState([0, maxPlanValue]);

  React.useEffect(() => {
    if (!!afterChangeValue) {
      setValue([afterChangeValue[0],afterChangeValue[1]])
    }
  }, [afterChangeValue]);

  React.useEffect(() => {
    if(!!maxPlanValue){
      setValue([0,maxPlanValue]);
    }
  }, [maxPlanValue]);

  const handleChange = (event, newValue) => {
    console.log(newValue)
    setValue(newValue);
    props.onChangeValue(newValue)
  };

  return (
    <Box>
      <Slide>
        <Slider
          getAriaLabel={() => 'Temperature range'}
          value={value}
          onChange={handleChange}
          valueLabelDisplay="auto"
          getAriaValueText={valuetext}
          disableSwap={true}
          className="IOS"
          max={maxPlanValue}
        />
      </Slide>
      <div className='d-flex align-items-center justify-content-between'>
        <Values>{value[0]} {unit}</Values>
        <Values>{value[1]} {unit}</Values>
      </div>

    </Box>
  );
}