import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { styled } from '@mui/material/styles';

export const UnCheckedIcon = styled('span')(({ theme }) => ({
    borderRadius: '50%',
    width: 18,
    height: 18,
    border: `2px solid #dadada`,
    backgroundColor: '#fff',
}));

export const CheckedIcon = styled(UnCheckedIcon)({
    backgroundColor: '#fff',
    border: `2px solid #dadada`,
    '&:before': {
        display: 'block',
        width: 18,
        height: 18,
        backgroundImage: 'radial-gradient(#120ec5,#120ec5 28%,transparent 32%)',
        content: '""',
    }
});

export function CustomRadioButton(props) {
    return (
        <Radio
            disableRipple
            color="default"
            checkedIcon={<CheckedIcon />}
            icon={<UnCheckedIcon />}
            {...props}
        />
    );
}

function RodioButton(props) {
    const { radioList, onChange } = props;
    return (
        <FormControl sx={{ marginLeft: '10px' }}>
            <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue=""
                name="parent"
                onChange={(e) => onChange(e)}
            >
                {radioList.map(x => {
                    return (
                        <>
                            <FormControlLabel disabled={x.isDisabled ? true : false} checked={x.isChecked} value={x.value} control={<CustomRadioButton className="radio-padding" />} label={x.label} />
                            {x.child?.length > 0 &&
                                <FormControl>
                                    <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        defaultValue=""
                                        name="child"
                                        onChange={(e) => onChange(e)}
                                    >
                                        {x.child.map(y =>
                                            <FormControlLabel disabled={y.isDisabled ? true : false} checked={y.isChecked} sx={{ ml: 2 }} value={y.value} control={<CustomRadioButton className="radio-padding" />} label={y.label} />
                                        )}
                                    </RadioGroup>
                                </FormControl>
                            }
                        </>
                    )
                }
                )}
            </RadioGroup>
        </FormControl>
    );
}

export default RodioButton;