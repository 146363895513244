import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';
import BoltIcon from '@mui/icons-material/Bolt';

export const UnCheckedIcon = styled('span')(({ theme }) => ({
  borderRadius: 6,
  width: 18,
  height: 18,
  border: `2px solid #dadada`,
  backgroundColor: '#fff',

}));

export const CheckedIcon = styled('span')(({ theme }) => ({
  backgroundColor: '#120ec5',
  borderRadius: 6,
  width: 18,
  height: 18,
  border: `2px solid #120ec5`,
  '&:before': {
    display: 'block',
    width: 18,
    height: 18,
    borderRadius: 6,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },

}));



export default function CustomCheckBoxes(props) {
  const { label, checked, onChange, disabled } = props;

  return (
    <FormGroup>
      <FormControlLabel disabled={disabled} name={label} onChange={onChange} checked={checked} control={<Checkbox style={{ padding: "5px 10px 5px 5px" }} disableRipple checkedIcon={<CheckedIcon />} icon={<UnCheckedIcon />} />} label={label} />
    </FormGroup>
  );
}