import React, { useState, useEffect } from 'react';
import Header from './Header';
import { Outlet } from 'react-router';
import PageLoader from './../pages/PageLoader';
import { useSelector, useDispatch } from 'react-redux';
import { setAttachmentAddition, setAttachmentDeleted, setCommentAddOrEdit, setCommentDelete, setUpdatedDescription } from '../Redux/Slice/commentSlice';
import { setMessageReceive } from '../Redux/Slice/chatSlice';
import { SERVER_URL } from './../Config';
import { HubConnectionBuilder } from '@microsoft/signalr';

function Layout() {
    let dispatch = useDispatch();

    let isLoader = useSelector(state => state.common.isLoader);

    const [connection, setConnection] = useState(null);
    const [show, setShow] = useState(false);

    useEffect(() => {
        if (isLoader) {
            setShow(true)
        }
        else {
            setShow(false);
        }
    }, [isLoader]);

    useEffect(() => {
        const newConnection = new HubConnectionBuilder()
            .withUrl(SERVER_URL + 'chatHub')
            .withAutomaticReconnect()
            .build();
        setConnection(newConnection);
    }, []);

    useEffect(() => {
        if (connection) {
            connection.start()
                .then(result => {
                    console.log('Connected!');
                    connection.on("ReceiveAttachment", function (data) {
                        dispatch(setAttachmentAddition(data));
                    });
                    connection.on("AttachmentDeleted", function (data) {
                        dispatch(setAttachmentDeleted(data));
                    });
                    connection.on("CommentAddOrEdit", function (data) {
                        dispatch(setCommentAddOrEdit(data));
                    });
                    connection.on("DeleteComment", function (data) {
                        dispatch(setCommentDelete(data));
                    });
                    connection.on("DescriptionUpdated", function (data) {
                        dispatch(setUpdatedDescription(data));
                    });
                    connection.on("ReceiveMessage", function (data) {
                        dispatch(setMessageReceive(data));
                    });
                    connection.on("ReplyOutsideMessage", function (data) {
                        console.log(data);
                    });
                    connection.on("ReplyOutsideMessageError", function (data) {
                        console.log(data);
                    });
                })
                .catch(e => console.log('Connection failed: ', e));
        }
    }, [connection]);

    return (
        <>
            <Header />
            <div>
                <Outlet />
                <PageLoader show={show} />
            </div>
        </>
    );
}

export default Layout;


