import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const EmptyCardWrapper = styled.div`
background-color:#efeae6 !important;
border-radius:10px;
padding:35px 15px;
cursor:pointer;
display:flex;
justify-content:center;
align-items:center;
`

const Centeritems = styled.div`
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
& p {
    margin: 5px 0 0 0;
    text-align:center;
    color: #ab9892;
    font-size:14px;
}
& svg {
    color: #ab9892;

}
`


function EmptyCard() {
    
    return (
    <EmptyCardWrapper>
        <Centeritems>
            <CheckCircleIcon fontSize='large' color='#ab9892' />
            <p>Nichts erfordert deine Aufmerksamkeit.</p>
        </Centeritems>
    </EmptyCardWrapper>
    );
}

export default EmptyCard;