import React, { useState, useEffect, useRef } from 'react';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';
import WelcomeMessage from './WelcomeMessage';
import ChatUserList from './ChatUserList';
import { useSelector } from 'react-redux';
import { Search, SearchIconWrapper, StyledInputBase } from './../../Components/SearchBar';
import SearchIcon from '@mui/icons-material/Search';

const ChatSearchWrapper = styled.div`
margin-top:20px;
margin-bottom:20px;
`
function ChatWelcome() {
    const welcome = useSelector((state) => state.chat.welcome)
    const chatList = useSelector((state) => state.chat.chatList)

    const [convsersationSearch, setConvsersationSearch] = useState("");

    const debounce = (func, delay) => {
        let timeoutId;
        return function (...args) {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                func.apply(null, args);
            }, delay);
        };
    };

    const handleSearchChange = debounce((e) => {
        let val = e.target.value;
        if (!!val) {
            setConvsersationSearch(val);
        }
        else {
            setConvsersationSearch("");
        }
    })


    return (
        <>
            {welcome === true || chatList === true ?
                <>
                    <Typography sx={{ fontWeight: 600, pt: 1 }} variant="h4" component="h4">
                        Chat
                    </Typography>
                    <ChatSearchWrapper>
                        <Search bgColor="rgb(248,246,248)">
                            <SearchIconWrapper>
                                <SearchIcon />
                            </SearchIconWrapper>
                            <StyledInputBase
                                placeholder="Suche"
                                inputProps={{ 'aria-label': 'search' }}
                                onChange={(e) => handleSearchChange(e)}
                            />
                        </Search>
                    </ChatSearchWrapper>
                </>
                : null
            }
            {welcome && <WelcomeMessage />}
            {chatList && <div key={convsersationSearch}> <ChatUserList convsersationSearch={convsersationSearch} /></div>}

        </>
    );
}

export default ChatWelcome;



