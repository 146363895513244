import React, { useState, useEffect, useRef } from 'react';
import { Box, Button, Typography } from '@mui/material';
import ChatEinstellungenModal from '../Components/Dialogs/ChatEinstellungenModal';
import Divider from '@mui/material/Divider';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { dynamicGetApi, dynamicPostApi } from './../Api';
import { useDispatch, useSelector } from 'react-redux';
import { setRecall } from './../Redux/Slice/commonSlice';


export const Description = styled.p`
font-Weight:400 !important;
color:#7c7c7c;
margin-top:8px !important;
`

function ChatSetting(props) {
    const [cookies, setCookie, removeCookie] = useCookies(['authToken']);
    let dispatch = useDispatch();
    let navigate = useNavigate();

    let reCall = useSelector(state => state.common.recall);

    const [ChatModal, setChatModal] = useState(false);
    const [chatSettingData, setChatSettingData] = useState({});

    useEffect(() => {
        getChatSetting();
    }, []);

    useEffect(() => {
        if (reCall) {
            getChatSetting();
            dispatch(setRecall(false));
        }
    }, [reCall]);

    const getChatSetting = async () => {
        let Url = "api/chatSetting/getChatSetting";
        let response = await dynamicGetApi(Url, navigate, setCookie,dispatch);
        setChatSettingData(response.data);
    }

    return (
        <>
            <Box sx={{ p: 5 }} className='p-mob'>
                <Box className="d-flex align-items-center justify-content-between flex-column-mob">
                    <Typography variant='h5' component='h5' sx={{ fontWeight: '600', color: '#1a1a1a' }}>Chat-Einstellungen</Typography>
                    <Button className='btn-light practiceSetting-btn-mt' onClick={() => setChatModal(true)}>Bearbeiten</Button>
                </Box>
                <Box sx={{ mt: 6.6, }}>
                    <div>
                        <div className="d-flex align-items-center justify-content-between">
                            <Typography variant='p' component='div' sx={{ fontWeight: '600', color: '#3b3b3b' }}>Praxisnummer für WhatsApp und SMS</Typography>
                            <Typography variant='p' sx={{ fontWeight: '600', color: '#a8a8a8' }}>{chatSettingData.phone}</Typography>
                        </div>
                        <Typography variant='p'>
                            <Description>Diese Nummer sehen Patient:innn als Absender der WhatsApp Nachrichten.</Description>
                        </Typography>
                        <Divider sx={{ my: 2 }} />
                    </div>
                    <div>
                        <div className="d-flex align-items-center justify-content-between">
                            <Typography variant='p' sx={{ fontWeight: '600', color: '#3b3b3b' }}>360 Dialog Channel ID</Typography>
                            <Typography variant='p' sx={{ fontWeight: '600', color: '#a8a8a8' }}>{chatSettingData.channelId}</Typography>
                        </div>
                        <Typography variant='p'>
                            <Description>Hiermit Wird 360Dialog der Zugang zur Verwaltung der WhatsApp Nachrichten gewährt</Description>
                        </Typography>
                        <Divider sx={{ my: 2 }} />
                    </div>
                    <div>
                        <div className="d-flex align-items-center justify-content-between">
                            <Typography variant='p' sx={{ fontWeight: '600', color: '#3b3b3b' }}>Anzeigename für Textbausteine</Typography>
                            <Typography variant='p' sx={{ fontWeight: '600', color: '#a8a8a8', textAlign: 'end' }}>{chatSettingData.displayName}</Typography>
                        </div>
                        <Typography variant='p'>
                            <Description>Der Anzeigename sollte folgenden Textbaustein sinnvoll befüllen: Die Praxis [...] möchte Ihnen eine Nachricht schicken.</Description>
                        </Typography>
                        <Divider sx={{ my: 2 }} />
                    </div>
                    <div>
                        <div className="d-flex align-items-center justify-content-between">
                            <Typography variant='p' sx={{ fontWeight: '600', color: '#3b3b3b' }}>Kurzname der Praxis</Typography>
                            <Typography variant='p' sx={{ fontWeight: '600', color: '#a8a8a8', textAlign: 'end' }}>{chatSettingData.shortName}</Typography>
                        </div>
                        <Typography variant='p'>
                            <Description>Möglichst kurzer Name, der diese Praxis eindeutig kennzeichnet und als Signatur bei WhatsApp verwendet wird</Description>
                        </Typography>
                        <Divider sx={{ my: 2 }} />
                    </div>
                </Box>
            </Box>
            <ChatEinstellungenModal isShow={ChatModal} hideChatModal={() => setChatModal(false)} />
        </>
    );
}

export default ChatSetting;