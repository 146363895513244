import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Popover from '@mui/material/Popover';
import SettingsIcon from '@mui/icons-material/Settings';
import BusinessIcon from '@mui/icons-material/Business';
import CustomCheckBoxes from './Inputs/Checkboxes';
import { Navigate, useNavigate } from 'react-router-dom';
import CustomRadio from './Inputs/Radio';
import NightlightRoundIcon from '@mui/icons-material/NightlightRound';
import Button from '@mui/material/Button';
import CallIcon from '@mui/icons-material/Call';
import Avatar from './Avatar';
import { AppLogout } from '../Api';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import RangeSlider from './Inputs/DoubleSlider';
import SearchBar from './SearchBar';
import EditIcon from '@mui/icons-material/Edit';
import { Box } from '@mui/system';
import { useFormik } from 'formik';
import { dynamicGetApi, dynamicPostApi } from './../Api';
import { setRecall, setIsEdit, setApplyFilter, setPlanFilter, setPractitionerFilter, setValueFilter, setTimeFilter, setSortFilter, setSeletedClinicId } from './../Redux/Slice/commonSlice';
import { setSeletedUserId } from './../Redux/Slice/userSlice';
import { Grid, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import { Emoji, EmojiStyle } from "emoji-picker-react";
import { setTemplateToUse } from './../Redux/Slice/chatSlice';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

const HeaderButton = styled.button`
border:none ;
background-color:transparent !important;
font-size:16px;
padding:5px 10px;
font-weight:500;
border-radius:8px;
align-self:center;
display: flex;
align-items: center;
cursor:pointer;
&:hover{
    background-color:#f3efec !important;
    outline:none !important;
}
`
const ChatTemplateHeaderButton = styled.button`
border:none ;
background-color:transparent !important;
font-size:14px;
padding:8px 15px;
font-weight:500;
align-self:center;
display: flex;
align-items: center;
cursor:pointer;
color:#120ec5 !important;
&:hover{
    background-color:#f3efec !important;
    outline:none !important;
    border-radius:20px;
}
`
export const GallenbachButton = styled.button`
border:none ;
background-color:transparent !important;
font-size:16px;
padding:5px 10px;
font-weight:500;
border-radius:8px;
align-self:center;
display: flex;
align-items: center;
cursor:pointer;
&:hover{
    background-color:#f5f5f5 !important;
    outline:none !important;
}
`
const HeaderButtonLeftIcon = styled.div`

${props => props.bg && `background-color:#efeae6 !important;`}
${props => props.newBg && `background-color:${props.newBg};`}
padding:5px;
border-radius:6px;
color:${props => props.color ? props.color : "#ab9892"};
margin-right:5px;
font-size:22px;
display: flex;

`
const HeaderButtonarrow = styled.div`
color:#ab9892;
font-size:26px;
display: flex;

`
const EllipsisText = styled.div`
white-space: nowrap; 
width: 50px; 
overflow: hidden;
text-overflow: ellipsis; 
`
export const CustomWrapper = styled.div`
padding:10px;
width:${props => props.width ? props.width : "250px"};
& .SearchBarWrapper{
  margin: 15px 0;
}
`
export const PlanarternHeading = styled.div`
font-weight:500;
margin-bottom:10px;

`
export const ListWrapper = styled.ul`
margin:0;
padding:0;
`
export const ListItem = styled.li`
margin:0;
list-style-type:none;
border-radius:10px;
padding: 0 15px;
margin-bottom:5px;
margin-left: -8px;

&:hover{
  background-color:#efeae6;
}
`
export const PlanarternFooter = styled.div`
border-top:1px solid #f5f5f5;
padding:10px 10px 0px 10px;
text-align:center;
margin-left:-17px;
margin-right:-17px;
color:#6f6f6f;
& .colorBlue{
  color:#120ec5;

}
`
const BlueNotification = styled.div`
 width:22px;
 height:22px;
 background-color:#120ec5;
 color:#fff;
 font-size:14px;
 border-radius:50%;
 margin-right:10px;
 font-weight:500;
`
const PaddingLeft15 = styled.div`
padding-left:13px;
`
const BlueAnchor = styled.div`
color : #120ec5;
& .addMarginRight{
  margin-right:8px;
}
`
export const FeedbackTextAreaWrapper = styled.div`
background-color:#f5f5f5;
border:1px solid #e8e8e8;
outline:none !important;
padding: 15px;
border-radius:10px;

`
export const FeedbackTextArea = styled.textarea`
width:100%;
background-color:#f5f5f5;
border:none;
outline:none !important;
resize: none;
font-size: 15px;
&::placeholder{
  color:#bdbdbd
}
`
export const ButtonAbsenden = styled.div`
margin-top:20px;
& .pill{
  border-radius:50px
}
`
export const BlueBtn = styled.div`
& .pill{
  background-color: #120ec5;
  color:#fff;
  &:hover{
    background-color:#0e0ba0;
  }
}
& .pill::disabled{
  color: rgba(0, 0, 0, 0.26);
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0.12);
}
`
const EditText = styled.span`
font-size:16px;
color:#1a1a1a;
margin-left:5px;
`
const EditFileHeader = styled.div`
display:flex;
align-items:center;
width:350px;
border-bottom: 1px solid rgba(224, 224, 224, 1);
padding-bottom:15px;
padding-top:10px;
`
const EditFileHeaderTitle = styled.div`
width:90%;
text-align:center;
font-weight:500;
`
const ChatTemplateHeaderTitle = styled.div`
text-align:center;
font-weight:500;
`
const EditFileHeaderIcon = styled.div`
width:10%;
text-align:center;
`
const EditFileBody = styled.div`
padding-top:15px;
padding-bottom:10px;
`
const MemberlistBox = styled.div`
width:220px;
`
const ChatTemplateBox = styled.div`
width:320px;
`
const MemberlistHeader = styled.div`
display:flex;
align-items:center;
border-bottom: 1px solid rgba(224, 224, 224, 1);
padding-bottom:10px;
padding-top:7px;
`
const ChatTemplateHeader = styled.div`
display:flex;
align-items:center;
justify-content:space-between;
border-bottom: 1px solid rgba(224, 224, 224, 1);
padding-bottom:10px;
padding-top:7px;
padding-left:15px !important;
padding-right:15px !important;
`
const MemberlistBody = styled.div`
padding-top:0px;
padding-bottom:0px;
`
const ChatTemplateBody = styled.div`
padding-top:0px;
padding-bottom:0px;
`
const IconTextSearch = styled.div`
border-radius:50%;
background-color:#0f5481;
width:30px;
height:30px;
color:#fff;
display: flex;
align-items: center;
justify-content: center;
margin-right: 10px;
`
const ChatTemplateList = styled.div`
padding-left:15px !important;
padding-right:15px !important;
display:flex;
align-items:center;
justify-content:space-between;
&:hover{
  background-color:rgb(224 224 224 / 60%);
}
`
const ChatTemplateTitle = styled.div`
font-size:14px;
padding-top:8px !important;
padding-bottom:8px !important;
width:85%;
border-bottom: 1px solid rgb(224 224 224 / 60%);
&:hover{
  border-bottom: 1px solid rgb(224 224 224 / 0%);
}
`
const ChatTemplateIcon = styled.div`
padding-top:8px !important;
padding-bottom:8px !important;
`

export function DropdownWrapper(props) {
  let navigate = useNavigate();
  const { icon, title, iconBg, noarrow, buttonClass, bodyType, position, tableName, InnerText, Link_1, Link_2, btnText, headerTitle, inputLabel, inputType, inputValue, EmailList, TemplateList, setTextModuleModel } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const dropdownBody = {
    "GallenbachZahneZahnarztpraxis": <GallenbachZahneZahnarztpraxis closeDropdown={handleClose} navigate={navigate} />,
    "Planarten": <Planarten />,
    "KollegInnen": <KollegInnen />,
    "Sortierung": <Sortierung />,
    "Alle": <Alle />,
    "ProfileDropdown": <ProfileDropdown title={title} />,
    "Feedback": <FeedbackDropdown title={title} />,
    "Planwert": <Planwert title={title} />,
    "TableAction": <TableAction clinicId={props.clinicId} closeDropdown={handleClose} setColleagueModal={props.setColleagueModal} setClinicModal={props.setClinicModal} userId={props.userId} tableName={props.tableName} />,
    "ChatAction": <ChatAction InnerText={props.InnerText} closeDropdown={handleClose} setConsentModal={props.setConsentModal} userId={props.userId} />,
    "NotesAction": <NotesAction Link_1={props.Link_1} Link_2={props.Link_2} closeDropdown={handleClose} noteId={props.noteId} perfromAction={props.perfromAction} />,
    "EditFile": <EditFile title={title} btnText={props.btnText} headerTitle={props.headerTitle} inputLabel={props.inputLabel} inputType={props.inputType} inputValue={props.inputValue} planId={props.planId} attachmentId={props.attachmentId} closeDropdown={handleClose} />,
    "Memberlist": <Memberlist headerTitle={props.headerTitle} EmailList={props.EmailList} closeDropdown={handleClose} />,
    "ChatTemplates": <ChatTemplates templateList={props.templateList} closeDropdown={handleClose} setTextModuleModel={props.setTextModuleModel} />
  }
  return (
    <div>
      <div>
        {bodyType !== "ProfileDropdown" ?
          <HeaderButton
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            className={buttonClass}
          >
            {icon && <HeaderButtonLeftIcon bg={iconBg}>
              {icon}
            </HeaderButtonLeftIcon>}
            {title}
            {!noarrow && <HeaderButtonarrow>
              <ExpandMoreIcon color='inherit' fontSize='inherit' />
            </HeaderButtonarrow>}
          </HeaderButton>
          :
          <div style={{ marginLeft: "10px" }}><Avatar isLogged onClick={handleClick} noMargin username={title} width={30} height={30} /></div>
        }
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          className={`${bodyType === "EditFile" ? "EditFilePopover" : bodyType === 'ChatTemplates' ? 'ChatTemplatesPopover' : "CustomPopoverStyle"}`}
          anchorOrigin={{
            vertical: bodyType === 'ChatTemplates' ? 'top' : 'bottom',
            horizontal: bodyType === 'ChatTemplates' ? 'left' : (position ? "right" : 'left'),
          }}
          transformOrigin={{
            vertical: bodyType === 'ChatTemplates' ? 'bottom' : 'top',
            horizontal: bodyType === 'ChatTemplates' ? 'right' : (position ? "right" : 'left'),
          }}
        >
          <div className='PopOverWrapper'>
            {dropdownBody[bodyType]}
          </div>
        </Popover>
      </div>
    </div>
  );
}

const GallenbachZahneZahnarztpraxis = (props) => {

  return (
    <div className='GallenbachZahneZahnarztpraxisWrapper'>
      <GallenbachButton className='w-100'>
        <HeaderButtonLeftIcon newBg={"#f5f5f5"} color='#bababa' >
          <BusinessIcon />
        </HeaderButtonLeftIcon>
        <EllipsisText className='flex-1'>{localStorage.getItem("practiceName")}</EllipsisText>
        <HeaderButtonarrow style={{ color: "#bababa", marginLeft: "15px" }}>
          <SettingsIcon color='inherit' fontSize='inherit' onClick={() => { props.navigate('/practiceSetting/team'); props.closeDropdown() }} />
        </HeaderButtonarrow>
      </GallenbachButton>
    </div>

  )
}
const TableAction = (props) => {
  const [setCookie] = useCookies();
  let navigate = useNavigate();
  let dispatch = useDispatch();

  const onEditClick = () => {
    dispatch(setSeletedUserId(props.userId));
    dispatch(setIsEdit(true));
    props.setColleagueModal(true);
  }
  const onAddEditClinicClick = () => {
    dispatch(setSeletedClinicId(props.clinicId));
    props.setClinicModal(true);
  }

  const onDeleteClinicClick = async () => {
    let url = "api/clinic/deleteClinic?clinicId=" + props.clinicId;
    let res = await dynamicGetApi(url, navigate, setCookie, dispatch);
    if (res.data) {
      dispatch(setRecall(true));
    }
  }

  return (
    <div className='GallenbachZahneZahnarztpraxisWrapper'>
      {props.tableName === "ManageClinic" ?
        <Box>
          <GallenbachButton className='w-100' onClick={() => { onAddEditClinicClick(); props.closeDropdown() }}>
            <Box sx={{ p: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
              <EditIcon style={{ color: '#bababa', fontSize: '25px' }} />
              <EditText>Klinik bearbeiten</EditText>
            </Box>
          </GallenbachButton>
          <GallenbachButton className='w-100' onClick={() => { onDeleteClinicClick(); props.closeDropdown() }}>
            <Box sx={{ p: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
              <DeleteIcon style={{ color: '#bababa', fontSize: '25px' }} />
              <EditText>Löschen</EditText>
            </Box>
          </GallenbachButton>
        </Box >
        :
        <GallenbachButton className='w-100' onClick={() => { onEditClick(); props.closeDropdown() }}>
          <Box sx={{ p: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
            <EditIcon style={{ color: '#bababa', fontSize: '25px' }} />
            <EditText>Person editieren</EditText>
          </Box>
        </GallenbachButton>
      }
    </div>

  )
}
const ChatAction = (props) => {
  const onEditClick = () => {
    props.setConsentModal(true);
  }

  return (
    <div className='GallenbachZahneZahnarztpraxisWrapper'>
      <GallenbachButton className='w-100' onClick={() => { onEditClick(); props.closeDropdown() }}>
        <Box sx={{ p: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
          <img width={25} src='/images/signature.png' />
          <EditText>{props.InnerText}</EditText>
        </Box>
      </GallenbachButton>
    </div>

  )
}
const NotesAction = (props) => {

  return (
    <div className='notes-dropdown'>
      <GallenbachButton className='w-100' onClick={() => { props.perfromAction("Edit", props.noteId); props.closeDropdown() }}>
        <Box sx={{ p: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
          <EditText>{props.Link_1}</EditText>
        </Box>
      </GallenbachButton>
      <GallenbachButton className='w-100' onClick={() => { props.perfromAction("Delete", props.noteId); props.closeDropdown() }}>
        <Box sx={{ p: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
          <EditText>{props.Link_2}</EditText>
        </Box>
      </GallenbachButton>
    </div>

  )
}
const EditFile = (props) => {
  const [cookies, setCookie, removeCookie] = useCookies(['authToken']);
  let navigate = useNavigate();
  let dispatch = useDispatch();

  const [fileName, setFileName] = useState(props.inputValue);

  const handleSubmit = async () => {
    let attachmentData = {
      attachmentId: props.attachmentId,
      name: fileName,
      planId: props.planId,
      attachmentFile: null,
    }
    var form_data = new FormData();
    for (var key in attachmentData) {
      form_data.append(key, attachmentData[key]);
    }
    let url = 'api/comment/createUpdateAttachment';
    let result = await dynamicPostApi(form_data, url, navigate, setCookie, dispatch);
    if (!!result.data && result.data !== 'Fail To Upload Attachment') {

    }
  }
  return (
    <div className='editFile-dropdown'>
      <Box sx={{ pt: 0, pb: 1, px: 2 }} >
        <EditFileHeader>
          <EditFileHeaderTitle>{props.headerTitle}</EditFileHeaderTitle>
          <EditFileHeaderIcon>
            <CloseIcon onClick={() => props.closeDropdown()} autoFocus className='edit-file-close' />
          </EditFileHeaderIcon>
        </EditFileHeader>
        <EditFileBody>
          <Box>
            <FormLabel className='edit-file-label' id="file-name">{props.inputLabel}</FormLabel>
            <TextField
              margin="normal"
              required
              fullWidth
              placeholder='File Name'
              id="file-name"
              name="file-name"
              size="small"
              type={props.inputType}
              value={fileName}
              sx={{ mb: 2, height: '45px' }}
              onChange={(e) => setFileName(e.target.value)}
            />
          </Box>
          <Button className='btn-primary edit-file-btn' onClick={() => handleSubmit()}>{props.btnText}</Button>

        </EditFileBody>
      </Box>
    </div>
  )
}
const ChatTemplates = (props) => {
  let dispatch = useDispatch();
  const handleClick = () => {
    props.setTextModuleModel(true)
  }

  return (
    <div className='Chat-templates'>
      <Box sx={{ pb: 1 }}>
        <ChatTemplateBox>
          <ChatTemplateHeader>
            <ChatTemplateHeaderTitle>Textbausteine</ChatTemplateHeaderTitle>
            <ChatTemplateHeaderButton onClick={handleClick}>Berbeiten</ChatTemplateHeaderButton>
          </ChatTemplateHeader>
        </ChatTemplateBox>
        <ChatTemplateBody>
          {props.templateList.map((item, index) => (
            <ChatTemplateList className='chat-temp-list-item' onClick={() => dispatch(setTemplateToUse(item.id))}>
              <ChatTemplateIcon>
                <Emoji unified={item.emoji} emojiStyle={EmojiStyle.APPLE} size={25} />
              </ChatTemplateIcon>
              <ChatTemplateTitle className='chat-temp-title'>
                {item.title}
              </ChatTemplateTitle>
            </ChatTemplateList>
          ))}
        </ChatTemplateBody>
      </Box>
    </div>
  )
}
const Memberlist = (props) => {
  return (
    <div className='editFile-dropdown'>
      <Box sx={{ pt: 0, pb: 1, px: 2 }} >
        <MemberlistBox>
          <MemberlistHeader>
            <EditFileHeaderTitle>{props.headerTitle}</EditFileHeaderTitle>
            <EditFileHeaderIcon>
              <CloseIcon onClick={() => props.closeDropdown()} className='edit-file-close' />
            </EditFileHeaderIcon>
          </MemberlistHeader>
          <MemberlistBody>
            <TextField
              margin="normal"
              required
              fullWidth
              placeholder='File Name'
              id="email-list"
              name="email-list"
              size="small"
              sx={{ mb: 2, height: '45px' }}
            />
            {props.EmailList.map((item) => (
              <div
                key={item.id}
                style={{
                  display: 'flex',
                  justifyContent: 'start',
                  alignItems: 'center',
                  margin: '5px 0px',
                }}
              >
                <div>
                  <IconTextSearch>
                    <Typography variant='p' component='div' sx={{ fontWeight: '600', color: '#fff', textTransform: 'uppercase', fontSize: '12px' }}>sg</Typography>
                  </IconTextSearch>
                </div>
                <div>
                  <p className='mt-0px'>{item.text}</p>
                </div>
              </div>
            ))}
            <div>
              <p>All members on the board ({props.EmailList.length})</p>
            </div>
          </MemberlistBody>
        </MemberlistBox>
      </Box>
    </div>
  )
}
const Planarten = (props) => {
  const [cookies, setCookie, removeCookie] = useCookies(['authToken']);
  let navigate = useNavigate();
  let dispatch = useDispatch();

  let planFilterValue = useSelector(state => state.common.planFilter);

  const [CheckboxList, setCheckboxList] = useState([]);
  const [checkedLenght, setCheckedLenght] = useState(0);

  useEffect(() => {
    getDropdownData();
  }, []);

  useEffect(() => {
    setCheckedLenght(CheckboxList?.filter(x => x.isChecked === true)?.length);
  }, [CheckboxList]);

  const getDropdownData = async () => {
    let url = "api/kanban/getDropdownValues";
    let res = await dynamicGetApi(url, navigate, setCookie, dispatch)
    setCheckboxList(res.data.planList)
  }

  const onChange = (e, i, value) => {
    let prevState = [...CheckboxList];
    prevState[i].isChecked = e.target.checked;
    setCheckboxList(prevState);
    let toPassValues = [];
    prevState.filter(x => x.isChecked === true).map(x => toPassValues.push(x.value));
    if (toPassValues.length === 0) {
      resetChecked();
    }
    dispatch(setPlanFilter(toPassValues));
    dispatch(setApplyFilter(true));
  }

  const resetChecked = () => {
    let prevState = [...CheckboxList];
    prevState.map(x => x.isChecked = false);
    setCheckboxList(prevState)
    dispatch(setPlanFilter(""));
    dispatch(setApplyFilter(false));
    dispatch(setRecall(true));
  }
  return (
    <CustomWrapper className='PlanartenWrapper'>
      <PlanarternHeading className='fw-bold'>
        Planarten
      </PlanarternHeading>
      <ListWrapper>
        {
          CheckboxList?.map((x, i) =>
            <ListItem>
              <CustomCheckBoxes onChange={(e) => onChange(e, i, x.value)} label={x?.label} checked={x?.isChecked} />
            </ListItem>
          )
        }
      </ListWrapper>
      <PlanarternFooter>
        {checkedLenght !== 0 ? <div className='d-flex justify-content-center cursorPointer' onClick={resetChecked}> <BlueNotification>{checkedLenght}</BlueNotification> <span className='colorBlue'>Zurucksetzen</span></div> :
          <span>Zurucksetzen</span>}
      </PlanarternFooter>

    </CustomWrapper>

  )
}
const KollegInnen = (props) => {
  const [cookies, setCookie, removeCookie] = useCookies(['authToken']);
  let navigate = useNavigate();
  let dispatch = useDispatch()

  let practitionerFilter = useSelector(state => state.common.practitionerFilter);

  const [userList, setUserList] = useState([]);

  useEffect(() => {
    getDropdownData();
  }, []);

  const getDropdownData = async () => {
    let url = "api/kanban/getDropdownValues";
    let res = await dynamicGetApi(url, navigate, setCookie, dispatch)
    setUserList(res.data.userList)
  }

  const onChangeUser = (e) => {
    dispatch(setPractitionerFilter(e.target.value))
    dispatch(setApplyFilter(true));
  }
  const onClearUser = () => {
    dispatch(setPractitionerFilter(""))
    dispatch(setApplyFilter(false));
    dispatch(setRecall(true));
  }

  return (
    <CustomWrapper width={"260px"} className='KollegInnenWrapper'>
      <PlanarternHeading className='fw-bold'>
        Kolleg:innen
      </PlanarternHeading>
      <div className='SearchBarWrapper'>
        <SearchBar placeholder={"Suche"} bgColor={"rgb(248,246,248)"} />
      </div>
      <ListWrapper>
        <CustomRadio onChange={(e) => onChangeUser(e)} showImage defaultValue={practitionerFilter} radioList={userList} />
      </ListWrapper>
      <PlanarternFooter>
        {practitionerFilter ? <div className='d-flex justify-content-center cursorPointer' onClick={() => onClearUser()}> <BlueNotification>1</BlueNotification> <span className='colorBlue'>Zurucksetzen</span></div> :
          <span>Zurucksetzen</span>}
      </PlanarternFooter>
    </CustomWrapper>

  )
}
const Sortierung = () => {
  let dispatch = useDispatch();

  let sortFilter = useSelector(state => state.common.sortFilter);

  const radioList = [
    {
      label: "Nach Planerstellung",
      value: "Nach Planerstellung",
    },
    {
      label: "Nach Planwert",
      value: "Nach Planwert",
    },
    {
      label: "Nachname (A-Z)",
      value: "Nachname (A-Z)",
    }
  ];

  const onSorChanges = (e) => {
    dispatch(setSortFilter(e.target.value));
    dispatch(setApplyFilter(true));
  }

  return (
    <CustomWrapper width={"220px"} className='SortierungWrapper'>
      <PlanarternHeading className='fw-bold'>
        Sortierung
      </PlanarternHeading>
      <ListWrapper>
        <CustomRadio onChange={(e) => onSorChanges(e)} defaultValue={sortFilter} radioList={radioList} />
      </ListWrapper>

    </CustomWrapper>

  )
}
const Alle = () => {
  let dispatch = useDispatch()

  let timeFilter = useSelector(state => state.common.timeFilter);

  const radioList = [
    {
      label: "Letzte 30 Tage",
      value: "1",
    },
    {
      label: "Letzte 3 Monate",
      value: "3",
    },
    {
      label: "Letzte 6 Monate",
      value: "6",
    },
    {
      label: "Letzte 12 Monate",
      value: "12",
    },
    {
      label: "Alle",
      value: "Alle",
    }
  ];

  const onAlleChange = (e) => {
    dispatch(setTimeFilter(e.target.value));
    dispatch(setApplyFilter(true));
  }

  return (
    <CustomWrapper width={"220px"} className='AlleWrapper'>
      <PlanarternHeading className='fw-bold'>
        Erstellt in
      </PlanarternHeading>
      <ListWrapper>
        <CustomRadio onChange={(e) => onAlleChange(e)} defaultValue={timeFilter} radioList={radioList} />
      </ListWrapper>

    </CustomWrapper>

  )
}
const ProfileDropdown = (props) => {
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(['authToken']);
  //let userName = useSelector(state => state.user.userName);
  let userName = localStorage.getItem("UserName");
  return (
    <CustomWrapper width={"220px"} className='ProfileWrapper'>
      <PaddingLeft15>
        <Avatar isLogged={true} width={36} height={36} />
        <div className='mb-3'></div>
        <PlanarternHeading className='fw-bold'>
          {userName}
        </PlanarternHeading>
      </PaddingLeft15>
      <GallenbachButton onClick={() => AppLogout(removeCookie, navigate)} className='w-100 '>
        <HeaderButtonLeftIcon className='pl-0' newBg={""} color='#bababa' >
          <NightlightRoundIcon />
        </HeaderButtonLeftIcon>
        <div> Abmelden</div>

      </GallenbachButton>

    </CustomWrapper>

  )
}
const FeedbackDropdown = () => {
  const [cookies, setCookie, removeCookie] = useCookies(['authToken']);
  let dispatch = useDispatch();
  let navigate = useNavigate();
  const [value, setValue] = useState("");

  const formik = useFormik({
    initialValues: {
      message: ''
    },
    onSubmit: async values => {
      let url = 'api/user/sendFeedback?message=' + values.message;
      let result = await dynamicGetApi(url, navigate, setCookie, dispatch);
    },
  });

  const setFormikValue = (e) => {
    setValue(e.target.value)
    formik.setFieldValue("message", e.target.value)
  }

  return (
    <CustomWrapper width={"500px"} className='FeedbackWrapper'>
      <Grid container>
        <Grid md={6} sm={6} xs={12}>
          <div>
            <div className='EmailImage'>
              <img width={100} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSNBucbXYlSOU_qY4Zaa8Gp0d27yVlXKmIyxw&usqp=CAU" alt="email Image" />
            </div>
            <PlanarternHeading className='fw-bold'>
              Deine Meinung ist uns wichtig.
            </PlanarternHeading>
            <Typography wrap variant='p'>Wir Wollen deine Ideen, Verbesserungsvorschlage und Kritik fur HKP-Hero horen!</Typography>
            <BlueAnchor className='d-flex align-items-center'><CallIcon className='addMarginRight' fontSize={'16'} /> <span>030 91733040</span> </BlueAnchor>
          </div>
        </Grid>
        <Grid md={6} sm={6} xs={12}>
          <FeedbackTextAreaWrapper>

            <FeedbackTextArea onChange={(e) => setFormikValue(e)} rows={10} placeholder="Was sind deine Gedanken zu HKP-Hero?"></FeedbackTextArea>
          </FeedbackTextAreaWrapper>
          <ButtonAbsenden>
            <BlueBtn className=''>
              <Button type='button' variant="contained" color="inherit" onClick={formik.handleSubmit} className='pill' disabled={value ? false : true}>Absenden</Button>

            </BlueBtn>
          </ButtonAbsenden>
        </Grid>
      </Grid>
    </CustomWrapper>
  )
}
const Planwert = (props) => {
  const [cookies, setCookie, removeCookie] = useCookies(['authToken']);
  let navigate = useNavigate();
  let dispatch = useDispatch()

  let valueFilter = useSelector(state => state.common.valueFilter);

  const [maxPlanValue, setMaxPlanValue] = useState(0);

  useEffect(() => {
    getDropdownData();
  }, []);

  const getDropdownData = async () => {
    let url = "api/kanban/getDropdownValues";
    let res = await dynamicGetApi(url, navigate, setCookie, dispatch)
    setMaxPlanValue(res.data.maxPlanValue)
  }

  const onChangeValue = (e) => {
    dispatch(setValueFilter(e));
    dispatch(setApplyFilter(true));
  }

  return (
    <CustomWrapper width={"220px"} className='SortierungWrapper'>
      <PlanarternHeading className='fw-bold'>
        Planwert
      </PlanarternHeading>
      <div className='w-100'>
        <RangeSlider unit={"€"} onChangeValue={onChangeValue} afterChangeValue={valueFilter} maxPlanValue={maxPlanValue} />
      </div>

    </CustomWrapper>
  )
}