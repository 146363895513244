import React from 'react';
import styled from 'styled-components';
import Button from '@mui/material/Button';


function PrimaryButton(props) {
    const { title, BtnWidth, HandleClick } = props
    const StyledButton = styled(Button)(() => ({
        marginTop: '15px !important',
        padding: '10px 15px !important',
        width: BtnWidth,
        minWidth: BtnWidth,
    }))

    return (
        <>
            <StyledButton className='btn-primary' BtnWidth={BtnWidth} onClick={HandleClick}>{title}</StyledButton>
        </>
    );
}

export default PrimaryButton;