import React, { useState, useEffect, useRef } from 'react';
import Typography from '@mui/material/Typography';
import Avatar from '../Avatar';
import { Box } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useDispatch } from 'react-redux';
import { setChatList, setOpenChat, setSelectedConversationId, setSelectedNonConversation } from '../../Redux/Slice/chatSlice';
import { DisplayFlex } from './ChatWhatsApp';
import moment from "moment";
import { dynamicGetApi } from '../../Api';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';

function ChatUserList(props) {
    const [cookies, setCookie, removeCookie] = useCookies(['authToken']);
    let navigate = useNavigate();
    let dispatch = useDispatch();

    const { convsersationSearch } = props;
    const containerRef = useRef(null);
    const prevScrollTop = useRef(0);

    const [page, setPage] = useState(1);
    const [conversationList, setConversationList] = useState([]);

    // useEffect(() => {
    //     getConversationList();
    // }, []);

    useEffect(() => {
        if (!!convsersationSearch) {
            getConversationListSearchResult(page);
        }
        else {
            getConversationList(page);
        }
    }, [page]);

    useEffect(() => {
        if (page > 1) {
            setPage(1);
        }
        else {
            if (!!convsersationSearch) {
                getConversationListSearchResult(1);
            }
            else {
                getConversationList(1);
            }
        }
    }, [convsersationSearch]);

    useEffect(() => {
        console.log(containerRef.current)
        if (containerRef.current !== null) {
            const container = containerRef.current;
            container.addEventListener('scroll', handleScroll);
            return () => {
                container.removeEventListener('scroll', handleScroll);
            };
        }
    }, [containerRef.current]);

    const handleScroll = async () => {
        const container = containerRef.current;
        if (container.scrollTop > prevScrollTop.current) {
            prevScrollTop.current = container.scrollTop;
            const scrollPosition = container.scrollTop + container.clientHeight;
            const scrollHeight = container.scrollHeight;
            if (scrollPosition >= scrollHeight - 0.7) { // Adjust threshold as needed
                setPage(newPage => newPage + 1);
            }
        }
    };

    const getConversationList = async (pageNo) => {
        let url = 'api/chat/getConversationList?search=' + convsersationSearch + '&page=' + pageNo;
        let res = await dynamicGetApi(url, navigate, setCookie, dispatch);
        const newRecord = res.data;
        if (pageNo == 1) {
            setConversationList(newRecord);
        }
        else {
            setConversationList(conversationList => [...conversationList, ...newRecord]);
        }
    }

    const getConversationListSearchResult = async (pageNo) => {
        let url = 'api/chat/getConversationList?search=' + convsersationSearch + '&page=' + pageNo;
        let res = await dynamicGetApi(url, navigate, setCookie, dispatch);
        const newRecord = res.data;
        if (pageNo == 1) {
            setConversationList(newRecord);
        }
        else {
            setConversationList(conversationList => [...conversationList, ...newRecord]);
        }
    }

    const handleRowClick = (conversation) => {
        dispatch(setChatList(false));
        dispatch(setOpenChat(true));
        if (conversation.conversationId > 0) {
            dispatch(setSelectedConversationId(conversation.conversationId));
        }
        else {
            dispatch(setSelectedNonConversation(conversation));
        }
    }

    return (
        <TableContainer key={convsersationSearch} ref={containerRef} component={Paper} sx={{ boxShadow: 'none', borderRadius: '0px !important', maxHeight: "415px" }}>
            <Table aria-label="simple table">
                <TableBody>
                    {conversationList.length > 0 ?
                        conversationList.map(x =>
                            <TableRow onClick={() => handleRowClick(x)}>
                                <TableCell sx={{ px: 0 }}>
                                    <Box display="flex" justifyContent="start" alignItems="center">
                                        <Box>
                                            <Avatar noMargin usernameProps={x.userName} className="table" width={35} height={35} />
                                        </Box>
                                        <Box sx={{ width: '100%' }} display="flex" justifyContent="space-between" alignItems="center">
                                            <Box>
                                                <Typography varient='p' component='p' sx={{ fontSize: '16px', margin: 0 }}>{x.userName}</Typography>
                                                <Typography varient='p' component='p' sx={{ fontSize: '14px', margin: 0, color: '#bcada7' }}>{x.lastMessage}</Typography>
                                            </Box>
                                            <DisplayFlex>
                                                <Box>
                                                    <Typography varient='p' component='p' sx={{ fontSize: '14px', margin: 0, color: '#bcada7' }}>
                                                        {!!x.date ? moment(new Date(x.date)).isBetween(moment().clone().startOf('isoWeek'), moment().clone().endOf('isoWeek')) ?
                                                            moment(new Date(x.date)).format("ddd, hh:mm a") : moment(new Date(x.date)).format("DD.MM.YYYY")
                                                            : null}
                                                    </Typography>
                                                </Box>
                                                <Box></Box>
                                            </DisplayFlex>
                                        </Box>
                                    </Box>
                                </TableCell>
                            </TableRow>)
                        : <></>}
                </TableBody>
            </Table>
        </TableContainer>
    )
}
export default ChatUserList;