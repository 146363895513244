import React, { useState, useEffect, useRef } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
import { CloseLeftCornor } from '../CloseIconWrap';
import { Box, Button } from '@mui/material';
import Stack from '@mui/material/Stack';
import AttachmentIcon from '@mui/icons-material/Attachment';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import AttachmentCommentList from '../AttachmentCommentList';
import CommentTextBadge from '../CommentTextBadge';
import NewCommentTextbox from '../NewCommentTextbox';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { dynamicGetApi, dynamicPostApi } from './../../Api';
import { setAttachmentAddition, setAttachmentDeleted, setCommentAddOrEdit, setCommentDelete } from '../../Redux/Slice/commentSlice';
import SubjectIcon from '@mui/icons-material/Subject';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToRaw, EditorState, ContentState, convertFromHTML  } from "draft-js";
import draftToHtml from "draftjs-to-html";

const CommentWrap = styled.div`
padding-left:4%;
padding-right:4%;
overflow-y: auto;
margin-bottom: 25px;

`
const PaddingLeft = styled.div`
padding-left: 40px;
`
const EditButton = styled.div`
background-color: ${props => props.bluebg ? "#0079bf" : "#091e420a"} ;
color:${props => props.bluebg ? "#fff" : "#000"};
padding:5px;
${props => !props.noMargin && ` margin-left:10px;`}

 font-size: 14px;
 font-weight: 400;
 justify-content: center;
 line-height: 20px;
 padding: 6px 12px;
 cursor:pointer;
 &:hover{
    background-color: ${props => props.bluebg ? "#026aa7" : "#276fff0a"}  
 }
`
const FakeDescriptionButton = styled.div`
background-color:#091e420a;
border: none;
border-radius: 3px;
box-shadow: none;
color: inherit;
display: block;
min-height: 40px;
padding: 8px 12px;
text-decoration: none;
cursor:pointer;
}
`

function CommentsModal(props) {
    const [cookies, setCookie, removeCookie] = useCookies(['authToken']);
    let dispatch = useDispatch();
    let navigate = useNavigate();

    const { open, setOpen, planId } = props;

    let attachmentAddition = useSelector(state => state.comment.attachmentAddition);
    let attachmentDeleted = useSelector(state => state.comment.attachmentDeleted);
    let commentAddOrEdit = useSelector(state => state.comment.commentAddOrEdit);
    let commentDelete = useSelector(state => state.comment.commentDelete);
    let updatedDescription = useSelector(state => state.comment.updatedDescription);

    const [CommentsList, setCommentsList] = useState([]);
    const [userList, setUserList] = useState([]);
    const [activeUserList, setActiveUserList] = useState([]);
    const [attachment, setAttachment] = useState([]);
    const [editorState, setEditorState] = useState('');
    const [editDescription, setEditDescription] = useState(false);
    const [description, setDescription] = useState('');

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        if (Object.keys(attachmentAddition).length > 0 && attachmentAddition.planId === planId) {
            additionInAttachment(attachmentAddition);
        }
    }, [attachmentAddition]);

    useEffect(() => {
        if (!!attachmentDeleted) {
            deleteAttachment(attachmentDeleted);
        }
    }, [attachmentDeleted]);

    useEffect(() => {
        if (Object.keys(commentAddOrEdit).length > 0 && commentAddOrEdit.comment.planId === planId) {
            commentCreatedOrEdit(commentAddOrEdit);
        }
    }, [commentAddOrEdit]);

    useEffect(() => {
        if (!!commentDelete) {
            deleteComment(commentDelete);
        }
    }, [commentDelete]);

    useEffect(() => {
        if(Object.keys(updatedDescription).length > 0 && updatedDescription.planId === planId){
            setDescription(updatedDescription.description);
            setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(updatedDescription.description))));
        }
    }, [updatedDescription]);

    const getData = async () => {
        let url = "api/comment/getCommentListByPlanId?planId=" + planId;
        var res = await dynamicGetApi(url, navigate, setCookie, dispatch);
        let newCommentList = [];
        res.data.comments.map(x =>
            newCommentList.push({
                id: x.id,
                userName: x.userName,
                comment: x.comment,
                planId: x.planId,
                createdAt: x.createdAt,
                createdBy: x.createdBy,
                updatedAt: x.updatedAt,
                attachments: !!x.attachments ? getCommentMentionedAttachment(res.data.attachments, x.attachments) : [],
                users: !!x.users ? getCommentMentionedUser(res.data.users, x.users) : [],
                isEditable: x.isEditable
            })
        )
        setCommentsList(newCommentList);
        setUserList(res.data.users);
        setActiveUserList(res.data.users.filter(x => x.isActive === true));
        setAttachment(res.data.attachments);
        setDescription(res.data.description);
        setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(res.data.description))));
    }

    const getCommentMentionedUser = (userList, users) => {
        let mentionedUsersIds = users.split(',');
        let mentionedUsersList = [];
        mentionedUsersIds.map(x => {
            if (!!userList.find(a => a.userId === parseInt(x))) {
                mentionedUsersList.push(userList.find(y => y.userId === parseInt(x)))
            }
        });
        return mentionedUsersList;
    }

    const getCommentMentionedAttachment = (attachmentList, attachments) => {
        let mentionedAttachmentsIds = attachments.split(',');
        let mentionedAttchmentsList = [];
        mentionedAttachmentsIds.map(x => {
            if (!!attachmentList.find(a => a.attachmentId === parseInt(x))) {
                mentionedAttchmentsList.push(attachmentList.find(y => y.attachmentId === parseInt(x)));
            }
        });
        return mentionedAttchmentsList;
    }

    const editOrUnEditComment = (index) => {
        let prevstate = [...CommentsList];
        prevstate[index].isEditable = !prevstate[index].isEditable;
        setCommentsList(prevstate);
    }

    const additionInAttachment = (data) => {
        let attachmentList = attachment;
        let newChanges = {
            attachmentId: data.attachmentId,
            name: data.name,
            createdAt: data.createdAt,
            createdBy: data.createdBy,
            key: data.key,
            contentType: data.contentType
        }
        if (data.isEdit) {
            let newAttachmentList = [];
            attachmentList.map(x => x.attachmentId === data.attachmentId ? newAttachmentList.push(newChanges) : newAttachmentList.push(x));
            attachmentList = newAttachmentList;
        }
        else {
            attachmentList.push(newChanges);
        }
        setAttachment(attachmentList);
        dispatch(setAttachmentAddition({}));
    }

    const deleteAttachment = (data) => {
        let attachmentList = attachment;
        setAttachment(attachmentList.filter(x => x.attachmentId !== data));
        dispatch(setAttachmentDeleted(null));
    }

    const commentCreatedOrEdit = (data) => {
        let _commnetList = CommentsList;
        let newComment = {
            id: data.comment.id,
            userName: data.comment.userName,
            comment: data.comment.comment,
            planId: data.comment.planId,
            createdAt: data.comment.createdAt,
            createdBy: data.comment.createdBy,
            updatedAt: data.comment.updatedAt,
            attachments: !!data.comment.attachments ? getCommentMentionedAttachment(attachment, data.comment.attachments) : [],
            users: !!data.comment.users ? getCommentMentionedUser(userList, data.comment.users) : [],
            isEditable: data.comment.isEditable
        }
        if (data.isEdit) {
            let newCommentList = [];
            _commnetList.map(x => x.id === data.comment.id ? newCommentList.push(newComment) : newCommentList.push(x));
            _commnetList = newCommentList;
        }
        else {
            _commnetList.unshift(newComment);
        }
        setCommentsList(_commnetList);
        dispatch(setCommentAddOrEdit({}));
    }

    const deleteComment = (data) => {
        let _commnetList = CommentsList;
        setCommentsList(_commnetList.filter(x => x.id !== data));
        dispatch(setCommentDelete(null));
    }

    const onEditorStateChange = (e) => {
        setEditorState(e);
    }

    const saveDescription = async () => {
        let url = 'api/comment/planDescription';
        let data = { planId: planId, description: draftToHtml(convertToRaw(editorState.getCurrentContent())) }
        let res = await dynamicPostApi(data, url, navigate, setCookie, dispatch);
        setEditDescription(!editDescription)
    }

    return (
        <>
            <Dialog
                fullWidth={true}
                maxWidth={'sm'}
                open={open}
                onClose={() => setOpen(false)}
                className="antwort-width-600 modal-border-radius overflow-inherit-com"
                aria-labelledby="responsive-dialog-title"
                PaperProps={{ style: { backgroundColor: '#f8f5f4', overflow: "inherit" }, }}
            >
                <div className='' onClick={() => setOpen(false)}>
                    <CloseLeftCornor right />
                </div>
                <div>
                    <Typography variant='h5' component='div' sx={{ fontWeight: '600', color: '#3b3b3b', textAlign: 'center', my: 2 }}>Comments</Typography>
                </div>
                <CommentWrap className='scroll'>

                    <DialogContent>
                        <DialogContentText>
                            <Stack>
                                <Box display="flex" justifyContent="start" alignItems="center" className="flex-between-mob px-0-mob flex-col-center-sx">
                                    <div>
                                        <SubjectIcon className='cross-icon m-icon' />
                                    </div>
                                    <div className='d-flex'>
                                        <ListItemText className='badge-text-20 text-center-mob' sx={{ mt: 0 }} primary="Description" />
                                        {!!description && editDescription == false && <EditButton onClick={() => setEditDescription(!editDescription)}>Edit</EditButton>}

                                    </div>
                                </Box>
                                <Box>
                                    <PaddingLeft>
                                        {editDescription === false && <FakeDescriptionButton onClick={() => setEditDescription(!editDescription)} dangerouslySetInnerHTML={{ __html: !!description ? description : '<p>Add a more detailed description</p>'}}></FakeDescriptionButton>}
                                        {editDescription === true &&
                                            <>
                                                <Editor
                                                    editorState={editorState}
                                                    toolbarClassName="toolbarClassName mb-0"
                                                    wrapperClassName="wrapperClassName"
                                                    editorClassName="bg-white editorClassMain"
                                                    onEditorStateChange={(e) => onEditorStateChange(e)}
                                                    toolbar={{
                                                        options: ['list', 'link', 'image', 'emoji', 'blockType', 'inline'],
                                                        blockType: {
                                                            inDropdown: true,
                                                            options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Code'],
                                                            className: undefined,
                                                            component: undefined,
                                                            dropdownClassName: undefined,
                                                        },
                                                        inline: {
                                                            inDropdown: false,
                                                            className: undefined,
                                                            component: undefined,
                                                            dropdownClassName: undefined,
                                                            options: ['bold', 'italic', 'strikethrough',],
                                                            bold: { className: undefined },
                                                            italic: { className: undefined },
                                                            strikethrough: { className: undefined },
                                                        },
                                                        list: {
                                                            inDropdown: true,
                                                            className: undefined,
                                                            component: undefined,
                                                            dropdownClassName: undefined,
                                                            options: ['unordered', 'ordered'],
                                                            unordered: { className: undefined },
                                                            ordered: { className: undefined },

                                                        },
                                                        link: {
                                                            inDropdown: false,
                                                            className: undefined,
                                                            component: undefined,
                                                            popupClassName: undefined,
                                                            dropdownClassName: undefined,
                                                            showOpenOptionOnHover: true,
                                                            defaultTargetOption: '_self',
                                                            options: ['link'],
                                                            link: { className: undefined },
                                                            linkCallback: undefined
                                                        },

                                                    }}
                                                />
                                                <div className='SaveCancel d-flex mt-2'>
                                                    <EditButton noMargin bluebg onClick={() => saveDescription()}>Save</EditButton>
                                                    <EditButton onClick={() => setEditDescription(false)}>Cancel</EditButton>
                                                </div>
                                            </>
                                        }
                                    </PaddingLeft>
                                </Box>
                            </Stack>
                            <Stack>
                                <Box display="flex" justifyContent="start" alignItems="center" sx={{ pt: 1 }} className="flex-between-mob px-0-mob flex-col-center-sx">
                                    <div>
                                        <AttachmentIcon className='cross-icon m-icon rotate-40' />
                                    </div>
                                    <div>
                                        <ListItemText className='badge-text-20 text-center-mob' sx={{ mt: 0 }} primary="Anhänge" />
                                    </div>
                                </Box>
                                <AttachmentCommentList attachment={attachment} planId={planId} />
                            </Stack>
                            <Stack>
                                <div>
                                    <div className="d-flex align-items-center justify-content-between grid-main-zustimm">
                                        <Box display="flex" justifyContent="start" alignItems="center" className="flex-between-mob px-0-mob flex-col-center-sx">
                                            <div>
                                                <FormatListBulletedIcon className='cross-icon m-icon' />
                                            </div>
                                            <div>
                                                <ListItemText className='badge-text-20 text-center-mob' sx={{ mt: 0 }} primary="Aktivitåt" />
                                            </div>
                                        </Box>
                                    </div>
                                </div>
                            </Stack>
                            <Stack>
                                <NewCommentTextbox planId={planId} userList={activeUserList} />
                            </Stack>
                            {CommentsList.map((x, i) =>
                                <Stack>
                                    <CommentTextBadge editOrUnEditComment={editOrUnEditComment} data={x} index={i} planId={planId} userList={activeUserList} />
                                </Stack>
                            )}
                        </DialogContentText>
                    </DialogContent>
                </CommentWrap>
            </Dialog>
        </>
    )
}
export default CommentsModal;