import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderColor: '#fff',
    bgcolor: 'background.paper',
    borderRadius: '16px',
    boxShadow: 24,
    pt: 4,
    pb: 3,
    px: 2,
};
function EmailConfirmation(props) {
    return (
        <>
            <div>
                <Modal
                    open={props.isShow}
                    onClose={props.hideEmailConfModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style} className='modal-width' >
                        <Box sx={{ textAlign: 'center' }}>
                            <img src="/images/email-confirmation.png" width='150' />
                        </Box>
                        <Typography id="modal-modal-title" variant="h5" component="h5" align='center' sx={{ mt: 2 }}>
                            <Box sx={{ fontWeight: 600 }}>
                                E-Mail gesendet
                            </Box>
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2, mb: 3 }} align='center'>
                            HKP-Hero hat dir einen Link zum Zurücksetzen deines
                            Passworts an dcdscdsav@gmail.com geschickt. Folge den
                            Anleitungen in der E-Mail um dein Passwort
                            zurückzusetzen.
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2, pt: 2, borderTop: '1px solid #F5F5F5' }}>
                            <Button className='btn-primary' onClick={props.closeAll}>
                                Zurück zum login
                            </Button>
                        </Box>
                    </Box>
                </Modal>
            </div>
        </>
    );
}

export default EmailConfirmation;