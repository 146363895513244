import { React, useState, useEffect, useLayoutEffect, useRef } from 'react';
import styled from 'styled-components';
import ChatWhatsApp, { ChatHead, ChatHeadBox, DisplayFlex, ChatBack, ChatBodyInner, MsgTitle, MsgText, MsgTextDivider, MsgTextFrom, MsgDetailBox, MsgDateTime, MsgReadIcon, MsgBox, MsgInput, MsgSubmitBox, SubmitDivider, SubmitText, ReplyBox, Reply, ReplyTitle, ReplyDetail, ReplyDateTime, WhatsAppMsg } from './ChatWhatsApp';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import SendIcon from '@mui/icons-material/Send';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useDispatch, useSelector } from 'react-redux';
import { setWelcome, setSameNumber, setChatList, setWhatsAppChat, setChatSMS, setchatNotContact, setOpenChat, setTemplateToUse } from '../../Redux/Slice/chatSlice';
import { DropdownWrapper } from '../HeaderDropdownWrapper';
import { Box } from '@mui/material';
import ChatConsent from '../Dialogs/ChatConsentModal';
import Avatar from '../Avatar';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { dynamicGetApi } from '../../Api';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import jwt_decode from "jwt-decode";
import PostAddIcon from '@mui/icons-material/PostAdd';
import TextModulesModal from '../Dialogs/TextModulesModal';
import DialogParent from '../Dialogs/DialogParent';
import moment from "moment";
import CreateTextBlockModal from '../Dialogs/CreateTextBlockModal';
import { dynamicPostApi } from '../../Api';
import { setMessageReceive, setSelectedConversationId, setSelectedNonConversation } from './../../Redux/Slice/chatSlice';
import { setRecall } from './../../Redux/Slice/commonSlice';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { GroupHead, GroupHeadInner, WhatsAppMsgBox } from './GroupChat';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Typography from '@mui/material/Typography';
import UserSameNumber from './UserSameNumber';
import ChatDisable, { DisableChatBody, DisableBox } from './ChatDisable';
import WarningIcon from '@mui/icons-material/Warning';
import Swal from 'sweetalert2';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';


export const SMSChatBody = styled.div`
display: flex;
flex-direction: column;
// justify-content: end;
height:400px;
overflow-y:auto;
margin-left:-24px;
margin-right:-24px;
`
export const SMSMsgBox = styled.div`
display:flex;
flex-direction: column;
justify-content: center;
align-items:end;
justify-content:end;
margin-top:15px;
`
export const SMSMsg = styled.div`
width:75%;
background-color:#BDBABD;
border-radius:12px;
padding:15px;
`
export const SMSMsgLowerText = styled.div`
margin-top:10px;
color:#BDBABD;
font-size:15px;
`
export const ChatTitle = styled.h3`
margin-right:5px;
margin-top:0px;
margin-bottom:0px;
font-weight:600;
font-size:18px;
`
export const ChatAvatarBox = styled.div`
margin-left:20px
`
const MsgArrowBox = styled.div`
padding: 5px !important;
position:absolute;
cursor:pointer;
transform: translate(7px,375px);
`
const MsgArrow = styled.span`
border-radius:50%;
padding: 5px !important;
background-color: #120EC5 !important;
display:flex;
`
const ChatContactBox = styled.div`
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
height:450px;
`
const ChatContactTitle = styled.h4`
margin:15px 0px;
`
const ChatContactText = styled.div`
color:#BDBABD;
text-align:center;
font-size:15px;
`

const ChatTemplatesList = [
    {
        icon: '1f64b-200d-2640-fe0f',
        title: 'HKP Statusabfrage nach 4 Wochen'
    },
    {
        icon: '1f9d0',
        title: 'HKP Statusabfrage nach 6 Wochen'
    },
    {
        icon: '1f44b',
        title: 'Aligner Statusabfrage nach 1 Woche'
    },
    {
        icon: '1f914',
        title: 'Aligner Statusabfrage nach 2 Wochen'
    },
    {
        icon: '231b',
        title: 'Aligner Statusabfrage nach 3 Wochen'
    },
    {
        icon: '1f9d6',
        title: 'Antwort der Kasse zum HKP erhalten?'
    }
]

function SingalChat() {
    const [cookies, setCookie, removeCookie] = useCookies(["authToken"]);
    let dispatch = useDispatch();
    let navigate = useNavigate();

    let selectedConversationId = useSelector(state => state.chat.selectedConversationId);
    let messageReceive = useSelector(state => state.chat.messageReceive);
    let templateToUse = useSelector(state => state.chat.templateToUse);
    let recall = useSelector(state => state.common.recall);
    let selectedNonConversation = useSelector(state => state.chat.selectedNonConversation);

    const [ConsentModal, setConsentModal] = useState(false);
    const [conversation, setConversation] = useState({});
    const [textModuleModel, setTextModuleModel] = useState(false);
    const [createTextBlockModel, setCreateTextBlockModel] = useState(false);
    const [msgText, setMsgText] = useState('');
    const [templateList, setTemplateList] = useState([]);
    const [isScrolled, setIsScrolled] = useState(false);
    const [isScrollToDownEnabled, setIsScrollToDownEnabled] = useState(false);
    const [chatDownBtnClicked, setChatDownBtnClicked] = useState(false);
    const [defaultChat, setDefaultChat] = useState(true);
    const [sameNumberUserShow, setSameNumberUserShow] = useState(false);
    const [nonConversation, setNonConversation] = useState({});

    const myRef = useRef(null);

    useEffect(() => {
        if (!!selectedConversationId) {
            getConversationData();
        }
    }, [selectedConversationId]);

    useEffect(() => {
        if (Object.keys(selectedNonConversation).length > 0) {
            setConversation(selectedNonConversation);
        }
    }, [selectedNonConversation]);

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        if (recall) {
            getData();
            getConversationData();
        }
    }, [recall]);

    useEffect(() => {
        if (!!templateToUse) {
            let data = { conversationId: selectedConversationId, templateId: templateToUse, patientId: conversation.userId };
            postTemplate(data);
            dispatch(setTemplateToUse(null));
        }
    }, [templateToUse]);

    const getData = async () => {
        let url = 'api/conversationTemplates/getAllConversationTemplates';
        let res = await dynamicGetApi(url, navigate, setCookie, dispatch);
        if (!!res.data) {
            setTemplateList(res.data);
            dispatch(setRecall(false));
        }
    }

    const getConversationData = async () => {
        let url = 'api/chat/getChatByConversationId?conversationId=' + selectedConversationId;
        let res = await dynamicGetApi(url, navigate, setCookie, dispatch);
        setConversation(res.data);
        dispatch(setRecall(false));
    }

    const postTemplate = async (data) => {
        let url = "api/chat/sendSystemTemplate";
        let res = await dynamicPostApi(data, url, navigate, setCookie, dispatch);
    }

    const handleBackClick = () => {
        if (sameNumberUserShow) {
            dispatch(setOpenChat(true));
            dispatch(setChatList(false))
        } else {
            dispatch(setOpenChat(false))
            dispatch(setChatList(true))
        }
        dispatch(setWelcome(false))
        dispatch(setchatNotContact(false))
        dispatch(setSelectedConversationId(null));
        dispatch(setSelectedNonConversation({}));
        setDefaultChat(true);
        setSameNumberUserShow(false);
    }

    const postMsg = async () => {
        let url = 'api/chat/addOrUpdateMessage';
        let data = { chatId: selectedConversationId, message: msgText };
        let res = await dynamicPostApi(data, url, navigate, setCookie, dispatch);
        if (res.data !== true) {
            Swal.fire({
                title: 'Send template?',
                text: "It's been more than 24 hours since the last message. You need to send the template again to the patient for further communication.",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#120EC5',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Send template!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    var url = "api/chat/sendTemplate?conversationId=" + selectedConversationId;
                    let res = await dynamicGetApi(url, navigate, setCookie, dispatch);
                    if (res.data) {
                        Swal.fire(
                            'Sent!',
                            'Template send successfully.',
                            'success'
                        )
                    }
                }
            })
        } else {

        }
    }

    useEffect(() => {
        if (Object.keys(messageReceive).length > 0 && messageReceive.chatId === selectedConversationId) {
            let newConversation = conversation;
            newConversation.userChat.push(messageReceive)
            setConversation(newConversation);
            setMsgText('');
            dispatch(setMessageReceive({}));
        }
        //****** For Scroll New Masseges  ********//
        scrollMsg();

    }, [messageReceive]);

    useEffect(() => {
        const chatItems = document.getElementsByClassName('msg-box');
        if (chatItems.length > 0) {
            chatItems[chatItems.length - 1].scrollIntoView(true);
        }
        setIsScrolled(false);
        setIsScrollToDownEnabled(false);
        setChatDownBtnClicked(false);
    }, [conversation]);

    const scrollMsg = () => {
        const chatItems = document.getElementsByClassName('msg-box');
        if (isScrolled === false) {
            if (chatItems.length > 0) {
                chatItems[chatItems.length - 1].scrollIntoView(true);
            }
        } else {
            if (chatDownBtnClicked === false) {
                setIsScrollToDownEnabled(true)
            }
        }
    }

    const handleScroll = () => {
        setIsScrolled(true);
    }

    const handleMsgArrowClick = () => {
        setChatDownBtnClicked(true);
        const chatItems = document.getElementsByClassName('msg-box');
        if (chatItems.length > 0) {
            chatItems[chatItems.length - 1].scrollIntoView(true);
        }
        // setIsScrolled(false);
        setIsScrollToDownEnabled(false);
    }

    const handleClickSameNuberChat = () => {
        setDefaultChat(false);
        setSameNumberUserShow(true);
    }

    const decodeString = str => {
        var strr = str.replace(/\r\n/g, '<br/>')
        return '<span>' + strr + '</span>'
    };

    return (
        <>
            <ChatHead>
                <ChatHeadBox>
                    <DisplayFlex className={sameNumberUserShow ? "w-100" : null}>
                        <ChatBack>
                            <NavigateBeforeIcon onClick={handleBackClick} />
                        </ChatBack>
                        {conversation.isActive === false && sameNumberUserShow === false ?
                            <>
                                <ChatAvatarBox>
                                    <Avatar noMargin usernameProps={conversation.userName} className="table" width={21} height={21} />
                                </ChatAvatarBox>
                                <ChatTitle>
                                    {conversation.userName}
                                </ChatTitle>
                                {conversation.type === 1 || conversation.type === 3 ? <WhatsAppIcon sx={{ color: '#46C254', fontSize: '22px' }} /> : <img alt="sms" width='19' src='/images/SMSIcon.png' />}
                            </>
                            :
                            <>
                                {sameNumberUserShow ?
                                    <ChatTitle className='mr-0px ml-10px'>Personen mit der gleichen Nummer</ChatTitle>
                                    : null
                                }
                                {conversation.isActive || conversation.conversationId === 0 ?
                                    <>
                                        <ChatAvatarBox>
                                            <Avatar noMargin usernameProps={conversation.userName} className="table" width={21} height={21} />
                                        </ChatAvatarBox>
                                        <ChatTitle>
                                            {conversation.userName}
                                        </ChatTitle>
                                        <WarningIcon sx={{ color: '#ab9892', fontSize: '22px' }} />
                                    </>
                                    :
                                    null
                                }
                            </>
                        }
                    </DisplayFlex>
                    {sameNumberUserShow === false ?
                        <Box display="flex" justifyContent="center" alignItems="center">
                            <DropdownWrapper InnerText="Einverstandnis andem" bodyType={"ChatAction"} userId="" setConsentModal={setConsentModal} noarrow iconBg={false} icon={<MoreHorizIcon style={{ color: '#AB9892' }} />} />
                        </Box>
                        : null
                    }
                </ChatHeadBox>
            </ChatHead>
            {conversation.conversationType === 2 && sameNumberUserShow === false && conversation.isActive === false ?
                <>
                    <GroupHead>
                        <GroupHeadInner>
                            <DisplayFlex>
                                <Box>
                                    <Avatar noMargin usernameProps="Name" className="table" width={25} height={25} />
                                </Box>
                                <Box sx={{ width: '100%' }} display="flex" justifyContent="space-between" alignItems="center">
                                    <Box display="flex">
                                        <Typography varient='p' component='p' sx={{ fontSize: '16px', margin: 0 }}>Name,</Typography>
                                        <Box sx={{ marginLeft: '10px' }}>
                                            <Typography varient='p' component='p' sx={{ fontSize: '16px', margin: 0, color: '#ad9b95' }}>+1</Typography>
                                        </Box>
                                    </Box>
                                    <Box className='pointer' onClick={handleClickSameNuberChat}>
                                        <NavigateNextIcon sx={{ color: '#ad9b95' }} />
                                    </Box>
                                </Box>
                            </DisplayFlex>
                        </GroupHeadInner>
                    </GroupHead>
                </>
                :
                null
            }
            <SMSChatBody className={`scroll ${conversation.conversationType === 2 ? "groupChat-height" : ""} ${conversation.isActive ? "deactivate-chat" : ""}`}>
                <ChatBodyInner ref={myRef} onScroll={handleScroll} onWheel={handleScroll}>
                    {defaultChat ?
                        <>
                            {!!conversation.userChat && conversation.userChat.length > 0 ?
                                <div>
                                    {conversation.userChat?.map(x => {
                                        return x.chatType === 1 ?
                                            <SMSMsgBox className='msg-box'>
                                                <SMSMsg className={conversation.type !== 2 ? "whatsApp-msgBox" : null}>
                                                    <DisplayFlex>
                                                        <Box>
                                                            <Avatar noMargin src='' usernameProps={jwt_decode(cookies.authToken).UserName} className="table" width={25} height={25} />
                                                        </Box>
                                                        <Box>
                                                            <MsgTitle>{jwt_decode(cookies.authToken).UserName}</MsgTitle>
                                                        </Box>
                                                    </DisplayFlex>
                                                    <MsgText dangerouslySetInnerHTML={{ __html: decodeString(x.message) }}></MsgText>
                                                    <MsgTextDivider>---</MsgTextDivider>
                                                    <MsgTextFrom>{localStorage.getItem("practiceName")}</MsgTextFrom>
                                                    <MsgDetailBox>
                                                        <MsgDateTime>{moment(new Date(x.time)).format("DD.MM.YYYY, hh:mm")}</MsgDateTime>
                                                        <MsgReadIcon>
                                                            <DoneAllIcon sx={{ fontSize: '18px' }} />
                                                        </MsgReadIcon>
                                                    </MsgDetailBox>
                                                </SMSMsg>
                                            </SMSMsgBox>
                                            :
                                            <ReplyBox className='msg-box'>
                                                <Reply>
                                                    <Box>
                                                        <ReplyTitle dangerouslySetInnerHTML={{ __html: decodeString(x.message) }}></ReplyTitle>
                                                        <ReplyDetail sx={{ marginTop: '5px' }}>
                                                            <ReplyDateTime>{moment(new Date(x.time)).format("DD.MM.YYYY, hh:mm")}</ReplyDateTime>
                                                        </ReplyDetail>
                                                    </Box>
                                                </Reply>
                                            </ReplyBox>
                                    }
                                    )}
                                </div>
                                :
                                <>
                                    {conversation.isActive || conversation.conversationId === 0 ?
                                        <ChatContactBox>
                                            <img alt="Not Contact" src='/images/ChatEye.png' />
                                            <ChatContactTitle>
                                                Patient:in kann nicht kontaktiert werden
                                            </ChatContactTitle>
                                            <ChatContactText>
                                                Damit Abdo Alraei von dir im Chat kontaktiert werden kann, muss eine gültige Telefonnummer in deiner PVS hinterlegt sein.
                                            </ChatContactText>
                                        </ChatContactBox> : null}
                                </>
                            }
                        </>
                        : null
                    }
                    {sameNumberUserShow ?
                        <UserSameNumber />
                        : null
                    }
                </ChatBodyInner>
                {conversation.isActive === true ?
                    <DisableBox>
                        Entweder Sven Markus Gallenbach ist in deiner PVS deaktiviert worden oder mittlerweile Teil eines Gruppenchats und kann nur dort Nachrichten empfangen.
                    </DisableBox>
                    : null
                }
                {isScrollToDownEnabled ?
                    <MsgArrowBox onClick={handleMsgArrowClick}>
                        <MsgArrow>
                            <ArrowDownwardIcon fontSize="small" sx={{ color: '#fff' }} />
                        </MsgArrow>
                    </MsgArrowBox>
                    : null
                }
            </SMSChatBody>

            {conversation.isActive === false && sameNumberUserShow === false ?
                <>
                    <MsgBox>
                        <MsgInput value={msgText} onChange={(e) => setMsgText(e.target.value)} />
                    </MsgBox>
                    <MsgSubmitBox>
                        <Box>
                            <SubmitDivider>---</SubmitDivider>
                            <Box className="chat-footer" sx={{ display: 'flex', alignItems: 'Center' }}>
                                <DropdownWrapper templateList={templateList} setTextModuleModel={setTextModuleModel} InnerText="" bodyType={"ChatTemplates"} userId="" noarrow iconBg={false} icon={<PostAddIcon sx={{ "&:hover": { color: '#120ec5' } }} />} />
                                <SubmitText>{localStorage.getItem("practiceName")}</SubmitText>
                            </Box>
                        </Box>
                        <Box>
                            {!!msgText ? <SendIcon sx={{ rotate: '-45deg', color: '#120ec5' }} onClick={() => postMsg()} /> : <SendIcon sx={{ rotate: '-45deg', color: '#EFEAE6' }} />}
                        </Box>
                    </MsgSubmitBox>
                </>
                :
                null
            }

            {ConsentModal && <ChatConsent isShow={ConsentModal} hideConsentModal={setConsentModal} />}
            <DialogParent open={textModuleModel} fullWidth={true} maxWidth={900} widthClass="">
                <TextModulesModal templateList={templateList} handleClose={() => setTextModuleModel(false)} handleCreateTextBlockModal={() => setCreateTextBlockModel(true)} />
            </DialogParent>
            <DialogParent open={createTextBlockModel} fullWidth={true} maxWidth={900} widthClass="">
                <CreateTextBlockModal handleClose={() => setCreateTextBlockModel(false)} />
            </DialogParent>
        </>
    );
}

export default SingalChat;