import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import ModalLeftSidebar from './ModalLeftSide';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText';
import DoneIcon from '@mui/icons-material/Done';
import { CloseLeftCornor } from '../CloseIconWrap';
import { dynamicGetApi, dynamicPostApi } from './../../Api';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import WiedervorlageSetzenModal from './WiedervorlageSetzenModal';
import { setBehandlungShiftCheck, setRecall, setDelayPlan } from './../../Redux/Slice/commonSlice';
import NotesModal, { EditBtn, EditBtnWrapper, EditBtnPosition } from './NotesModal';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import CommentsModal from './CommentsModal';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkboxes from '../Inputs/Checkboxes';
import { CheckedIcon, UnCheckedIcon } from '../Inputs/Checkboxes';
import Checkbox from '@mui/material/Checkbox';
import BoltIcon from '@mui/icons-material/Bolt';

function BehandlngModal(props) {
    const [cookies, setCookie, removeCookie] = useCookies(['authToken']);
    let navigate = useNavigate();
    let dispatch = useDispatch();

    let delayPlan = useSelector(state => state.common.delayPlan);

    const { handleClose, planId } = props;

    const [modalData, setModalData] = useState({});
    const [wieder, setWieder] = useState(false);
    const [showNotesMoadel, setShowNotesMoadel] = useState(false);
    const [comment, setComment] = useState(false);
    const [terminationDoneCheck, setTerminationDoneCheck] = useState(false);

    useEffect(() => {
        if (!!planId) {
            getData(planId);
        }
    }, [planId]);

    const getData = async (planId) => {
        let url = "api/kanban/getKanbanItemById?itemId=" + planId;
        let res = await dynamicGetApi(url, navigate, setCookie, dispatch);
        setModalData(res.data);
        setTerminationDoneCheck(res.data.terminationDone);
    }

    const handleClick = () => {
        dispatch(setBehandlungShiftCheck(true));
    }

    const valueChange = async (call) => {
        let newData = {};
        if (call === "delayPlan") {
            newData = { ...modalData, weidervorlage: delayPlan, requiredAttentionDate: null };
        }
        else if ("terminationDone") {
            newData = { ...modalData, terminationDone: !terminationDoneCheck };
            setTerminationDoneCheck(!terminationDoneCheck);
        }
        let url = "api/kanban/updateKanbanItem";
        let res = await dynamicPostApi(newData, url, navigate, setCookie, dispatch);
        setModalData(newData);
        dispatch(setRecall(true));
        if (call === "delayPlan") {
            dispatch(setDelayPlan(2));
        }
    }

    return (
        <>
            <WiedervorlageSetzenModal valueChange={valueChange} setOpen={setWieder} open={wieder} />
            {comment && <CommentsModal setOpen={setComment} open={comment} planId={modalData.id} />}
            {/* {showNotesMoadel && <NotesModal planId={modalData.id} openNotesMoadel={showNotesMoadel} setOpenNotesModal={setShowNotesMoadel} />} */}
            <Grid container spacing={4} className="grid-main">
                <Grid item md={4} sm={12} xs={12} className="grid-4-col">
                    <div onClick={() => handleClose()}>
                        <CloseLeftCornor />
                    </div>
                    <ModalLeftSidebar modalData={modalData} dispatch={dispatch} />
                </Grid>
                <Grid item md={8} sm={12} xs={12} style={{ paddingRight: '20px' }} className='model-p-mob'>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="h4" component="div" className='title-model-right'>
                            Behandlung
                        </Typography>
                        <div className="EditBtnPlan">
                            <EditBtnWrapper>
                                <EditBtn onClick={() => setComment(true)}>
                                    <BorderColorIcon />
                                </EditBtn>
                            </EditBtnWrapper>
                        </div>
                    </Box>
                    <Typography variant="h6" className='desc-model-right' sx={{ mb: 4, mt: 4 }}>
                        Terminieren und behandeln {/* Einglederung */}
                    </Typography>
                    <List sx={{ bgcolor: 'background.paper' }}>
                        <ListItem sx={{pl:0}}>
                            <FormGroup>
                                <FormControlLabel sx={{ m:0 }} className='behandlung-check' control={<Checkboxes checked={terminationDoneCheck} onChange={() => valueChange("terminationDone")} sx={{ fontSize: 28, marginLeft: '0px' }} />}
                                    label="Behandlungstermine vereinbaren pat hat termin" />
                            </FormGroup>
                        </ListItem>
                        <ListItem sx={{pl:0}}>
                            <Box>
                                <FormGroup>
                                    <FormControlLabel sx={{ m:0 }} disabled control={<Checkbox style={{ fontSize: 28, padding: "5px 10px 5px 5px" }} checkedIcon={<CheckedIcon />} icon={<UnCheckedIcon><BoltIcon sx={{ fontSize: '22px', color: '#ffb800' }} className='thunder-check' /></UnCheckedIcon>} />}
                                        label="Eingliederungsdatum in Dampsoft erfassen" />
                                </FormGroup>
                            </Box>
                        </ListItem>
                        {/* <ListItem alignItems="flex-start" className='list-bundle flex-col-mob-center' onClick={() => handleClick()}>
                            <ListItemAvatar sx={{ mt: 0 }}>
                                <div>
                                    <Avatar>
                                        <DoneIcon className='cross-icon m-icon' />
                                    </Avatar>
                                </div>
                            </ListItemAvatar>
                            <ListItemText
                                className='list-bundle-text text-center-mob'
                                primary="Ja, die Eingliederung hat stattgefunden"
                            />
                        </ListItem>
                        <ListItem alignItems="flex-start" className='list-bundle flex-col-mob-center' onClick={() => setWieder(true)}>
                            <ListItemAvatar sx={{ mt: 0 }}>
                                <div>
                                    <Avatar>
                                        <CloseIcon className='cross-icon m-icon' />
                                    </Avatar>
                                </div>
                            </ListItemAvatar>
                            <ListItemText
                                className='list-bundle-text text-center-mob'
                                primary="Nein, die Eingliederung hat noch nicht stattgefunden"
                            />
                        </ListItem> */}
                    </List>
                    <Box className='d-flex flex-col-btn'>
                        <Button className='btn-light' onClick={() => setWieder(true)}>Plan in die Wiedervorlage</Button>
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}
export default BehandlngModal;