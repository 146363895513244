import { configureStore } from '@reduxjs/toolkit';
import userReducer from './Slice/userSlice';
import commonReducer from './Slice/commonSlice';
import chatReducer from './Slice/chatSlice';
import commentReducer from './Slice/commentSlice';

export const store = configureStore({
  reducer: {
    user: userReducer,
    common: commonReducer,
    chat: chatReducer,
    comment: commentReducer
  },
})