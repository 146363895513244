import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Grid, Typography } from '@mui/material';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Team from './Team';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Outlet, useNavigate } from 'react-router-dom';
import { NavLink, Link } from 'react-router-dom';
import { styled, alpha } from '@mui/material/styles';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
const theme = createTheme({
    components: { MuiMenuItem: { styleOverrides: { root: { '&:hover': { backgroundColor: '#f4f7ff' }, '&:active': { backgroundColor: "#f4f7ff" }, "&.Mui-selected,&.Mui-selected:hover": { backgroundColor: "#f4f7ff" } } } } }
})

function PracticeSetting() {
    let navigate = useNavigate();
    const [TeamSelected, setTeamSelected] = useState(true);
    const [ChatSelected, setChatSelected] = useState(false);
    const teamClick = () => {
        setTeamSelected(true);
        setChatSelected(false);
        navigate("/practiceSetting/team");
    }
    const chatClick = () => {
        setChatSelected(true);
        setTeamSelected(false);
        navigate("/practiceSetting/chatSetting");
    }
   
    return (
        <>
            <Box sx={{ flexGrow: 1 }} className='my-practiceSetting'>
                <Container>
                    <Typography variant='h4' component='h4' sx={{ fontWeight: '600', mb: 3, color: '#1a1a1a' }}>Praxis-Einstellungen</Typography>
                    <Card maxWidth="xs" sx={{ borderRadius: '20px', boxShadow: 'none' }}>
                        <CardContent sx={{ pt: 0 }} className="pb-0px">
                            <Grid container sx={{py: 5}}>
                                <Grid lg={2.5} md={3} sm={12} xs={12} sx={{ borderRight: '1px solid #ebebeb', pr: 2}}>
                                    <ThemeProvider theme={theme}>
                                        <MenuList className='list-praxis-mb'>
                                            <MenuItem selected={TeamSelected} onClick={teamClick} current={true} sx={{ py: '10px', borderRadius: '7px', "&:hover": { color: "#120EC5" } }} className='practice-menu'>
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                    <PersonOutlineIcon sx={{ mr: 1 }} />
                                                    <Typography variant='' component='p' sx={{ fontWeight: '400', m: 0, mt: '2px', color: '#1a1a1a' }}>Team</Typography>
                                                </Box>
                                            </MenuItem>
                                            <MenuItem selected={ChatSelected} onClick={chatClick} sx={{ py: '10px', borderRadius: '7px', mt: 1, "&:hover": { color: "#120EC5" } }} className='practice-menu'>
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                    <ManageAccountsIcon sx={{ mr: 1 }} />
                                                    <Typography variant='' component='p' sx={{ fontWeight: '400', m: 0, mt: '2px', color: '#1a1a1a' }}>Chat-Einstellungen</Typography>
                                                </Box>
                                            </MenuItem>
                                            
                                        </MenuList>
                                    </ThemeProvider>
                                </Grid>
                                <Grid lg={9.5} md={9} sm={12} xs={12}>
                                    <Outlet />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Container>
            </Box>
        </>
    );
}

export default PracticeSetting;