import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import PersonOutlineRoundedIcon from '@mui/icons-material/PersonOutlineRounded';
import BusinessIcon from '@mui/icons-material/Business';
import CloseIconWrap from '../CloseIconWrap';

const GingWrap = styled.div`
padding-left:4.4%;
padding-right:4.4%;
`
const Avatar = styled.div`
border-radius:13px;
background-color:#fbf6fa;
width:45px;
height:45px;
color:#120ec5;
`

function GingDerPlanModal(props) {

    const { gingder, closeGingder, setHandoverCheckbox, setMakeCall } = props;

    const mailedToCashier = () => {
        setHandoverCheckbox(true);
        closeGingder(false);
        setMakeCall(true);
    }

    const handOverToPatient = () => {
        setHandoverCheckbox(true);
        closeGingder(false);
        setMakeCall(true);
    }

    return (
        <>
            <Dialog
                fullWidth={true}
                maxWidth={'sm'}
                open={gingder}
                onClose={() => closeGingder(false)}
                className="antwort-width-600"
                aria-labelledby="responsive-dialog-title"
            >
                <div onClick={() => closeGingder(false)}>
                    <CloseIconWrap />
                </div>
                <GingWrap>
                    <DialogTitle id="responsive-dialog-title" align="center" sx={{ pb: 0 }} className="antwort-title">
                        {"An wen ging der Plan?"}
                    </DialogTitle>
                    <DialogContent>
                        <Typography variant="subtitle2" align="center" className='subtitle-modal'>
                            Wurde der Plan schon an die Kasse geschickt order liegt er noch immer bei Martina Zorn?
                        </Typography>
                        <DialogContentText sx={{ pt: 5 }}>
                            <List sx={{ bgcolor: 'background.paper' }}>
                                <ListItem alignItems="flex-start" className='list-bg flex-col-mob-center' onClick={() => mailedToCashier()} >
                                    <ListItemAvatar>
                                        <Avatar>
                                            <BusinessIcon className='cross-icon m-icon' />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <div className='text-center-mob'>
                                        <ListItemText
                                            className='badge-text-20'
                                            primary="Der Plan wurde per Post an Kasse geschickt"
                                        />
                                        <Typography
                                            sx={{ lineHeight: 0.6 }}
                                            component="span"
                                            color="text.secondary"
                                            className='fs-17'
                                        >
                                            HKP-Hero fragt Martina Zorn in 3 Wochen, ob die Genehemigung corliegt.
                                        </Typography>
                                    </div>
                                </ListItem>
                                <ListItem alignItems="flex-start" className='list-bg flex-col-mob-center' onClick={() => handOverToPatient()}>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <PersonOutlineRoundedIcon className='cross-icon m-icon' />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <div className='text-center-mob'>
                                        <ListItemText
                                            className='badge-text-20'
                                            primary="Der Plan wurde an Patient:in ubergeben"
                                        />
                                        <Typography
                                            sx={{ lineHeight: 0.6 }}
                                            component="span"
                                            color="text.secondary"
                                            className='fs-17'
                                        >
                                            HKP-Hero fragt Martina Zorn nachste Woche, ob es noch offene Fragen zum Plan gibt.
                                        </Typography>
                                    </div>
                                </ListItem>
                            </List>
                        </DialogContentText>
                    </DialogContent>
                </GingWrap>
            </Dialog>
        </>
    )
}
export default GingDerPlanModal;