import React from 'react';
import { styled } from "@mui/material/styles";
import { CardHover, Centeritems, EmptyCardWrapper } from './Dialogs/ModalLeftSide';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Typography from '@mui/material/Typography';
import Button from "@mui/material/Button";
import Popover from '@mui/material/Popover';
import { setPopOver, setPopOverPatientName } from '../Redux/Slice/commonSlice';
import { useSelector } from 'react-redux';

const TooltipButton = styled(Button)(() => ({
    color: "#dddcf7",
    backgroundColor: "#120ec5",
    textTransform: "none",
    borderRadius: "50px",
    padding: "6px 28px !important",
    width: "100%",
    marginTop: "15px",
    fontSize: "15px",
    "&:hover": {
        backgroundColor: "#090699",
        color: "#fff",
    },
    "& .MuiPopover-paper": {
        borderRadius: "30px",
    }
}));
function Tooltip(props) {
    const { tooltipPopover, setTooltipPopover, anchorEl, dispatch, setAnchorEl } = props;

    let popOverPatientName = useSelector(state => state.common.popOverPatientName);

    const open = Boolean(tooltipPopover);

    const closeTooltip = () => {
        debugger
        setTooltipPopover(false);
        setAnchorEl(null);
        dispatch(setPopOver(false));
        dispatch(setPopOverPatientName(''));
    }

    return (
        <>
            <div>
                <Popover
                    id="mouse-over-popover"
                    className='popover-left-sidebars top-10'
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center', }}
                    transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    onClose={() => setTooltipPopover(false)}
                    disableRestoreFocus
                >
                    <div style={CardHover}>
                        <EmptyCardWrapper>
                            <Centeritems>
                                <Typography sx={{ fontWeight: 600 }}>{popOverPatientName} ist jetzt hier</Typography>
                                <p>Der Plan von {popOverPatientName} istjetzt hierzu finden. Wenn er zeitkritisch wird,erinnert dich HKP-Hero an ihn und machtihn wieder sichtbar.</p>
                            </Centeritems>
                            <TooltipButton onClick={() =>closeTooltip()}> Okay, verstanden!</TooltipButton>
                        </EmptyCardWrapper>
                    </div>
                </Popover>
            </div>
        </>
    )
}

export default Tooltip;