import { React, useState } from 'react';
import styled from 'styled-components';
import { ChatHead, ChatHeadBox, DisplayFlex, ChatBack, ChatBodyInner, MsgTitle, MsgText, MsgTextDivider, MsgTextFrom, MsgDetailBox, MsgDateTime, MsgReadIcon, MsgBox, MsgInput, MsgSubmitBox, SubmitDivider, SubmitText } from './ChatWhatsApp';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import SendIcon from '@mui/icons-material/Send';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useDispatch } from 'react-redux';
import { setWelcome, setSameNumber, setChatList, setWhatsAppChat, setChatSMS, setchatNotContact } from '../../Redux/Slice/chatSlice';
import { DropdownWrapper } from '../HeaderDropdownWrapper';
import { Box } from '@mui/material';
import ChatConsent from '../Dialogs/ChatConsentModal';
import Avatar from '../Avatar';
import DoneAllIcon from '@mui/icons-material/DoneAll';

export const SMSChatBody = styled.div`
display: flex;
flex-direction: column;
justify-content: end;
height:410px;
overflow-y:auto;
margin-left:-24px;
margin-right:-24px;
`
export const SMSMsgBox = styled.div`
display:flex;
flex-direction: column;
justify-content: center;
align-items:end;
justify-content:end;
margin-top:15px;
`
export const SMSMsg = styled.div`
width:75%;
background-color:#BDBABD;
border-radius:12px;
padding:15px;
`
export const SMSMsgLowerText = styled.div`
margin-top:10px;
color:#BDBABD;
font-size:15px;
`
export const ChatTitle = styled.h3`
margin-right:5px;
margin-top:0px;
margin-bottom:0px;
font-weight:600;
font-size:18px;
`
export const ChatAvatarBox = styled.div`
margin-left:20px
`

function ChatSMS() {
    const [ConsentModal, setConsentModal] = useState(false);
    let dispatch = useDispatch();
    const handleBackClick = () => {
        dispatch(setWelcome(false))
        dispatch(setChatList(true))
        dispatch(setWhatsAppChat(false))
        dispatch(setSameNumber(false))
        dispatch(setChatSMS(false))
        dispatch(setchatNotContact(false))
    }
    return (
        <>
            <ChatHead>
                <ChatHeadBox>
                    <DisplayFlex>
                        <ChatBack>
                            <NavigateBeforeIcon onClick={handleBackClick} />
                        </ChatBack>
                        <ChatAvatarBox>
                            <Avatar noMargin usernameProps="Peter Beispiel" className="table" width={21} height={21} />
                        </ChatAvatarBox>
                        <ChatTitle>
                            Peter Beispiel
                        </ChatTitle>
                        <img alt="sms" width='19' src='/images/SMSIcon.png' />
                    </DisplayFlex>
                    <Box display="flex" justifyContent="center" alignItems="center">
                        <DropdownWrapper InnerText="Einverstandnis andem" bodyType={"ChatAction"} userId="" setConsentModal={setConsentModal} noarrow iconBg={false} icon={<MoreHorizIcon style={{ color: '#AB9892' }} />} />
                    </Box>
                </ChatHeadBox>
            </ChatHead>
            <SMSChatBody className='scroll'>
                <ChatBodyInner>
                    <div>
                        <SMSMsgBox>
                            <SMSMsg>
                                <DisplayFlex>
                                    <Box>
                                        <Avatar noMargin src='https://localhost:44314/AccountImages/dcf95503-2eea-4e9d-a2ba-9a00885d9ef5.jpeg' usernameProps="Ess Patient" className="table" width={25} height={25} />
                                    </Box>
                                    <Box>
                                        <MsgTitle>Ess Patient</MsgTitle>
                                    </Box>
                                </DisplayFlex>
                                <MsgText>HUhu</MsgText>
                                <MsgTextDivider>---</MsgTextDivider>
                                <MsgTextFrom>Gallenbach & Zahne Zahnarztpraxis</MsgTextFrom>
                                <MsgDetailBox>
                                    <MsgDateTime>11.12.22, 14:33</MsgDateTime>
                                    <MsgReadIcon>
                                        <DoneAllIcon sx={{ fontSize: '18px' }} />
                                    </MsgReadIcon>
                                </MsgDetailBox>
                            </SMSMsg>
                            <SMSMsgLowerText>Diese Nachricht kann nicht gesendet werden.</SMSMsgLowerText>
                        </SMSMsgBox>
                    </div>
                </ChatBodyInner>
            </SMSChatBody>
            <MsgBox>
                <MsgInput />
            </MsgBox>
            <MsgSubmitBox>
                <Box>
                    <SubmitDivider>---</SubmitDivider>
                    <SubmitText>Gallenbach & Zahne Zahnarztpraxis</SubmitText>
                </Box>
                <Box>
                    <SendIcon sx={{ rotate: '-45deg', color: '#EFEAE6' }} />
                </Box>
            </MsgSubmitBox>
            <ChatConsent isShow={ConsentModal} hideConsentModal={setConsentModal} />
        </>
    );
}

export default ChatSMS;