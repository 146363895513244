import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import Popover from '@mui/material/Popover';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import ChatSharpIcon from '@mui/icons-material/ChatSharp';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import ChatWelcome from './ChatWelcome';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import ChatWhatsApp from './ChatWhatsApp';
import UserSameNumber from './UserSameNumber';
import ChatSMS from './ChatSMS';
import ChatNotContact from './ChatNotContact';
import ChatDisable from './ChatDisable';
import SingalChat from './SingalChat';
import GroupChat from './GroupChat';
import { dynamicGetApi } from '../../Api';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';


export default function ChatPopover() {


    const whatsAppChat = useSelector((state) => state.chat.whatsAppChat)
    const sameNumber = useSelector((state) => state.chat.sameNumber)
    const chatSMS = useSelector((state) => state.chat.chatSMS)
    const chatNotContact = useSelector((state) => state.chat.chatNotContact)
    const chatDisable = useSelector((state) => state.chat.chatDisable)
    const openChat = useSelector(state => state.chat.openChat);
    const groupChat = useSelector(state => state.chat.groupChat);

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };



    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const theme = createTheme({
        components: {
            MuiPaper: {
                styleOverrides: {
                    root: {
                        borderRadius: '30px'
                    },
                },
            },
        },
    });




    return (
        <div className='chat-box'>
            <Box sx={{ m: 1, textAlign: 'right' }}>
                <Fab size="medium" className="btn-primary" aria-label="add" aria-describedby={id} onClick={handleClick}>
                    {!open ? <ChatSharpIcon /> : <CloseIcon />}
                </Fab>
            </Box>
            <ThemeProvider theme={theme}>
                <Popover
                    id={id}
                    PaperProps={{
                        sx: {
                            overflow: "unset"
                        }
                    }}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right', }}
                    transformOrigin={{ vertical: 'bottom', horizontal: 'right', }}
                    className='chat-popup-mb'
                >
                    <Box sx={{ pt: 0, px: 3, pb: 3, width: 350, height: 540 }} className="chat-box-mb">

                        <ChatWelcome />

                        {groupChat ? <GroupChat /> : null}

                        {openChat ? <SingalChat /> : null}

                        {/* {sameNumber ? <UserSameNumber /> : null} */}

                        {chatNotContact ? <ChatNotContact /> : null}

                        {/* {whatsAppChat ?
                            <ChatWhatsApp />
                            : null
                        } */}
                        {/* {chatSMS ?
                            <ChatSMS />
                            : null
                        } */}
                        {/* {chatDisable ?
                            <ChatDisable />
                            : null
                        } */}

                    </Box>
                </Popover>
            </ThemeProvider>

        </div>
    );
}