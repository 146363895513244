import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import ModalLeftSide from './ModalLeftSide';
import ModalRightSide from './ModalRightSide';
import { CloseLeftCornor } from '../CloseIconWrap';
import { dynamicGetApi } from './../../Api';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';


function PlanningModal(props) {

    const [cookies, setCookie, removeCookie] = useCookies(['authToken']);
    let navigate = useNavigate();
    let dispatch = useDispatch();

    const { handleClose, planId } = props;

    const [modalData, setModalData] = useState({});

    useEffect(() => {
        if (!!planId) {
            getData(planId);
        }
    }, [planId]);

    const getData = async (planId) => {
        let url = "api/kanban/getKanbanItemById?itemId=" + planId;
        let res = await dynamicGetApi(url, navigate, setCookie,dispatch);
        setModalData(res.data);
    }

    return (
        <>
            <div>
                <Grid container spacing={4} className="grid-main">
                    <Grid item md={4} sm={12} xs={12} className="grid-4-col">
                        <ModalLeftSide modalData={modalData} dispatch={dispatch} />
                    </Grid>
                    <Grid item md={8} sm={12} xs={12} className='model-p-mob'>
                        <div onClick={handleClose}>
                            <CloseLeftCornor />
                        </div>
                        <ModalRightSide modalData={modalData} setModalData={setModalData} />
                    </Grid>
                </Grid>
            </div>
        </>
    )
}
export default PlanningModal;