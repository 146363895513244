import React, { useState, useEffect } from 'react';
import KanbanCard from '../Components/KanbanCard';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import styled from 'styled-components';
import PlanningModal from '../Components/Dialogs/PlanningModal';
import VersionSelectorModal from '../Components/Dialogs/VersionSelectorModal';
import ZustimmungModal from '../Components/Dialogs/ZustimmungModal';
import BehandlngModal from '../Components/Dialogs/BehandlngModal';
import AbrechnungModal from '../Components/Dialogs/AbrechnungModal';
import PlanungPopover from '../Components/CardHeadingHovers/PlanungPopover';
import ZustimmungPopover from '../Components/CardHeadingHovers/ZustimmungPopover';
import BehandlungPopover from '../Components/CardHeadingHovers/BehandlungPopover';
import AbrechnungPopover from '../Components/CardHeadingHovers/AbrechnungPopover';
import EmptyCard from '../Components/EmptyCard';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { dynamicGetApi } from './../Api';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { setRecall, setApplyFilter, setSortFilter, setDisableButton, setIsPrivatePlan, setPlanStatusChanged } from './../Redux/Slice/commonSlice';
import moment from 'moment';
import DialogParent from './../Components/Dialogs/DialogParent';
import Tooltip from '../Components/Tooltip';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import jwt_decode from "jwt-decode";


const HomepageWrapper = styled.div`
padding:20px;
margin-top: 7%;
`
const ColumnWrapper = styled.div`
margin:10px 0px;

`
const ColumnTitle = styled.div`
 display:flex;
 align-items:center;
 justify-content:space-between;
 padding:15px 5px 25px 5px;

 & .fw-bold{
    font-weight:600;
 }
 & span:not(.fw-bold){
    color:#ab9892
 }
 & .priceSpan{
    font-size:13px;
 }
`
const Notification = styled.div`
background-color:#efeae6 !important;
padding:5px;
border-radius: 25px;
color:#ab9892;
margin-right:5px;
margin-left:5px;
font-size: 14px;
width: 20px;
height: 14px;
display: flex;
justify-content: center;
    align-items: center;
`
const DropdownWrapperDiv = styled.div`
display:flex;
align-items:center;
justify-content:space-evenly;
cursor:pointer;
margin-top:20px;
margin-bottom:10px;
& .title{
    color:#ab9892;
}
& .dropdown{
    color:#ab9892;
    font-size:28px;

}
`
const ScrollAbleWrapper = styled.div`
background-color:${props => !!props.parentDiv ? `transparent` : `#efeae6 !important`};
padding:${props => !!props.parentDiv ? `0px` : `8px !important`};
border-radius:10px;
& .mb-1{
    margin-bottom:7px;
}
&::-webkit-scrollbar {
    width: 2px;
  }
  
&::-webkit-scrollbar-track {
    background: #f8f5f4; 
  }
   
&::-webkit-scrollbar-thumb {
    background: #f8f5f4; 
    border-radius:10px;
  }
&::-webkit-scrollbar-thumb:hover {
    background: #efeae6; 
  }
`

function HomePage() {
    const [cookies, setCookie, removeCookie] = useCookies(['authToken', 'selectedVersion']);
    let navigate = useNavigate();
    let dispatch = useDispatch();

    let applyFilter = useSelector(state => state.common.applyFilter);
    let planFilter = useSelector(state => state.common.planFilter);
    let practitionerFilter = useSelector(state => state.common.practitionerFilter);
    let valueFilter = useSelector(state => state.common.valueFilter);
    let timeFilter = useSelector(state => state.common.timeFilter);
    let sortFilter = useSelector(state => state.common.sortFilter);
    let recall = useSelector(state => state.common.recall);
    let planStatusChanged = useSelector(state => state.common.planStatusChanged);
    let popOver = useSelector(state => state.common.popOver);
    let isPrivatePlan = useSelector(state => state.common.isPrivatePlan);
    let searchValue = useSelector(state => state.common.searchValue);

    const [open, setOpen] = useState(false);
    const [zustim, setZustim] = useState(false);
    const [behand, setBehand] = useState(false);
    const [abrechnung, setAbrechnung] = useState(false);
    const [planungPopover, setPlanungPopover] = useState(false);
    const [zustimmungPopover, setZustimmungPopover] = useState(false);
    const [behandlungPopover, setBehandlungPopover] = useState(false);
    const [abrechnungPopover, setAbrechnungPopover] = useState(false);
    const [scrollableListData, setScrollableListData] = useState([]);
    const [data, setData] = useState([]);
    const [dataFilter, setdataFilter] = useState();
    const [planIdForPlanModal, setPlanIdForPlanModal] = useState("");
    const [openModalStage, setOpenModalStage] = useState("");
    const [tooltipPopover, setTooltipPopover] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [versionModal, setVersionModal] = useState(false);
    const [records, setRecords] = useState([]);
    const [page, setPage] = useState(1);

    const ZustimmungRef = React.useRef();
    const BehandlungRef = React.useRef();

    const openPopover = (event, name) => {
        if (name === "Planung") {
            setPlanungPopover(event.currentTarget);
        }
        if (name === "Zustimmung") {
            setZustimmungPopover(event.currentTarget);
        }
        if (name === "Behandlung") {
            setBehandlungPopover(event.currentTarget);
        }
        if (name === "Abrechnung") {
            setAbrechnungPopover(event.currentTarget);
        }
    }

    const closePopover = (name) => {
        if (name === "Planung") {
            setPlanungPopover(false);
        }
        if (name === "Zustimmung") {
            setZustimmungPopover(false);
        }
        if (name === "Behandlung") {
            setBehandlungPopover(false);
        }
        if (name === "Abrechnung") {
            setAbrechnungPopover(false);
        }
    }

    useEffect(() => {
        getPageData();
        document.body.classList.add("removescrollbody");
        return () => {
            document.body.classList.remove("removescrollbody");
        }
    }, []);

    useEffect(() => {
        if (recall) {
            getPageData();
            dispatch(setRecall(false));
        }
    }, [recall]);

    useEffect(() => {
        const getData = setTimeout(() => {
            if (!!searchValue) {
                searchFun(searchValue);
            }
        }, 1000);
        if (!!dataFilter) {
            setData(dataFilter);
        }
        return () => clearTimeout(getData);
    }, [searchValue]);

    useEffect(() => {
        if (applyFilter) {
            if (!!planFilter || !!practitionerFilter || !!valueFilter || !!timeFilter) {
                collectiveFilter(planFilter || [], practitionerFilter, valueFilter, timeFilter)
            }
            if (!!sortFilter) {
                SortedFilter(sortFilter)
            }
            dispatch(setApplyFilter(false));
        }
    }, [applyFilter]);

    useEffect(() => {
        if (popOver) {
            setAnchorEl(planStatusChanged.currentStage == "1" ? ZustimmungRef.current : planStatusChanged.currentStage == "2" ? BehandlungRef.current : null);
            setTooltipPopover(true);
        }
    }, [popOver]);

    useEffect(() => {
        if (!!cookies.authToken && cookies.selectedVersion === undefined && jwt_decode(cookies.authToken).Version === "") {
            setVersionModal(true);
            setOpenModalStage("Version");
        } else if (!!cookies.selectedVersion) {
            setCookie("selectedVersion", cookies.selectedVersion);
        }
        else {
            setCookie("selectedVersion", jwt_decode(cookies.authToken).Version === "1" ? "old" : "new");
        }
    }, [cookies.authToken]);

    const collectiveFilter = (planfilterID, practitionerID, value, date) => {
        let val1 = value[0];
        let val2 = value[1];
        let filter = dataFilter;
        let filtered = [];
        let prevDate = GetPrevDates(date);

        filter?.map(x => {
            filtered.push({
                ...x,
                notification: CountNotification(x?.children.filter(x =>
                    (!!planfilterID.length > 0 ? planfilterID?.some((y) => y === x?.planName) : true)
                    && (val1 >= 0 && !!val2 ? (x.planAmount >= val1 && x.planAmount <= val2) : true)
                    && (!!practitionerID ? x.practitionerId === parseInt(practitionerID) : true)
                    && (!!date && date !== "Alle" ? moment(x.createdAt).isSameOrAfter(prevDate) : true)
                    && (!!date && date === "Alle" ? moment(x.createdAt).isSameOrBefore(prevDate) : true)
                )),
                value: CalcAmount(x?.children.filter(x =>
                    (!!planfilterID.length > 0 ? planfilterID?.some((y) => y === x?.planName) : true)
                    && (val1 >= 0 && !!val2 ? (x.planAmount >= val1 && x.planAmount <= val2) : true)
                    && (!!practitionerID ? x.practitionerId === parseInt(practitionerID) : true)
                    && (!!date && date !== "Alle" ? moment(x.createdAt).isSameOrAfter(prevDate) : true)
                    && (!!date && date === "Alle" ? moment(x.createdAt).isSameOrBefore(prevDate) : true)
                )),
                children: x?.children.filter(x =>
                    (!!planfilterID.length > 0 ? planfilterID?.some((y) => y === x?.planName) : true)
                    && (val1 >= 0 && !!val2 ? (x.planAmount >= val1 && x.planAmount <= val2) : true)
                    && (!!practitionerID ? x.practitionerId === parseInt(practitionerID) : true)
                    && (!!date && date !== "Alle" ? moment(x.createdAt).isSameOrAfter(prevDate) : true)
                    && (!!date && date === "Alle" ? moment(x.createdAt).isSameOrBefore(prevDate) : true)
                ),
            });
        });
        setData(filtered);
        setScrollableListData(filtered);
    }

    const searchFun = (value) => {
        let val = value.toLowerCase();
        let filter = dataFilter;
        let filtered = [];

        filter?.map(x => {
            filtered.push({
                ...x,
                notification: CountNotification(x?.children.filter(x =>
                    x.patientName.toLowerCase().includes(val) || x.planName.toLowerCase().includes(val) || x.patientId.toString().includes(val) || x.id.toString().includes(val)
                    || (x.planAmount).toLocaleString('de', { style: 'currency', currency: 'Eur' }).includes(val)
                    || moment(new Date(x.createdAt)).format("MMMM YYYY").toLowerCase().includes(val)
                )),
                value: CalcAmount(x?.children.filter(x =>
                    x.patientName.toLowerCase().includes(val) || x.planName.toLowerCase().includes(val) || x.patientId.toString().includes(val) || x.id.toString().includes(val)
                    || (x.planAmount).toLocaleString('de', { style: 'currency', currency: 'Eur' }).includes(val)
                    || moment(new Date(x.createdAt)).format("MMMM YYYY").toLowerCase().includes(val)
                )),
                children: x?.children.filter(x =>
                    x.patientName.toLowerCase().includes(val) || x.planName.toLowerCase().includes(val) || x.patientId.toString().includes(val) || x.id.toString().includes(val)
                    || (x.planAmount).toLocaleString('de', { style: 'currency', currency: 'Eur' }).includes(val)
                    || moment(new Date(x.createdAt)).format("MMMM YYYY").toLowerCase().includes(val)
                ),
            });
        });
        setData(filtered);
        setScrollableListData(filtered);
    }

    const SortedFilter = (sortFilter) => {
        let filter = data;
        let filtered = [];
        if (sortFilter == "Nach Planerstellung") {
            filter?.map(x => {
                filtered.push({
                    ...x,
                    children: x?.children.sort((a, b) => moment(a.createdAt).format('YYYYMMDD') - moment(b.createdAt).format('YYYYMMDD')),
                });
            });
            setData(filtered);
        } else if (sortFilter == "Nach Planwert") {

            filter?.map(x => {
                filtered.push({
                    ...x,
                    children: x?.children.sort((a, b) => b.planAmount - a.planAmount),
                });
            });
            setData(filtered);
            setScrollableListData(filtered);

        } else {
            //surname sort
            filter?.map(x => {
                filtered.push({
                    ...x,
                    children: x?.children.sort((a, b) => {
                        if (a.patientName.split(' ')[1] < b.patientName.split(' ')[1]) {
                            return -1;
                        }
                        if (a.patientName.split(' ')[1] > b.patientName.split(' ')[1]) {
                            return 1;
                        }
                        return 0;
                    })

                });
            });
            setData(filtered);
            setScrollableListData(filtered);
        }
    }

    const GetPrevDates = (value) => {
        return new Date(moment().subtract(value, 'months'))
    }

    const CountNotification = (list1) => {
        return `${list1.length}`;
    }

    const CalcAmount = (list1) => {
        let amount = 0;
        list1.length > 0 && list1.map(x => amount = amount + x.planAmount);
        return `${amount} €`;
    }

    const getPageData = async () => {
        let url = "api/kanban/getAllItems";
        let res = await dynamicGetApi(url, navigate, setCookie, dispatch);
        let emList = [];
        res?.data?.map(x => emList.push(x));
        setData(emList);
        setdataFilter(emList);
        setScrollableListData(emList);
        if (Object.keys(planStatusChanged).length > 0) {
            setPlanIdForPlanModal(planStatusChanged.kanbanId);
            if (planStatusChanged.nextStage === 'Zustimmung') {
                setOpen(false);
                if (isPrivatePlan !== true) {
                    setZustim(true);
                    setOpenModalStage("Zustimmung");
                }
            }
            if (planStatusChanged.nextStage === 'Behandlung') {
                setZustim(false);
                if (isPrivatePlan !== true) {
                    setBehand(true);
                    setOpenModalStage("Behandlung");
                }
            }
            if (planStatusChanged.nextStage === 'Abrechnung') {
                setBehand(false);
                setAbrechnung(true);
                setOpenModalStage("Abrechnung");
            }
            dispatch(setPlanStatusChanged({}));
            dispatch(setIsPrivatePlan(false));
        }
    }

    const toggleDropdown = (index) => {
        let prevState = [...scrollableListData];
        prevState[index].isOpen = !prevState[index].isOpen;
        setScrollableListData(prevState)
    }

    const openModal = (data) => {
        setPlanIdForPlanModal(data.id);
        if (data.stage === "1") {
            setOpen(true);
            setOpenModalStage("Planung");
        }
        else if (data.stage === "2") {
            setZustim(true);
            setOpenModalStage("Zustimmung");
        }
        else if (data.stage === "3") {
            setBehand(true);
            setOpenModalStage("Behandlung");
        }
        else if (data.stage === "4") {
            setAbrechnung(true);
            setOpenModalStage("Abrechnung");
        }
    }

    const closeModal = () => {
        if (openModalStage === "Planung") {
            setOpen(false);
        }
        else if (openModalStage === "Zustimmung") {
            setZustim(false);
        }
        else if (openModalStage === "Behandlung") {
            setBehand(false);
        }
        else if (openModalStage === "Abrechnung") {
            setAbrechnung(false);
        }
        else if (openModalStage === "Version") {
            setVersionModal(false);
        }
        setPlanIdForPlanModal("");
        setOpenModalStage("");
        dispatch(setDisableButton(true));
        dispatch(setPlanStatusChanged({}));
    }



    return (
        <HomepageWrapper className='mt-mob-home'>

            <DialogParent open={open} fullWidth={true} maxWidth={900}>
                <PlanningModal handleClose={closeModal} planId={planIdForPlanModal} />
            </DialogParent>
            <DialogParent open={versionModal} >
                <VersionSelectorModal handleClose={closeModal} />
            </DialogParent>
            <DialogParent open={zustim} fullWidth={true} maxWidth={"sm"}>
                <ZustimmungModal handleClose={closeModal} planId={planIdForPlanModal} />
            </DialogParent>
            <DialogParent open={behand} fullWidth={true} maxWidth={"sm"}>
                <BehandlngModal handleClose={closeModal} planId={planIdForPlanModal} />
            </DialogParent>
            <DialogParent open={abrechnung} fullWidth={true} maxWidth={"sm"}>
                <AbrechnungModal handleClose={closeModal} planId={planIdForPlanModal} />
            </DialogParent>

            <PlanungPopover closePlanungPopover={closePopover} planungPopover={planungPopover} />
            <ZustimmungPopover closeZustimmungPopover={closePopover} zustimmungPopover={zustimmungPopover} />
            <BehandlungPopover closeBehandlungPopover={closePopover} behandlungPopover={behandlungPopover} />
            <AbrechnungPopover closeAbrechnungPopover={closePopover} abrechnungPopover={abrechnungPopover} />


            <Tooltip tooltipPopover={tooltipPopover} setTooltipPopover={setTooltipPopover} anchorEl={anchorEl} dispatch={dispatch} setAnchorEl={setAnchorEl} />

            <Box sx={{ flexGrow: 1 }}>
                <Container maxWidth="xl" className="home-content-container">
                    <Grid container columnSpacing={{ md: 8, sm: 4 }} className='custon-container h-100'>
                        {data.map((x, i) =>
                            <Grid item md={3} sm={6} xs={12} className={`first-${x?.title} h-100`}>
                                <ColumnWrapper className='h-100'>
                                    <ColumnTitle>
                                        <>
                                            {x.title === "Planung" ? (
                                                <span className='fw-bold' aria-owns={planungPopover ? 'mouse-over-popover' : null}
                                                    aria-haspopup="true"
                                                    onMouseEnter={(e) => openPopover(e, "Planung")}
                                                    onMouseLeave={() => closePopover("Planung")}>{x?.title}</span>
                                            ) : null}
                                            {x.title === "Zustimmung" ? (
                                                <span className='fw-bold' aria-owns={zustimmungPopover ? 'mouse-over-popover' : null}
                                                    aria-haspopup="true"
                                                    onMouseEnter={(e) => openPopover(e, "Zustimmung")}
                                                    onMouseLeave={() => closePopover("Zustimmung")}>{x?.title}</span>
                                            ) : null}
                                            {x.title === "Behandlung" ? (
                                                <span className='fw-bold' aria-owns={behandlungPopover ? 'mouse-over-popover' : null}
                                                    aria-haspopup="true"
                                                    onMouseEnter={(e) => openPopover(e, "Behandlung")}
                                                    onMouseLeave={() => closePopover("Behandlung")}>{x?.title}</span>
                                            ) : null}
                                            {x.title === "Abrechnung" ? (
                                                <span className='fw-bold' aria-owns={abrechnungPopover ? 'mouse-over-popover' : null}
                                                    aria-haspopup="true"
                                                    onMouseEnter={(e) => openPopover(e, "Abrechnung")}
                                                    onMouseLeave={() => closePopover("Abrechnung")}>{x?.title}</span>
                                            ) : null}
                                        </>
                                        <div className='d-flex align-items-center'>
                                            <span className='priceSpan'>{(x?.value).toLocaleString('de', { style: 'currency', currency: 'Eur' })}</span>
                                            <Notification>
                                                {x?.notification}
                                            </Notification>
                                        </div>
                                    </ColumnTitle>
                                    <div className='test'>
                                        {x.title === "Planung" || x.title === "Abrechnung" ?
                                            x?.children.length > 0 ?
                                                <ScrollAbleWrapper>
                                                    {x?.children.map((x, i) =>
                                                        <div key={"child_" + i} className='mb-3' onClick={() => openModal(x)}>
                                                            <KanbanCard cardData={x} />
                                                        </div>)}
                                                </ScrollAbleWrapper>

                                                : <div className='mb-3'>
                                                    <EmptyCard />
                                                </div>
                                            : null
                                        }

                                        {x.title === "Zustimmung" || x.title === "Behandlung" ? <ScrollAbleWrapper parentDiv>
                                            {x.title === "Zustimmung" || x.title === "Behandlung" ?
                                                x?.children.filter(x => x.requiredAttentionDate !== null).length > 0 ?
                                                    <ScrollAbleWrapper>
                                                        {x?.children.map((x, i) =>
                                                            x.requiredAttentionDate !== null && <div key={"child_" + i} className='mb-3' onClick={() => openModal(x)}>
                                                                <KanbanCard cardData={x} />
                                                            </div>)}
                                                    </ScrollAbleWrapper>

                                                    : <div className='mb-3'>
                                                        <EmptyCard />
                                                    </div>
                                                : null
                                            }

                                            {x.widthDropdown === true &&
                                                <>
                                                    <DropdownWrapperDiv ref={i === 1 ? ZustimmungRef : i === 2 ? BehandlungRef : null} onClick={() => toggleDropdown(i)}>
                                                        <div className='title'>
                                                            Wiedervorlage
                                                        </div>
                                                        <div className='d-flex align-items-center'>
                                                            <Notification>
                                                                {x?.children?.filter(x => x.requiredAttentionDate === null).length}
                                                            </Notification>
                                                            {x?.isOpen === true ? <ExpandLessIcon className='dropdown' color='inherit' fontSize='inherit' /> : <ExpandMoreIcon className='dropdown' color='inherit' fontSize='inherit' />}

                                                        </div>
                                                    </DropdownWrapperDiv>
                                                    {x?.isOpen === true ?
                                                        <ScrollAbleWrapper>
                                                            {x?.children.map((x, i) =>
                                                                x.requiredAttentionDate === null && <div key={"child1_" + i} className='mb-1' onClick={() => openModal(x)}>
                                                                    <KanbanCard cardData={x} />
                                                                </div>
                                                            )}
                                                        </ScrollAbleWrapper>
                                                        : null}
                                                </>
                                            }
                                        </ScrollAbleWrapper> : null}
                                    </div>
                                </ColumnWrapper>
                            </Grid>
                        )}
                    </Grid>
                </Container>
            </Box>
        </HomepageWrapper>
    );
}

export default HomePage;