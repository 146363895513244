import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import jwt_decode from "jwt-decode";
import { useCookies } from 'react-cookie';

const IconTextFirst = styled.div`
border-radius:50%;
background-color:#0f5481;
width:35px;
height:35px;
color:#fff;
display: flex;
align-items: center;
justify-content: center;
margin-right: 10px;
`

function BadgeLabelComment(props) {
   const [cookies, setCookie, removeCookie] = useCookies(['authToken']);
   const { letter } = props;
   const lettersConvert = (text) => {
      var myStr = text;
      var matches = myStr.match(/\b(\w)/g);
      return matches.join('');
   }
   const generateRandomColor = () => {
     return Math.floor(Math.random()*16777215).toString(16);

   }
   return (
      <>
         <div>
            <IconTextFirst bgColor={generateRandomColor()}>
               <Typography variant='p' component='div' sx={{ fontWeight: '600', color: '#fff', textTransform: 'uppercase' }}>{!!letter ? lettersConvert(letter) : lettersConvert(jwt_decode(cookies.authToken).UserName)}</Typography>
            </IconTextFirst>
         </div>
      </>
   )
}
export default BadgeLabelComment;
