import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Button, Typography } from '@mui/material';
import CreateIcon from '@mui/icons-material/Create';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { Emoji, EmojiStyle } from "emoji-picker-react";
import { AccordionButton } from './Dialogs/TextModulesModal';
import { setSelectedTemplateId } from './../Redux/Slice/chatSlice';
import { useDispatch } from 'react-redux';
import { dynamicGetApi } from '../Api';
import { useNavigate } from 'react-router-dom';
import { Cookie } from '@mui/icons-material';
import { useCookies } from 'react-cookie';
import { setRecall } from './../Redux/Slice/commonSlice';




const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ExpandMoreIcon />}
    {...props}
  />
))(({ theme }) => ({
  // backgroundColor:
  //   theme.palette.mode === 'dark'
  //     ? 'rgba(255, 255, 255, .05)'
  //     : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  // borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function CustomizedAccordions(props) {
  const [cookie, setCookie, removeCookie] = useCookies();
  let dispatch = useDispatch();
  let navigate = useNavigate();

  const { accordionList, handleCreateTextBlockModal } = props;

  const [expanded, setExpanded] = useState('panel-0');
  const [accordionClick, setaccordionClick] = useState(false);

  const handleClick = (event, panel) => {
    setExpanded(event ? panel : false);
    if (expanded === panel) {
      setExpanded(false);
    }
  }

  const handleAccordionClick = (event) => {
    event.stopPropagation()
  }

  const deleteConversationTemplate = async (id) => {
    let url = 'api/conversationTemplates/deleteConversationTemplate?id=' + id;
    let res = await dynamicGetApi(url, navigate, setCookie, dispatch);
    if (res.data) {
      dispatch(setRecall(true));
    }
  }

  return (
    <div>
      {accordionList.map((item, index) => (
        <Accordion className='custom-Accordian' expanded={expanded === `panel-${index}`} onClick={(event) => handleClick(event, `panel-${index}`)}>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Box sx={{ width: '65%', flexShrink: 0, display: 'flex', alignItems: 'center' }}>
              <Emoji unified={item.emoji} emojiStyle={EmojiStyle.APPLE} size={25} />
              <Typography sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>{item.title}</Typography>
            </Box>
            <Box sx={{ width: '35%', flexShrink: 0 }} display="flex" justifyContent={expanded === `panel-${index}` ? "end" : "end"} alignItems="center">
              <Box className='accordion-icons-box' onClick={handleAccordionClick}>
                {expanded === `panel-${index}` ?
                  <>
                    <CreateIcon sx={{ fontSize: '20px !important', color: '#ab9892' }} onClick={() => { dispatch(setSelectedTemplateId(item.id)); handleCreateTextBlockModal(true); }} />
                    <DeleteOutlinedIcon sx={{ fontSize: '20px !important', color: '#ab9892' }} onClick={() => deleteConversationTemplate(item.id)} />
                  </>
                  :
                  null}
              </Box>
              <Box sx={{ ml: 1 }}>
                <AccordionButton onClick={handleAccordionClick}>Automatische Nachricht</AccordionButton>
              </Box>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
          <Typography>
              {item.salutation}
            </Typography>
            <br/>
            <Typography>
              {item.contents}
            </Typography>
            <br/><br/>
            <Typography>
              {item.practiceName}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}

    </div>
  );
}