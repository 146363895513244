import Container from '@mui/material/Container';
import React, { useState } from 'react';
import styled from 'styled-components';
import BusinessIcon from '@mui/icons-material/Business';
import NoteOutlinedIcon from '@mui/icons-material/NoteOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import EuroSymbolOutlinedIcon from '@mui/icons-material/EuroSymbolOutlined';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import NoteIcon from '@mui/icons-material/Note';
import SearchBar from '../Components/SearchBar';
import { DropdownWrapper } from '../Components/HeaderDropdownWrapper';
import { useNavigate, useLocation } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';


const HeaderWrapper = styled.div`
background-color:#f8f6f5 !important; 
box-shadow: 0 2px 5px #c9c9c9;
padding:5px 0;
top: 0;
left: 0;
right: 0;
position: fixed;
`

export const FlexBetween = styled.div`
display:flex;
justify-content: space-between;
align-items : center
`

const AddSpace = styled.div`
margin: 15px 0;
`
export const PlaneButton = styled.div`
background:#efeae6;
padding:3px 5px;
border-radius: 10px;
display:flex;
align-items:center;
color:#120ec5;
height: 26px;
cursor: pointer;
`

const PlaneButtonDisable = styled.div`
background:#dddddd;
padding:3px 5px;
border-radius: 10px;
display:flex;
align-items:center;
color:#fff;
height: 26px;
cursor: pointer;
`

const HandEmoji = styled.span`
font-size:16px;
`
function Header() {
    let location = useLocation();
    const [isHeaderHide, setIstHeaderHide] = useState(true);

    return (
        <div className='mobile-header-wrapper'>
            <div className='mobile-menu-icon'>
                {isHeaderHide ?
                    <MenuIcon className='mob-icon' onClick={() => setIstHeaderHide(false)} sx={{ color: '#AB9892', fontSize: '40px' }} />
                    :
                    <CloseIcon className='mob-icon' onClick={() => setIstHeaderHide(true)} sx={{ color: '#AB9892', fontSize: '40px' }} />
                }
            </div>
            <HeaderWrapper className={`${isHeaderHide ? "Header" : "Header d-block"}`} >
                <Container maxWidth="xxl">
                    <FlexBetween className='desktop-header'>
                        <div className='w-100'>
                            <GallenbachTitle />
                        </div>
                        <PlanIcon />
                        <div className='w-100 toRight d-flex align-items-center justify-content-end rightHeaderButtons'>
                            <FeedBackText />
                            <LogoutText />
                        </div>
                    </FlexBetween>
                    <div className='d-flex align-items-center justify-content-between mobile-header'>
                        <div>
                            <GallenbachTitle />
                            <PlanIcon />
                        </div>
                        <div>
                            <LogoutText />
                            <FeedBackText />
                        </div>
                    </div>
                    <AddSpace />
                    {location.pathname.toLowerCase().indexOf("practicesetting") === -1 ?
                        <FlexBetween className='search-block'>
                            <div className='d-flex align-items-center flex-1 bottom-header-mob'>
                                <DropdownWrapper bodyType={"Planarten"} iconBg={false} icon={<NoteOutlinedIcon style={{ transform: 'rotate(90deg)  scaleX(-1)' }} fontSize='inherit' />} title={"Planarten"} />
                                <DropdownWrapper bodyType={"KollegInnen"} iconBg={false} icon={<PersonOutlineOutlinedIcon fontSize='inherit' />} title={"Kolleg:innen"} />
                                <DropdownWrapper bodyType={"Planwert"} iconBg={false} icon={<EuroSymbolOutlinedIcon fontSize='inherit' />} title={"0-19.100 €"} />
                                <DropdownWrapper bodyType={"Alle"} iconBg={false} icon={<CalendarMonthIcon fontSize='inherit' />} title={"Alle"} />
                                <DropdownWrapper bodyType={"Sortierung"} iconBg={false} icon={<CompareArrowsIcon style={{ transform: 'rotate(-270deg)' }} fontSize='inherit' />} title={"Sortiert nach Planerstellung"} />
                            </div>
                            <div className='d-flex align-items-center flex-end search-block-p'>
                                <SearchBar placeholder={"Patienten,Plan ID, ..."} bgColor={"#efeae6"} />
                            </div>
                        </FlexBetween>
                        : null}
                </Container>
            </HeaderWrapper>
        </div>
    );
}

export default Header;


export const GallenbachTitle = () => {
    return (
        <>
            <div>
                <DropdownWrapper bodyType={"GallenbachZahneZahnarztpraxis"} iconBg={true} icon={<BusinessIcon fontSize='inherit' />} title={localStorage.getItem("practiceName")} />
            </div>
        </>
    )
}

export const PlanIcon = () => {

    let navigate = useNavigate();
    let location = useLocation();

    return (
        <>
            <div className='d-inline-flex'>
                {location.pathname === "/home" ? <PlaneButton onClick={() => navigate('/home')}>
                    <NoteIcon style={{ transform: 'rotate(90deg)  scaleX(-1)', marginRight: "5px" }} />
                    <span>Plane</span>
                </PlaneButton> : <PlaneButtonDisable onClick={() => navigate('/home')}>
                    <NoteIcon style={{ transform: 'rotate(90deg)  scaleX(-1)', marginRight: "5px" }} />
                    <span>Plane</span>
                </PlaneButtonDisable>}
            </div>
        </>
    )
}


export const FeedBackText = () => {
    return (
        <>
            <div>
                <DropdownWrapper position={'right'} bodyType={"Feedback"} buttonClass="handEmojiButton" noarrow iconBg={false} icon={<HandEmoji>{`👋`}</HandEmoji>} title={"Feedback"} />
            </div>
        </>
    )
}

export const LogoutText = () => {
    return (
        <>
            <div>
                <DropdownWrapper position={'right'} bodyType={"ProfileDropdown"} buttonClass="UsernameBtn" iconBg={false} noarrow title={"Heinz Gallenbach"} />
            </div>
        </>
    )
}


