import React, { useState, useEffect, useRef } from 'react';
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Typography from '@mui/material/Typography';
import moment from "moment";
import { useDispatch, useSelector } from 'react-redux';
import Popover from '@mui/material/Popover';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { dynamicPostApi } from './../../Api';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { setRecall, setPlanStatusChanged, setDisableButton, setBehandlungShiftCheck, setPopOver, setPopOverPatientName, setIsPrivatePlan } from './../../Redux/Slice/commonSlice';
import { Validation } from './NotesModal';

const ColorButton = styled(Button)(() => ({
  color: "#dddcf7",
  backgroundColor: "#120ec5",
  textTransform: "none",
  borderRadius: "50px",
  padding: "8px 28px !important",
  fontSize: "17px",
  "&:hover": {
    backgroundColor: "#090699",
    color: "#fff",
  },
  "& .MuiPopover-paper": {
    borderRadius: "30px",
  }
}));

export const EmptyCardWrapper = styled(Stack)(() => ({
  backgroundColor: '#fff !important',
  borderRadius: '30px',
  padding: '25px 12px',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const Centeritems = styled(Stack)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  "& p": {
    margin: '5px 0 0 0',
    textAlign: 'center',
    color: '#000',
    fontSize: '16px',
  },
  "& svg": {
    color: '#21bf89',
  }
}));

export const CardHover = {
  maxWidth: '300px',
  borderRadius: '50%',
  padding: '10px',
}

function ModalLeftSidebar(props) {

  const btnText = {
    '1': 'Planung abschlieBen',
    '2': 'Zustimmung vorhanden',
    '3': 'Eingegliedert'
  }

  const [cookies, setCookie, removeCookie] = useCookies(['authToken']);
  let navigate = useNavigate();
  const btnRef = useRef();
  const { modalData, dispatch } = props;

  let disableButton = useSelector(state => state.common.disableButton);
  let behandlungShiftCheck = useSelector(state => state.common.behandlungShiftCheck);

  const [tooltipPopover, setTooltipPopover] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isCopy, setIsCopy] = useState(false);

  useEffect(() => {
    if (disableButton === false && (modalData.status === 1 || modalData.status === 2) && modalData.eHkp) {
      setAnchorEl(btnRef?.current);
      setTooltipPopover(true);
    } else {
      setAnchorEl(null);
      setTooltipPopover(false);
    }
  }, [disableButton]);

  useEffect(() => {
    if (tooltipPopover) {
      setTimeout(() => {
        setAnchorEl(null);
        setTooltipPopover(false);
      }, 3000);
    }
  }, [tooltipPopover]);

  useEffect(() => {
    if (behandlungShiftCheck) {
      btnPlanComplete();
      dispatch(setBehandlungShiftCheck(false));
    }
  }, [behandlungShiftCheck]);

  const GetFirstLetters = (str) => {
    var matches = str.match(/\b(\w)/g); // ['J','S','O','N']
    var acronym = matches.join('');
    return acronym
  }

  const btnPlanComplete = async () => {
    let url = "api/kanban/updateKanbanItem";
    let data = {};
    if (modalData.status === 1) {
      data = { ...modalData, requiredAttentionDate: null, status: 2, progress: 1 };
      dispatch(setPlanStatusChanged({ kanbanId: modalData.id, currentStage: modalData.status, nextStage: 'Zustimmung' }));
    }
    if (modalData.status === 2) {
      data = { ...modalData, requiredAttentionDate: null, status: 3 };
      dispatch(setPlanStatusChanged({ kanbanId: modalData.id, currentStage: modalData.status, nextStage: 'Behandlung' }));
    }
    if (modalData.status === 3) {
      data = { ...modalData, requiredAttentionDate: null, status: 4 };
      dispatch(setPlanStatusChanged({ kanbanId: modalData.id, currentStage: modalData.status, nextStage: 'Abrechnung' }));
    }
    let res = await dynamicPostApi(data, url, navigate, setCookie, dispatch);
    if (res.data) {
      dispatch(setDisableButton(true));
      dispatch(setRecall(true));
      if (modalData.private_Plan) {
        dispatch(setPopOver(true));
        dispatch(setPopOverPatientName(modalData.name));
        dispatch(setIsPrivatePlan(true));
      }
    }
  }

  const copyToClipBoard = async copyMe => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setIsCopy(true);
      setTimeout(() => {
        setIsCopy(false);
      }, 2000);
    } catch (err) {
      setIsCopy(false);
    }
  };

  return (
    <>
      <Avatar
        sx={{ mb: 2 }}
        className="avatar-text"
      >
        {modalData.name && GetFirstLetters(modalData.name)}
      </Avatar>
      <Typography
        variant="h5"
        component="div"
        sx={{ fontFamily: "Monospace", fontWeight: 700, mb: 2 }}
      >
        {modalData.name}
      </Typography>
      <div>
        <Typography
          color="text.secondary"
          variant="body2"
          sx={{ fontWeight: "regular", fontSize: "15px" }}
        >
          Planaufstellung
        </Typography>
        <Typography
          color=""
          variant="body2"
          sx={{ fontWeight: "regular", fontSize: "17px" }}
        >
          {moment(new Date(modalData.aufstellungsdatum)).format("MMMM YYYY")}
        </Typography>
      </div>
      <div>
        <Typography
          color="text.secondary"
          variant="body2"
          sx={{ mt: 0.9, fontWeight: "regular", fontSize: "15px" }}
        >
          Wert
        </Typography>
        <Typography
          color=""
          variant="body2"
          sx={{ fontWeight: "regular", fontSize: "17px" }}
        >
          {/* {modalData.gesamtsumme} € */}
          {(!!modalData.gesamtsumme ? modalData.gesamtsumme : 0).toLocaleString('de', { style: 'currency', currency: 'Eur' })}
        </Typography>
      </div>
      <div>
        <Typography
          color="text.secondary"
          variant="body2"
          sx={{ mt: 0.9, fontWeight: "regular", fontSize: "15px" }}
        >
          Planart
        </Typography>
        <Typography
          color=""
          variant="body2"
          sx={{ fontWeight: "regular", fontSize: "17px" }}
        >
          {modalData.planName}
        </Typography>
      </div>
      <div>
        <Typography
          color="text.secondary"
          variant="body2"
          sx={{ mt: 0.9, fontWeight: "regular", fontSize: "15px" }}
        >
          Patienten-ID
        </Typography>
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
        >
          <Typography variant="body2" sx={{ fontWeight: "regular", fontSize: "17px" }}>
            {modalData.patientId}
          </Typography>
          <p>
            <ContentCopyIcon onClick={() => copyToClipBoard(modalData.patientId)}/>
          </p>
            {isCopy ? <Validation>Copied!</Validation> : null}
        </Stack>
      </div>
      <div className={`${modalData.status === 4 || modalData.isPlanDisable ? "mb-30px" : null}`}>
        <Typography
          color="text.secondary"
          variant="body2"
          sx={{ mt: 1.6, fontWeight: "regular", fontSize: "15px" }}
        >
          Plannummer
        </Typography>
        <Typography
          color=""
          variant="body2"
          sx={{ fontWeight: "regular", fontSize: "17px" }}>
          {modalData.plannummer}
        </Typography>
      </div>
      <div>
        {modalData.status !== 4 && modalData.isPlanDisable !== true &&
          <ColorButton
            ref={btnRef}
            disabled={(modalData.status === 1 || modalData.status === 2) && disableButton ? true : false}
            variant="contained" sx={{ mt: 2, mb: 3 }}
            onClick={() => btnPlanComplete()}>
            {btnText[modalData.status]}
          </ColorButton>}
        <PlanungTooltip anchorEl={anchorEl} tooltipPopover={tooltipPopover} setTooltipPopover={setTooltipPopover} />
      </div>
    </>
  );
}
export default ModalLeftSidebar;

export const PlanungTooltip = (props) => {

  const { tooltipPopover, setTooltipPopover, anchorEl } = props;

  const open = Boolean(tooltipPopover);

  return (
    <>
      <div>
        <Popover
          id="mouse-over-popover"
          className='popover-left-sidebar tooltip-mob'
          sx={{
            pointerEvents: 'none',
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          onClose={() => setTooltipPopover(false)}
          disableRestoreFocus
        >
          <div className='p-relative'>
            <div style={CardHover}>
              <EmptyCardWrapper>
                <Centeritems>
                  <CheckCircleIcon fontSize='large' />
                  <Typography sx={{ fontWeight: 600 }}>Planung abschließen</Typography>
                  <p>Der Plan ist abgeschickt Odet übergeben worden und kann Jet:t In dje nachste Phase</p>
                </Centeritems>
              </EmptyCardWrapper>
            </div>
          </div>

        </Popover>
      </div>
    </>
  )
}