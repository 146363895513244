import { React, useState } from 'react';
import styled from 'styled-components';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Box } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { DropdownWrapper } from '../HeaderDropdownWrapper';
import Typography from '@mui/material/Typography';
import Avatar from '../Avatar';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import SendIcon from '@mui/icons-material/Send';
import ChatConsent from '../Dialogs/ChatConsentModal';
import { useDispatch } from 'react-redux';
import { setWelcome, setChatList, setWhatsAppChat, setSameNumber, setChatSMS, setchatNotContact } from '../../Redux/Slice/chatSlice';

export const ChatHead = styled.div`
margin-left:-24px;
margin-right:-24px;
margin-top:-10px;
`
export const ChatHeadBox = styled.div`
display:flex;
align-items:center;
justify-content:space-between;
padding-left:24px;
padding-right:24px;
border-bottom:1px solid rgba(237 237 237);
padding-bottom:10px;
`
export const ChatBack = styled.div`
background:#efeae6;
padding:3px 5px;
border-radius: 50%;
display:flex;
align-items:center;
color:#120ec5;
height: 26px;
cursor: pointer !important;
`
export const DisplayFlex = styled.div`
display:flex;
align-items:center;
`
export const GroupTitle = styled.h3`
margin-left:15px;
margin-right:5px;
margin-top:0px;
margin-bottom:0px;
font-weight:600;
font-size:18px;
`
export const GroupHead = styled.div`
margin-left:-24px;
margin-right:-24px;
`
export const GroupHeadInner = styled.div`
padding-left:24px;
padding-right:24px;
padding-top:10px;
padding-bottom:10px;
border-bottom:1px solid rgba(247 247 247);
`
export const ChatBody = styled.div`
display: flex;
flex-direction: column;
justify-content: end;
height:350px;
overflow-y:auto;
margin-left:-24px;
margin-right:-24px;
`
export const ChatBodyInner = styled.div`
padding-left:24px;
padding-right:24px;
`
export const WhatsAppMsgBox = styled.div`
display:flex;
align-items:center;
justify-content:end;
margin-top:15px;
`
export const WhatsAppMsg = styled.div`
width:75%;
background-color:#120EC5;
border-radius:12px;
padding:15px;
`

export const ReplyBox = styled.div`
display:flex;
align-items:center;
justify-content:start;
margin-top:15px;
`
export const Reply = styled.div`
width:75%;
background-color:#f5f5f5;
border-radius:12px;
padding:15px;
`
export const MsgTitle = styled.p`
margin:0px;
font-size:16px;
color:#dfdfdf;
`
export const MsgText = styled.p`
margin-bottom:0px;
margin-top:20px;
font-size:16px;
color:#fff;
`
export const MsgTextFrom = styled.p`
margin:0px;
font-size:16px;
color:#fff;
`
export const MsgTextDivider = styled.p`
margin:0px;
font-size:16px;
color:#fff;
font-weight:600;
`
export const MsgDateTime = styled.p`
margin:0px;
font-size:12px;
color:#dfdfdf;
font-weight:600;
`
export const MsgDetailBox = styled.div`
display:flex;
align-items:center;
justify-content:space-between;
margin-top:5px;
`
export const MsgReadIcon = styled.div`
color:#fff;
`
export const ReplyTitle = styled.p`
margin:0px;
font-size:16px;
color:#000;
`
export const ReplyDateTime = styled.p`
margin:0px;
font-size:12px;
color:#ab9892;
`
export const ReplyDetail = styled.div`
margin-top:5px;
`
export const MsgBox = styled.div`
display:flex;
margin-left:-24px;
margin-right:-24px;

margin-top: 10px;
border-top:1px solid  rgba(247 247 247);
`
export const MsgInput = styled.input.attrs({ type: 'text' })`
width:100%;
border:none;
padding:7px 15px;
font-size:16px;
&:focus{
    width:100%;
    border:none; 
    outline:none; 
}
`
export const MsgSubmitBox = styled.div`
display:flex;
align-items:center;
justify-content:space-between;
margin-left:-10px;
margin-right:-10px;
margin-top:-5px;
`
export const SubmitDivider = styled.p`
margin:0px;
font-size:16px;
color:#000;
font-weight:600;
`
export const SubmitText = styled.p`
margin:0px;
font-size:16px;
color:#000;
font-weight:600;
`

function GroupChat(props) {

    let dispatch = useDispatch();
    
    const [ConsentModal, setConsentModal] = useState(false);

    const handleBackClick = () => {
        dispatch(setWelcome(false))
        dispatch(setChatList(true))
        dispatch(setWhatsAppChat(false))
        dispatch(setSameNumber(false))
        dispatch(setChatSMS(false))
        dispatch(setchatNotContact(false))
    }
    const handleNextClick = () => {
        dispatch(setWelcome(false))
        dispatch(setChatList(false))
        dispatch(setWhatsAppChat(false))
        dispatch(setSameNumber(true))
        dispatch(setChatSMS(false))
        dispatch(setchatNotContact(false))
    }

    return (
        <>
            <ChatHead>
                <ChatHeadBox>
                    <DisplayFlex>
                        <ChatBack>
                            <NavigateBeforeIcon onClick={handleBackClick} />
                        </ChatBack>
                        <GroupTitle>
                            Personengruppe
                        </GroupTitle>
                        <WhatsAppIcon sx={{ color: '#46C254', fontSize: '22px' }} />
                    </DisplayFlex>
                    <Box display="flex" justifyContent="center" alignItems="center">
                        <DropdownWrapper InnerText="Einverstandnis andem" bodyType={"ChatAction"} userId="" setConsentModal={setConsentModal} noarrow iconBg={false} icon={<MoreHorizIcon style={{ color: '#AB9892' }} />} />
                    </Box>
                </ChatHeadBox>
            </ChatHead>
            <GroupHead>
                <GroupHeadInner>
                    <DisplayFlex>
                        <Box>
                            <Avatar noMargin usernameProps="Name" className="table" width={25} height={25} />
                        </Box>
                        <Box sx={{ width: '100%' }} display="flex" justifyContent="space-between" alignItems="center">
                            <Box display="flex">
                                <Typography varient='p' component='p' sx={{ fontSize: '16px', margin: 0 }}>Name,</Typography>
                                <Box sx={{ marginLeft: '10px' }}>
                                    <Typography varient='p' component='p' sx={{ fontSize: '16px', margin: 0, color: '#ad9b95' }}>+1</Typography>
                                </Box>
                            </Box>
                            <Box className='pointer'>
                                <NavigateNextIcon onClick={handleNextClick} sx={{ color: '#ad9b95' }} />
                            </Box>
                        </Box>
                    </DisplayFlex>
                </GroupHeadInner>
            </GroupHead>
            <ChatBody>
                <ChatBodyInner className='scroll'>
                    <div>
                        <WhatsAppMsgBox>
                            <WhatsAppMsg>
                                <DisplayFlex>
                                    <Box>
                                        <Avatar noMargin src='https://localhost:44314/AccountImages/dcf95503-2eea-4e9d-a2ba-9a00885d9ef5.jpeg' usernameProps="Ess Patient" className="table" width={25} height={25} />
                                    </Box>
                                    <Box>
                                        <MsgTitle>Ess Patient</MsgTitle>
                                    </Box>
                                </DisplayFlex>
                                <MsgText>HUhu</MsgText>
                                <MsgTextDivider>---</MsgTextDivider>
                                <MsgTextFrom>Gallenbach & Zahne Zahnarztpraxis</MsgTextFrom>
                                <MsgDetailBox>
                                    <MsgDateTime>11.12.22, 14:33</MsgDateTime>
                                    <MsgReadIcon>
                                        <DoneAllIcon sx={{ fontSize: '18px' }} />
                                    </MsgReadIcon>
                                </MsgDetailBox>
                            </WhatsAppMsg>
                        </WhatsAppMsgBox>
                        <ReplyBox>
                            <Reply>
                                <Box>
                                    <ReplyTitle>Jap</ReplyTitle>
                                    <ReplyDetail sx={{ marginTop: '5px' }}>
                                        <ReplyDateTime>11.12.22  14:33</ReplyDateTime>
                                    </ReplyDetail>
                                </Box>
                            </Reply>
                        </ReplyBox>
                    </div>
                    <div>
                        <WhatsAppMsgBox>
                            <WhatsAppMsg>
                                <DisplayFlex>
                                    <Box>
                                        <Avatar noMargin src='https://localhost:44314/AccountImages/dcf95503-2eea-4e9d-a2ba-9a00885d9ef5.jpeg' usernameProps="Ess Patient" className="table" width={25} height={25} />
                                    </Box>
                                    <Box>
                                        <MsgTitle>Ess Patient</MsgTitle>
                                    </Box>
                                </DisplayFlex>
                                <MsgText>HUhu</MsgText>
                                <MsgTextDivider>---</MsgTextDivider>
                                <MsgTextFrom>Gallenbach & Zahne Zahnarztpraxis</MsgTextFrom>
                                <MsgDetailBox>
                                    <MsgDateTime>11.12.22, 14:33</MsgDateTime>
                                    <MsgReadIcon>
                                        <DoneAllIcon sx={{ fontSize: '18px' }} />
                                    </MsgReadIcon>
                                </MsgDetailBox>
                            </WhatsAppMsg>
                        </WhatsAppMsgBox>
                        <ReplyBox>
                            <Reply>
                                <Box>
                                    <ReplyTitle>Jap</ReplyTitle>
                                    <ReplyDetail sx={{ marginTop: '5px' }}>
                                        <ReplyDateTime>11.12.22  14:33</ReplyDateTime>
                                    </ReplyDetail>
                                </Box>
                            </Reply>
                        </ReplyBox>
                    </div>
                </ChatBodyInner>
            </ChatBody>
            <MsgBox>
                <MsgInput />
            </MsgBox>
            <MsgSubmitBox>
                <Box>
                    <SubmitDivider>---</SubmitDivider>
                    <SubmitText>Gallenbach & Zahne Zahnarztpraxis</SubmitText>
                </Box>
                <Box>
                    <SendIcon sx={{ rotate: '-45deg', color: '#EFEAE6' }} />
                </Box>
            </MsgSubmitBox>
            <ChatConsent isShow={ConsentModal} hideConsentModal={setConsentModal} />
        </>
    );
}


export default GroupChat;
