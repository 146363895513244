import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import UserTable from '../Components/Tables/UserTable';
import { styled, alpha } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import EmployeeTable from '../Components/Tables/EmployeeTable';
import InviteColleague from '../Components/Dialogs/InviteColleague';
import BearbeitenModal from '../Components/Dialogs/BearbeitenModal';
import EinladenModal from '../Components/Dialogs/EinladenModal';


export const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#f5f5f5',
    borderRadius: '20px',
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));
export const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));
export const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    width: '100%',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
    },
}));

function Team(props) {
    const [ColleagueModal, setColleagueModal] = useState(false);
    const [bearbeiten, setBearbeiten] = useState(false);
    const [sreachText, setSreachText] = useState("");
    const [einladenModal, setEinladenModal] = useState(false);
    const [inviteToSelectedUserId, setInviteToSelectedUserId] = useState("");

    const filterRecordParent = (e) => {
        setSreachText(e.target.value);
    }

    return (
        <>
            <Box sx={{ p: 5 }} className='p-mob'>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} className='flex-column-mob'>
                    <Typography variant='h5' component='h5' sx={{ fontWeight: '600', color: '#1a1a1a' }}>HKP-Hero Nutzer:innen</Typography>
                    <Button className='btn-light practiceSetting-btn-mt' onClick={() => setColleagueModal(true)}>Mitarbeiter einladen</Button>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <UserTable setColleagueModal={setColleagueModal} />
                </Box>
                <Box sx={{ mt: 5, mb: 2 }}>
                    <Typography variant='h5' component='h5' sx={{ fontWeight: '600', color: '#1a1a1a' }}>In Dampsoft gefundene Mitarbeiter:innen</Typography>
                </Box>
                <Box sx={{ mt: 0, mb: 3 }}>
                    <Typography variant='p' component='p' sx={{ fontWeight: '500', margin: 0, fontSize: '16px', color: '#1a1a1a' }}>Um Kolleg:innen Zugriff auf HKP-Hero zu geben, müssen sie eingeladen werden.</Typography>
                </Box>
                <Box sx={{ mt: 0, mb: 3 }}>
                    <Search>
                        <SearchIconWrapper>
                            <SearchIcon />
                        </SearchIconWrapper>
                        <StyledInputBase
                            placeholder="Search…"
                            inputProps={{ 'aria-label': 'search' }}
                            onChange={(e) => filterRecordParent(e)}
                        />
                    </Search>
                </Box>
                <Box>
                    <EmployeeTable setBearbeiten={setBearbeiten} sreachText={sreachText} setEinladenModal={setEinladenModal} setInviteToSelectedUserId={setInviteToSelectedUserId} />
                </Box>
            </Box>
            <InviteColleague isShow={ColleagueModal} hideColleagueModal={() => setColleagueModal(false)} />
            <BearbeitenModal isShow={bearbeiten} hideBearbeiten={() => setBearbeiten(false)} />
            <EinladenModal isShow={einladenModal} hideEinladenModal={() => setEinladenModal(false)} selectUserId={inviteToSelectedUserId} />
        </>
    );
}

export default Team;