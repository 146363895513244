import * as React from 'react';
import Popover from '@mui/material/Popover';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import styled from 'styled-components';

function ZustimmungPopover (props) {

    const {zustimmungPopover, closeZustimmungPopover} = props;

    const CardHover = {
        maxWidth: '330px',
       }


    return(
        <>
         <div>
         <Popover
                id="mouse-over-popover"
                sx={{
                pointerEvents: 'none',
                }}
                open={zustimmungPopover}
                anchorEl={zustimmungPopover}
                anchorOrigin={{
                vertical: 'right',
                horizontal: 'right',
                }}
                transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
                }}
                onClose={() => closeZustimmungPopover("Zustimmung")}
                marginThreshold={0}
                disableAutoFocus={true}
                disableEnforceFocus={true}
                className='CustomPopoverStyle'
            >
                <div style={CardHover}>
                    <div alignItems="flex-start">
                        <CardMedia
                        component="img"
                        height="auto"
                        sx={{width: '100px'}}
                        image="/images/zustimmung-img.png"
                        alt="Planung Image"
                        />
                    </div>
                    <CardContent sx={{py:'0.5px !important'}}>
                        <Typography gutterBottom variant="h5" component="div" style={{fontWeight: 600}}>
                        Zustimmung
                        </Typography>
                        <Typography variant="body2" color="" className='fs-17'>
                        Von der Patientenerinnerung, den Plan an die Kasse zu schicken bis zur Wiedervor Bestätigung, dass alle Plan-Unterlagen unterschrieben in der Praxis liegen.
                        </Typography>
                        <Timeline position="right" sx={{mb: 0, pl: 0, pr: 0}}>
                            <TimelineItem className='timeline-no'>
                                <TimelineSeparator>
                                <TimelineDot className='card-dots badge-text-20'>
                                <span>1</span> 
                                </TimelineDot>
                                <TimelineConnector className='card-connect' />
                                </TimelineSeparator>
                                <TimelineContent className='fs-17' sx={{pt: 0, pr: 0, lineHeight: 1.3}}>Patientenerinnerungen mit konfigurierbarer Wiedervorlage.</TimelineContent>
                            </TimelineItem>
                            <TimelineItem className='timeline-no'>
                                <TimelineSeparator>
                                <TimelineDot className='card-dots badge-text-20'>
                                <span>2</span>
                                </TimelineDot>
                                <TimelineConnector className='card-connect' />
                                </TimelineSeparator>
                                <TimelineContent className='fs-17' sx={{pt: 0, pr: 0, lineHeight: 1.3}}>Dokumentation des Gutachterprozesses und damit verbundener Aufgaben.</TimelineContent>
                            </TimelineItem>
                            <TimelineItem className='timeline-no'>
                                <TimelineSeparator>
                                <TimelineDot className='card-dots badge-text-20'>
                                <span>3</span>
                                </TimelineDot>
                                </TimelineSeparator>
                                <TimelineContent className='fs-17' sx={{pt: 0, pr: 0, lineHeight: 1.3}}>Abschluss der Zustimmungsphase sobald alle Unterlagen in der Praxis liegen.</TimelineContent>
                            </TimelineItem>
                        </Timeline>
                    </CardContent>
                </div>
          </Popover>
         </div>
        </>
    )
}
export default ZustimmungPopover;