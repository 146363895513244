import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

function DialogParent (props) {
    const {open, handleClose,fullWidth,maxWidth,widthClass} = props;

    return(
        <>
           <div>
            <Dialog
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            open={open}
            onClose={handleClose}
            className={`${!!widthClass ? widthClass:"model-round"}`}
            aria-labelledby="responsive-dialog-title"
            >
                <DialogContent sx={{p: 0, borderRadius: '22px'}}>
                        {props.children}
                </DialogContent>
            </Dialog>
          </div>
        </>
    )
}
export default DialogParent;