import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
// import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import DoneIcon from '@mui/icons-material/Done';
import UpdateRoundedIcon from '@mui/icons-material/UpdateRounded';
import SelectDropdown from '../Inputs/SelectDropdown';
import CloseIconWrap from '../CloseIconWrap';
import { DialogContentWrapper } from './WarDieAntwort';

const AntwortWrap = styled.div`
padding-left:4.4%;
padding-right:4.4%;
padding-top:2.0%;
padding-bottom:2.0%;
`
const Avatar = styled.div`
  border-radius:13px;
  background-color:#e7eceb;
  width:45px;
  height:45px;
  color:#63c2a6;
`
const AvatarRotate = styled.div`
  border-radius:13px;
  background-color:#fcf7fa;
  width:45px;
  height:45px;
  color:#120ec5;
`
const DialogTitle = styled.div`
  padding-bottom:0px;
  font-size:22px;
  font-weight:700;
  text-align:center;
`
const AvatarCross = styled.div`
  border-radius:13px;
  background-color:#fff1f9;
  width:45px;
  height:45px;
  color:#d8315e;
`

function BeispielGesagtModal(props) {

    const { open, setOpen, patientName, setAbgelehnt, valueChange } = props;

    const onAccept = () => {
        valueChange("approvalAvailable");
        setOpen(false);
    }
    const onReject = () => {
        setAbgelehnt(true);
        setOpen(false);
    }
    const onDelay = () => {
        setOpen(false);
        valueChange('delayPlan')
    }

    return (
        <>
            <Dialog
                fullWidth={true}
                maxWidth={'sm'}
                open={open}
                onClose={() => setOpen(false)}
                className="antwort-width-600"
                aria-labelledby="responsive-dialog-title"
            >
                <div className='closeInner-btn' onClick={() => setOpen(false)}>
                    <CloseIconWrap />
                </div>
                <DialogContentWrapper>
                    <AntwortWrap>
                        <DialogTitle id="responsive-dialog-title" align="center" nowrap={true} className="">
                            Was hat {patientName} gesagt?
                        </DialogTitle>
                        <Typography variant="subtitle2" align="center" className='subtitle-modal'>
                            Wurde der Plan schon an die Kasse geschickt Oder liegt er noch immer bei {patientName}?
                        </Typography>
                        <DialogContent sx={{ p: 0 }}>
                            <DialogContentText>
                                <List sx={{ bgcolor: 'background.paper' }}>
                                    <ListItem alignItems="flex-start" className='list-bg' onClick={() => onAccept()}>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <DoneIcon className='cross-icon m-icon' />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <div>
                                            <ListItemText
                                                className='badge-text-20'
                                                primary="Kasse hat den Plan genehmigt"
                                                sx={{ mb: 0, color: '#363336' }}
                                            />
                                            <Typography
                                                sx={{ lineHeight: '0.20rem' }}
                                                component="span"
                                                color="text.secondary"
                                                className='fs-17'
                                            >
                                                Der Plan wurde genehmigt. Sobald die Unterlagen in der Praxis liegen, kann die Behandlung beginnen.
                                            </Typography>
                                        </div>
                                    </ListItem>
                                    <ListItem alignItems="flex-start" className='list-bg' onClick={() => onReject()}>
                                        <ListItemAvatar>
                                            <AvatarCross>
                                                <CloseIcon className='cross-icon m-icon' />
                                            </AvatarCross>
                                        </ListItemAvatar>
                                        <div>
                                            <ListItemText
                                                className='badge-text-20'
                                                primary="Kasse hat den Plan abgelehnt"
                                                sx={{ mb: 0, color: '#363336' }}
                                            />
                                            <Typography
                                                sx={{ lineHeight: '0.20rem' }}
                                                component="span"
                                                color="text.secondary"
                                                className='fs-17'
                                            >
                                                Die Kasse hat den Plan entweder direkt Oder nach einem Gutachterprozess abgelehnt.
                                            </Typography>
                                        </div>
                                    </ListItem>
                                    <ListItem alignItems="flex-start" className='list-bg' onClick={() => onDelay()}>
                                        <ListItemAvatar>
                                            <AvatarRotate>
                                                <UpdateRoundedIcon className='cross-icon m-icon' sx={{ transform: 'rotate(25deg)' }} />
                                            </AvatarRotate>
                                        </ListItemAvatar>
                                        <div>
                                            <ListItemText
                                                className='badge-text-20'
                                                primary="Keine Veränderung"
                                            />
                                            <Typography
                                                sx={{ lineHeight: 0.6 }}
                                                component="span"
                                                color="text.secondary"
                                                className='fs-17'
                                            >
                                                Plan Wird auf Wiedervorlage gesetzt. HKP-Hero erinnert dich später daran, dass {patientName} Beismel erneut gefragt werden muss,
                                            </Typography>
                                        </div>
                                    </ListItem>
                                    <div class="">
                                        <SelectDropdown />
                                    </div>
                                </List>
                            </DialogContentText>
                        </DialogContent>
                    </AntwortWrap>
                </DialogContentWrapper>
            </Dialog>
        </>
    );
}

export default BeispielGesagtModal;