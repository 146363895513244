import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Typography } from '@mui/material';
import styled from 'styled-components';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { DropdownWrapper } from '../HeaderDropdownWrapper';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { dynamicGetApi } from '../../Api';
import { useDispatch, useSelector } from 'react-redux';
import Avatar from '../Avatar';
import { setRecall } from './../../Redux/Slice/commonSlice';
import { AWS_BUCKET_URL } from './../../Config';



const NoImg = styled.div`
padding:8px;
border-radius:50%;
background-color:#f3efec;
text-transform:uppercase;
font-weight:700;
font-size:16px;
margin-right:5px;
color:#AB9892;
`
const ActionIcon = styled.div`
padding:5px;
padding-bottom:3px !important;
display:inline-block;
border-radius:10px;
&:hover{
    background-color:#F5F5F5;
}
`
const ImgStyle = styled.img`
width:41px;
border-radius:50%;
margin-right:5px;
`

export default function UserTable(props) {
    const [cookies, setCookie, removeCookie] = useCookies(['authToken']);
    let dispatch = useDispatch();
    let navigate = useNavigate();
    const isRecall = useSelector(state => state.common.recall);

    const [userList, setUserList] = useState([]);

    useEffect(() => {
        getUserList();
    }, []);

    useEffect(() => {
        if (isRecall) {
            getUserList();
            dispatch(setRecall(false));
        }
    }, [isRecall]);

    const getUserList = async () => {
        let url = "api/user/getUsers";
        let user = await dynamicGetApi(url, navigate, setCookie,dispatch);
        setUserList(user.data);
    }

    return (
        <>
            <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ width: "60%", color: '#1a1a1a' }}><b>Name</b></TableCell>
                            <TableCell align='center' sx={{ color: '#1a1a1a' }}><b>Rolle</b></TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.keys(userList).length > 0 && userList.length > 0 ? userList.map(x =>
                            <TableRow>
                                <TableCell sx={{ px: 0 }}>
                                    <Box display="flex" justifyContent="start" alignItems="center">
                                        <Box>
                                            <Avatar noMargin usernameProps={x.name} className="table" src={AWS_BUCKET_URL + x.image} width={35} height={35} />
                                        </Box>
                                        <Box>
                                            <Typography varient='p' component='p' sx={{ fontSize: '16px', margin: 0 }}>{x.name}</Typography>
                                            <Typography varient='p' component='p' sx={{ fontSize: '14px', margin: 0, color: '#bcada7' }}>{x.email}</Typography>
                                        </Box>
                                    </Box>
                                </TableCell>
                                <TableCell align='center'>{x.role}</TableCell>
                                <TableCell align='center'>
                                    <Box display="flex" justifyContent="center" alignItems="center">
                                        <DropdownWrapper bodyType={"TableAction"} userId={x.id} setColleagueModal={props.setColleagueModal} noarrow iconBg={false} icon={<MoreHorizIcon style={{ color: '#AB9892' }} />} />
                                    </Box>
                                </TableCell>
                            </TableRow>
                        ):<TableRow>
                        <TableCell colSpan={3}>
                            <Box display="flex" justifyContent="center" alignItems="center">
                                <Box>
                                    <Typography varient='p' component='p' sx={{ fontSize: '16px', margin: 0 }}>No data available</Typography>
                                </Box>
                            </Box>
                        </TableCell>
                    </TableRow>}
                    </TableBody>
                </Table>
            </TableContainer>

        </>
    );
}