import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import UpdateRoundedIcon from '@mui/icons-material/UpdateRounded';
import SelectDropdown from '../Inputs/SelectDropdown';
import CloseIconWrap from '../CloseIconWrap';

const SetzenWrap = styled.div`
padding-left:4.4%;
padding-right:4.4%;
`
const Avatar = styled.div`
border-radius:13px;
background-color:#fcf7fa;
width:45px;
height:45px;
color:#120ec5;
`

function WiedervorlageSetzenModal(props) {

    const { open, setOpen, valueChange } = props;
    return (
        <>
            <Dialog
                fullWidth={true}
                maxWidth={'sm'}
                open={open}
                onClose={() => setOpen(false)}
                className="antwort-width-600"
                aria-labelledby="responsive-dialog-title"
            >
                <div onClick={() => setOpen(false)}>
                    <CloseIconWrap />
                </div>
                <SetzenWrap>
                    <DialogTitle id="responsive-dialog-title" align="center" sx={{ pb: 0 }} className="antwort-title">
                        {"Plan auf Wiedervorlage setzen"}
                    </DialogTitle>
                    <DialogContent>
                        <Typography variant="subtitle2" align="center" className='subtitle-modal'>
                            Die Eingliederung hat noch nicht stattgefunden. HKP-Hero erinnert dich rechtzeitig wieder an den Plan.
                        </Typography>
                        <DialogContentText sx={{ pt: 4 }}>
                            <List sx={{ bgcolor: 'background.paper' }}>
                                <ListItem alignItems="flex-start" sx={{ mb: 3 }} className='list-bg flex-col-mob-center' onClick={()=>{valueChange('delayPlan');setOpen(false)}}>
                                    <ListItemAvatar xs={{ mt: '0px !important' }}>
                                        <Avatar>
                                            <UpdateRoundedIcon className='cross-icon m-icon' sx={{ transform: 'rotate(25deg)' }} />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        className='badge-text-20 text-center-mob'
                                        sx={{ mt: 1 }}
                                        primary="Auf Wiedervorlage setzen"
                                    />
                                </ListItem>
                                <div class="">
                                    <SelectDropdown />
                                </div>
                            </List>
                        </DialogContentText>
                    </DialogContent>
                </SetzenWrap>
            </Dialog>
        </>
    )
}
export default WiedervorlageSetzenModal;