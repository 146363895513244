import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
// import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
// import Avatar from '@mui/material/Avatar';
import TodayIcon from '@mui/icons-material/Today';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import SelectDropdown from '../Inputs/SelectDropdown';
import CloseIconWrap from '../CloseIconWrap';


const AntwortWrap = styled.div`
padding-left:4.4%;
padding-right:4.4%;
padding-top:2.0%;
`
const Avatar = styled.div`
  border-radius:13px;
  background-color:#e7eceb;
  width:45px;
  height:45px;
  color:#63c2a6;
`
const AvatarRotate = styled.div`
  border-radius:13px;
  background-color:#fbf6fa;
  width:45px;
  height:45px;
  color:#120ec5;
`

const DialogTitle = styled.div`
  padding-bottom:0px;
  font-size:24px;
  font-weight:700;
  text-align:center;
`

function PlanAbgelehntModal (props) {

    const {open, setOpen, setRejectionAppealed, valueChange} = props;

    return(
        <>
        <Dialog
            fullWidth={true}
            maxWidth={'sm'}
            open={open}
            onClose={() => setOpen(false)}
            className="antwort-width-600"
            aria-labelledby="responsive-dialog-title"
        >
            <div onClick={() => setOpen(false)}>
               <CloseIconWrap />
            </div>
            <AntwortWrap>
                <DialogTitle id="responsive-dialog-title" align="center" nowrap={true} className="">
                {"Kasse hat den Plan abgelehnt"}
                </DialogTitle>
                <Typography variant="subtitle2" align="center" className='subtitle-modal'>
                  Wie Wird mit diesem Plan weiter verfahren?
                </Typography>
                <DialogContent>
                <DialogContentText sx={{mt: 0.9}}>
                    <List sx={{ bgcolor: 'background.paper', pt: 0 }}>
                        <ListItem alignItems="flex-start" className='list-bg' onClick={() => {valueChange("disablePlan"); setOpen(false)}}>
                            <ListItemAvatar>
                            <AvatarRotate>
                              <TodayIcon className='cross-icon m-icon'/>
                          </AvatarRotate>
                            </ListItemAvatar>
                            <div>
                                <ListItemText  className='badge-text-20' primary="Plan deaktivieren" sx={{mb: 0, color: '#363336'}} />
                                <Typography
                                        sx={{ lineHeight: '0.20rem' }}
                                        component="span"
                                        color="text.secondary"
                                        className='fs-17'
                                    >
                                        Sobald du den Plan deaktivierst. ist er in HKP-Hero nicht mehr sichtbar.
                                </Typography>
                            </div>
                        </ListItem>
                        <ListItem alignItems="flex-start" className='list-bg' sx={{mb: 2}} onClick={()=> {setRejectionAppealed(true); setOpen(false)}}>
                            <ListItemAvatar>
                            <AvatarRotate>
                              <PriorityHighIcon className='cross-icon m-icon' />
                          </AvatarRotate>
                            </ListItemAvatar>
                            <div>
                                <ListItemText
                                className='badge-text-20'
                                primary="Ablehnung wird angefochten"
                                sx={{mb: 0, color: '#363336'}}
                                />
                                <Typography
                                        sx={{ lineHeight: '0.20rem' }}
                                        component="span"
                                        color="text.secondary"
                                        className='fs-17'
                                    >
                                        Der Plan liegt beim Obergutachter Oder es wurde Einspruch bei der KZV eingelegt. Der Plan Wird dann auf Wiedervorlage gesetzt und HKP-Hero fragt regelmäßig nach dem Status.
                                </Typography>
                            </div>
                        </ListItem>
                        <div class="">
                            <SelectDropdown />
                        </div>
                    </List>
                </DialogContentText>
                </DialogContent>
            </AntwortWrap>
        </Dialog>
        </>
    )
}
export default PlanAbgelehntModal;