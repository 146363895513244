import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import { Box, Button } from '@mui/material';
import AttachmentIcon from '@mui/icons-material/Attachment';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import { useFormik } from 'formik';
import CloseIcon from '@mui/icons-material/Close';
import { CustomWrapper, ListWrapper, PlanarternFooter, PlanarternHeading } from './HeaderDropdownWrapper';
import SearchBar from './SearchBar';
import OutsideClickHandler from 'react-outside-click-handler';
import BadgeLabelComment from './BadgeLabelComment';
import TextboxSaveBtn from './TextboxSaveBtn';



const PaddingLeftCoomment = styled.div`
padding-left: 11px;
`

function NewCommentTextbox (props) {

  const {planId, userList} = props;

   return(
        <>
           <Box display="flex" justifyContent="start" sx={{ pt: 1 }} className="flex-between-mob px-0-mob flex-col-center-sx">
                <div>
                    <BadgeLabelComment />
                </div>
                <div style={{flex: '1'}}>
                    <PaddingLeftCoomment>
                            <TextboxSaveBtn planId={planId} userList={userList}/>
                    </PaddingLeftCoomment>
                </div>
            </Box>
        </>
   )
}
export default NewCommentTextbox;

