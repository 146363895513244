import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Button, Typography } from '@mui/material';
import styled from 'styled-components';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { dynamicGetApi } from '../../Api';
import { useDispatch, useSelector } from 'react-redux';
import Avatar from '../Avatar';
import { setRecall, setIsEdit } from './../../Redux/Slice/commonSlice';
import { AWS_BUCKET_URL } from './../../Config';
import { DropdownWrapper } from '../HeaderDropdownWrapper';

const NoImg = styled.div`
padding:8px;
border-radius:50%;
background-color:#f3efec;
text-transform:uppercase;
font-weight:700;
font-size:16px;
margin-right:5px;
color:#AB9892;
`
const ActionIcon = styled.div`
padding:5px;
padding-bottom:3px !important;
display:inline-block;
border-radius:10px;
&:hover{
    background-color:#F5F5F5;
}
`
const ImgStyle = styled.img`
width:41px;
border-radius:50%;
margin-right:5px;
`


export default function EmployeeTable(props) {
    const [cookies, setCookie, removeCookie] = useCookies(['authToken']);
    let dispatch = useDispatch();
    let navigate = useNavigate();

    const isRecall = useSelector(state => state.common.recall);

    const [userList, setUserList] = useState([]);
    const [userFilterList, setUserFilterList] = useState([]);

    useEffect(() => {
        getUserList();
    }, []);

    useEffect(() => {
        if (isRecall) {
            getUserList();
            dispatch(setRecall(false));
        }
    }, [isRecall]);

    useEffect(() => {
        if (!!props.sreachText) {
            filterRecord();
        }
        else {
            getUserList();
        }
    }, [props.sreachText]);

    const getUserList = async () => {
        let url = "api/user/getUsersToInvite";
        let user = await dynamicGetApi(url, navigate, setCookie, dispatch);
        setUserList(user.data);
        setUserFilterList(user.data);
    }

    const inviteUserModal = async (userId) => {
        props.setEinladenModal(true);
        props.setInviteToSelectedUserId(userId);
        dispatch(setIsEdit(true));
    }

    const filterRecord = () => {
        let val = props.sreachText;
        let newRecords = userFilterList.filter(x => x.firstName.includes(val) || x.lastName.includes(val) || x.email.includes(val));
        setUserList(newRecords)
    }

    const GetFirstLetters = (str) => {
        var matches = str.match(/\b(\w)/g); // ['J','S','O','N']
        var acronym = matches.join('');
        return acronym
    }

    return (
        <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ width: "60%", color: '#1a1a1a' }}><b>Name</b></TableCell>
                        <TableCell align='center' sx={{ color: '#1a1a1a' }}><b>Pläne</b></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.keys(userList).length > 0 && userList.length > 0 ? userList.map(x =>
                        <TableRow>
                            <TableCell sx={{ px: 0 }}>
                                <Box display="flex" justifyContent="start" alignItems="center">
                                    <Box>
                                        <NoImg>{GetFirstLetters(x.name)}</NoImg>
                                    </Box>
                                    <Box>
                                        <Typography varient='p' component='p' sx={{ fontSize: '17px', margin: 0 }}>{x.name}</Typography>
                                    </Box>
                                </Box>
                            </TableCell>
                            <TableCell align='center'>0</TableCell>
                            <TableCell>
                                <Box display="flex" justifyContent="end" alignItems="center">
                                    <Button type='button' onClick={() => inviteUserModal(x.id)} className='btn-light' sx={{ mr: 3 }}>Einladen</Button>
                                    <DropdownWrapper bodyType={"TableAction"} userId={x.id} setColleagueModal={props.setBearbeiten} noarrow iconBg={false} icon={<MoreHorizIcon style={{ color: '#AB9892' }} />} />
                                </Box>
                            </TableCell>
                        </TableRow>
                    ) :
                        <TableRow>
                            <TableCell colSpan={3}>
                                <Box display="flex" justifyContent="center" alignItems="center">
                                    <Box>
                                        <Typography varient='p' component='p' sx={{ fontSize: '16px', margin: 0 }}>No data available</Typography>
                                    </Box>
                                </Box>
                            </TableCell>
                        </TableRow>
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
}