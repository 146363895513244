import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

export const EditBtnWrapper = styled.div`
display:flex;
align-items:center;
justify-content:end;
`
export const EditBtn = styled.div`
background: #efeae6;
padding: 4px 10px;
border-radius: 10px;
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
-webkit-align-items: center;
-webkit-box-align: center;
-ms-flex-align: center;
align-items: center;
color: #120ec5;
height: 26px;
cursor: pointer;
&:hover{
    background: #120ec5;  
    color: #fff;
}
`
export const DeleteBtn = styled.div`
background: #efeae6;
padding: 4px 10px;
border-radius: 10px;
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
-webkit-align-items: center;
-webkit-box-align: center;
-ms-flex-align: center;
align-items: center;
color: #f1416c;
height: 26px;
cursor: pointer;

&:hover{
    background: #f1416c;  
    color: #fff;
}
`
export const SuccessBtn = styled.div`
background: #efeae6;
padding: 4px 10px;
border-radius: 10px;
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
-webkit-align-items: center;
-webkit-box-align: center;
-ms-flex-align: center;
align-items: center;
color: #50cd89;
height: 26px;
cursor: pointer;
&:hover{
    background: #50cd89;  
    color: #fff;
}
`
export const BlackBtn = styled.div`
background: #efeae6;
padding: 4px 10px;
border-radius: 10px;
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
-webkit-align-items: center;
-webkit-box-align: center;
-ms-flex-align: center;
align-items: center;
color: #3b3b3b;
height: 26px;
cursor: pointer;
&:hover{
    background: #3b3b3b;  
    color: #fff;
}
`
export const TextArea = styled.textarea`
display: block;
width: 97%;
padding: 0.375rem 0.75rem;
background-clip: padding-box;
-webkit-appearance: none;
-moz-appearance: none;
appearance: none;
transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
font-size:16px;
border-radius: 8px;
background-color: #f5f5f5;
border-color: #f5f5f5;
&:hover{
    border-color: #f5f5f5;
};
&:focus{
    outline: none;
    border-color: #f5f5f5;
};
&:focus-visible{
    outline: none;
    border-color: #f5f5f5 !importan;
}

`
export const FlexCenter = styled.div`
display:flex;
align-items:center;
justify-content:center;
`
export const Validation = styled.div`
color:red;
margin-left:5px;
`

function NotesModal(props) {
    

    return (
        <>
        </>
    );
}

export default NotesModal;