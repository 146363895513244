import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import UpdateRoundedIcon from '@mui/icons-material/UpdateRounded';
import SelectDropdown from '../Inputs/SelectDropdown';
import CloseIconWrap from '../CloseIconWrap';
import ApartmentIcon from '@mui/icons-material/Apartment';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';

const AntwortWrap = styled.div`
padding-left:4.4%;
padding-right:4.4%;
padding-top:2.0%;
`
const AvatarRotate = styled.div`
  border-radius:13px;
  background-color:#fcf7fa;
  width:45px;
  height:45px;
  color:#120ec5;
`
const DialogTitle = styled.div`
  padding-bottom:0px;
  font-size:24px;
  font-weight:700;
  text-align:center;
`
export const DialogContentWrapper = styled.div`
margin-top:2px;
`
function WarDieAntwortModal(props) {
    const { open, setOpen, setSelectedAction, setIfPlanImplementCheck, patientName, valueChange } = props;

    const onSelection = (action) => {
        if (action === "Send Plan") {
            setIfPlanImplementCheck(true);
            setSelectedAction(action);
            setOpen(false);
        }
        else if (action === "No Change") {
            setOpen(false);
            valueChange('delayPlan');
        }
        else {
            setOpen(false);
        }
    }

    return (
        <>
            <Dialog
                fullWidth={true}
                maxWidth={'sm'}
                open={open}
                onClose={() => setOpen(false)}
                className="antwort-width-600 Personally-Asking"
                aria-labelledby="responsive-dialog-title"
            >
                <div className='closeInner-btn' onClick={() => setOpen(false)}>
                    <CloseIconWrap />
                </div>
                <DialogContentWrapper>
                    <AntwortWrap>
                        <DialogTitle id="responsive-dialog-title" align="center" nowrap={true} className="">
                            {"Was war die Antwort?"}
                        </DialogTitle>
                        <Typography variant="subtitle2" align="center" className='subtitle-modal'>
                            Wurde der Plan schon an die Kasse geschickt Oder liegt er noch immer bei {patientName}?
                        </Typography>
                        <DialogContent>
                            <DialogContentText>
                                <List sx={{ bgcolor: 'background.paper', pt: 0 }}>
                                    <ListItem alignItems="flex-start" className='list-bg flex-col-mob-center' onClick={() => onSelection("Send Plan")}>
                                        <ListItemAvatar>
                                            <AvatarRotate>
                                                <ApartmentIcon className='cross-icon m-icon' />
                                            </AvatarRotate>
                                        </ListItemAvatar>
                                        <div className='text-center-mob'>
                                            <ListItemText
                                                className='badge-text-20'
                                                primary="Plan wurde an die Krankenkasse geschickt"
                                                sx={{ mb: 0, color: '#363336' }}
                                            />
                                            <Typography
                                                sx={{ lineHeight: '0.20rem' }}
                                                component="span"
                                                color="text.secondary"
                                                className='fs-17'
                                            >
                                                HKP-Hero ermnert dich spater daran {patientName} zu fragen, ob die Kasse Sich schon gemeldet hat.
                                            </Typography>
                                        </div>
                                    </ListItem>
                                    <ListItem alignItems="flex-start" className='list-bg flex-col-mob-center' onClick={() => onSelection("No Change")}>
                                        <ListItemAvatar>
                                            <AvatarRotate>
                                                <UpdateRoundedIcon className='cross-icon m-icon' sx={{ transform: 'rotate(25deg)' }} />
                                            </AvatarRotate>
                                        </ListItemAvatar>
                                        <div className='text-center-mob'>
                                            <ListItemText
                                                className='badge-text-20'
                                                primary="Keine Veränderung"
                                                sx={{ mb: 0, color: '#363336' }}
                                            />
                                            <Typography
                                                sx={{ lineHeight: '0.20rem' }}
                                                component="span"
                                                color="text.secondary"
                                                className='fs-17'
                                            >
                                                Plan Wird auf Wiedervorlaxe gesetzt. HKP-Hero ennnert dich spater daran, dass {props.patientName} erneut gefragt werden muss.
                                            </Typography>
                                        </div>
                                    </ListItem>
                                    <ListItem alignItems="flex-start" className='list-bg flex-col-mob-center' onClick={() => {valueChange("disablePlan"); setOpen(false)}}>
                                        <ListItemAvatar>
                                            <AvatarRotate>
                                                <Inventory2OutlinedIcon className='cross-icon m-icon' />
                                            </AvatarRotate>
                                        </ListItemAvatar>
                                        <div className='text-center-mob'>
                                            <ListItemText
                                                className='badge-text-20'
                                                primary="Plan deaktivieren"
                                                sx={{ mb: 0, color: '#363336' }}
                                            />
                                            <Typography
                                                sx={{ lineHeight: '0.20rem' }}
                                                component="span"
                                                color="text.secondary"
                                                className='fs-17'
                                            >
                                                Sobald du den Plan in dampsoft deaktiuerst, ist er in HKP-Hero nicht mehr sichtbar.
                                            </Typography>
                                        </div>
                                    </ListItem>
                                    <div>
                                        <SelectDropdown />
                                    </div>
                                </List>
                            </DialogContentText>
                        </DialogContent>
                    </AntwortWrap>
                </DialogContentWrapper>
            </Dialog>
        </>
    )
}
export default WarDieAntwortModal;