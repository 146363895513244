import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import { Box, Button } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import OutsideClickHandler from 'react-outside-click-handler';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { dynamicGetApi, dynamicPostApi } from './../Api';
import useAutosizeTextArea from './AutoResizeTextArea';
import { Search, SearchIconWrapper, StyledInputBase } from './SearchBar';
import SearchIcon from '@mui/icons-material/Search';
import { setMentionedAttachment, setReplyComment } from '../Redux/Slice/commentSlice';
import Popover from '@mui/material/Popover';

export const TextArea = styled.textarea`
width:100%;
border:none;
resize:none;
&:hover{
    border-color: #b6b2b2;
};
&:focus{
    outline: none;
};
&:focus-visible{
    outline: none;
}
`
export const CommentWrapper = styled.div`
display: block;
width: 97%;
padding: 0.375rem 0.75rem;
background-clip: padding-box;
-webkit-appearance: none;
-moz-appearance: none;
appearance: none;
transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
font-size:16px;
border-radius: 8px;
background-color: #ffffff;
border: none;
box-shadow:var(--ds-shadow-raised,0 1px 2px -1px #091e4240,0 0 0 1px var(--ds-border,#091e4214));
&:hover{
    border-color: #b6b2b2;
};
&:focus{
    outline: none;
    box-shadow: var(--ds-shadow-overlay,0 4px 8px -2px #091e4240,0 0 0 1px var(--ds-border,#091e4214));
};
&:focus-visible{
    outline: none;
    border-color: #b6b2b2 !important;
}
`
const IconTextSearch = styled.div`
border-radius:50%;
background-color:#0f5481;
width:30px;
height:30px;
color:#fff;
display: flex;
align-items: center;
justify-content: center;
margin-right: 10px;
`
export const IconsWrapper = styled.div`
font-size:20px;
& .addrightMargin{
    margin-right:6px;
}
& .practiceSetting-btn-mt{
 padding:4px 5px !important;
}
`
const DropdownWrapperDiv = styled.div`
background-color: #fff;
box-shadow:var(--ds-shadow-raised,0 1px 2px -1px #091e4240,0 0 0 1px var(--ds-border,#091e4214));
padding:15px;
border-radius:15px;
cursor:pointer !important;
`
const UserListWrapper = styled.div`
display: flex;
justify-content: start;
align-items: center;
margin: 15px 5px 5px;
& .userText{
font-size:16px;
}
`
export const Validation = styled.div`
color:red;
margin-left:5px;
`

function TextboxSaveBtn(props) {
    const [cookies, setCookie, removeCookie] = useCookies(['authToken']);
    let dispatch = useDispatch();
    let navigate = useNavigate();
    const [isUserMentionedPopupOpened, setIsUserMentionedPopupOpened] = useState(false);
    const newtextAreaRef = useRef(null);
    const InputREf = useRef(null);

    const { commentData, index, editOrUnEditComment, planId, userList } = props;

    let mentionedAttachment = useSelector(state => state.comment.mentionedAttachment);
    let replyComment = useSelector(state => state.comment.replyComment);

    const [comment, setComment] = useState();

    const commentValidationSchema = Yup.object().shape({
        comment: Yup.string().required("Required *"),
    });

    const formik = useFormik({
        initialValues: {
            id: 0,
            comment: '',
            planId: planId,
            mentionAttachmentIds: "",
            mentionUserIds: ''
        },
        validationSchema: commentValidationSchema,
        onSubmit: async values => {
            let url = 'api/comment/createUpdateComment';
            let result = await dynamicPostApi(values, url, navigate, setCookie, dispatch);
            if (result.data) {
                formik.resetForm();
                setComment(false);
            }
        },
    });

    useAutosizeTextArea(newtextAreaRef.current, formik.values.comment);

    useEffect(() => {
        if (!!commentData && Object.keys(commentData).length > 0) {
            formik.setFieldValue("comment", commentData.comment);
            formik.setFieldValue("id", commentData.id);
            if (!!commentData.users.length > 0) {
                let userIds = [];
                commentData.users.map(x => userIds.push(x.userId));
                formik.setFieldValue("mentionUserIds", userIds.toString());
            }
            if (!!commentData.attachments.length > 0) {
                let attachmentIds = [];
                commentData.attachments.map(x => attachmentIds.push(x.attachmentId));
                formik.setFieldValue("mentionAttachmentIds", attachmentIds.toString());
            }
            setComment(true);
        }
    }, [commentData]);

    useEffect(() => {
        if (Object.keys(mentionedAttachment).length > 0) {
            formik.setFieldValue('mentionAttachmentIds', !!formik.values.mentionAttachmentIds ? formik.values.mentionAttachmentIds + "," + mentionedAttachment.attachmentId.toString() : mentionedAttachment.attachmentId.toString());
            formik.setFieldValue('comment', !!formik.values.comment ? formik.values.comment + " [" + mentionedAttachment.name + "]" : "[" + mentionedAttachment.name + "]");
            dispatch(setMentionedAttachment({}));
            setComment(true);
        }
    }, [mentionedAttachment]);

    useEffect(() => {
        if (Object.keys(replyComment).length > 0) {
            formik.setFieldValue('mentionUserIds', replyComment.createdBy.toString());
            formik.setFieldValue('comment', !!formik.values.comment ? formik.values.comment + " @" + replyComment.userName.toLowerCase().replace(" ", "") : "@" + replyComment.userName.toLowerCase().replace(" ", ""));
            dispatch(setReplyComment({}));
            setComment(true);
        }
    }, [replyComment]);

    const handleChange = async (e) => {
        if (e.target.files.length > 0) {
            let attachmentData = {
                attachmentId: 0,
                name: "",
                planId: planId,
                attachmentFile: e.target.files[0],
            }
            var form_data = new FormData();
            for (var key in attachmentData) {
                form_data.append(key, attachmentData[key]);
            }
            let url = 'api/comment/createUpdateAttachment';
            let result = await dynamicPostApi(form_data, url, navigate, setCookie, dispatch);
            if (!!result.data && result.data !== 'Fail To Upload Attachment') {
                formik.setFieldValue('mentionAttachmentIds', !!formik.values.mentionAttachmentIds ? formik.values.mentionAttachmentIds + "," + result.data.attachmentId.toString() : result.data.attachmentId.toString());
            }
        }
    }

    const handleSelectUser = async (menUser) => {
        if (Object.keys(menUser).length > 0) {
            formik.setFieldValue('mentionUserIds', !!formik.values.mentionUserIds ? formik.values.mentionUserIds + "," + menUser.userId.toString() : menUser.userId.toString());
            formik.setFieldValue('comment', !!formik.values.comment ? formik.values.comment + " @" + menUser.userName.toLowerCase().replace(" ", "") : "@" + menUser.userName.toLowerCase().replace(" ", ""));
        }
    }

    return (
        <>
            <div>
                <OutsideClickHandler onOutsideClick={() => {
                    if(isUserMentionedPopupOpened == false){
                        setComment(false);
                        console.log("outsidetrigger HO rha")

                    }
                }} >
                    <CommentWrapper>
                        <TextArea
                            placeholder='Schreiben Sie einen Kommentar....'
                            id="comment"
                            name="comment"
                            rows={"2"}
                            value={formik.values.comment}
                            onFocus={() => setComment(true)}
                            onChange={formik.handleChange}
                            ref={newtextAreaRef}
                        >
                        </TextArea>
                        {comment && (
                            <IconsWrapper>
                                <Box className="d-flex justify-content-between align-items-center flex-between-mob px-0-mob flex-col-center-sx m-top-min">
                                    <div>
                                        <Button size='small' className='btn-light practiceSetting-btn-mt' onClick={formik.handleSubmit}>Save</Button>
                                    </div>
                                    <div className='ml-15'>
                                        <div className="d-flex align-items-center flex-between-mob">

                                            <input
                                                accept="image/*"
                                                style={{ display: 'none' }}
                                                id="button-file-upload"
                                                type="file"
                                                ref={InputREf}
                                                onChange={(e) => handleChange(e)}
                                            />
                                            <AttachFileIcon onClick={() => InputREf?.current?.click()} fontSize='inherit' className=' addrightMargin cursorPointer' />
                                            <EmailSearchDropdown setIsUserMentionedPopupOpened={setIsUserMentionedPopupOpened} userList={userList} handleSelectUser={handleSelectUser} />
                                        </div>
                                    </div>
                                </Box>
                            </IconsWrapper>
                        )}
                    </CommentWrapper>
                    {formik.errors.comment && formik.touched.comment ? <Validation>{formik.errors.comment}</Validation> : null}
                </OutsideClickHandler>
            </div>
        </>
    )
}
export default TextboxSaveBtn;

export const EmailSearchDropdown = (props) => {
    const iconRef = useRef(null);

    const { userList, handleSelectUser } = props;

    const [showDropdown, setShowDropdown] = useState(false);
    const [user, setUser] = useState(userList);

    const handleIconClick = () => {
        setShowDropdown(!showDropdown);
    };

    const handleSearchChange = (e) => {
        let val = e.target.value;
        if (!!val) {
            let newUserList = userList.filter(x => x.userName.includes(val));
            setUser(newUserList);
        }
        else {
            setUser(userList);
        }
    };

    const lettersConvert = (text) => {
        var myStr = text;
        var matches = myStr.match(/\b(\w)/g);
        return matches.join('');
    }

    //========> Popover Begin
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
        setShowDropdown(true);
        setAnchorEl(event.currentTarget);
        props.setIsUserMentionedPopupOpened(true);
    };
    const handleClose = () => {
        setAnchorEl(null);
        props.setIsUserMentionedPopupOpened(false)
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    //========> Popover End

    return (
        <>
            <div ref={iconRef} className="position-r">
                {/* <IconsWrapper><AlternateEmailIcon fontSize='inherit' className='cursorPointer' onClick={handleClick} /></IconsWrapper> */}
                <Box
                    aria-describedby={id}
                    aria-controls={open ? 'simple-popover' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined} variant="contained" onClick={handleClick}>
                    <IconsWrapper><AlternateEmailIcon fontSize='inherit' className='cursorPointer' /></IconsWrapper>
                </Box>
                <div>

                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        sx={{ backgroundColor: '#fff0 !important', boxShadow: 'none !important;' }}
                        className="Popover-customizing"
                    >
                        {showDropdown && (
                            <DropdownWrapperDiv>
                                <div className='d-flex'>
                                    <Search bgColor="rgb(248,246,248)">
                                        <SearchIconWrapper>
                                            <SearchIcon />
                                        </SearchIconWrapper>
                                        <StyledInputBase
                                            placeholder="Suche"
                                            inputProps={{ 'aria-label': 'search' }}
                                            onChange={(e) => handleSearchChange(e)}
                                        />
                                    </Search>
                                </div>
                                {user.map((x) => (
                                    <UserListWrapper key={x.userId} onClick={() => handleSelectUser(x)}>
                                        <div>
                                            <IconTextSearch>
                                                <Typography variant='p' component='div' sx={{ fontWeight: '600', color: '#fff', textTransform: 'uppercase', fontSize: '12px' }}>{lettersConvert(x.userName)}</Typography>
                                            </IconTextSearch>
                                        </div>
                                        <div>
                                            <p className='mt-0px userText'>{x.userName}</p>
                                        </div>
                                    </UserListWrapper>
                                ))}
                            </DropdownWrapperDiv>
                        )}
                    </Popover>
                </div>
                {/* {showDropdown && (
                    <DropdownWrapperDiv>
                        <div className='d-flex'>
                            <Search bgColor="rgb(248,246,248)">
                                <SearchIconWrapper>
                                    <SearchIcon />
                                </SearchIconWrapper>
                                <StyledInputBase
                                    placeholder="Suche"
                                    inputProps={{ 'aria-label': 'search' }}
                                    onChange={(e) => handleSearchChange(e)}
                                />
                            </Search>
                        </div>
                        {user.map((x) => (
                            <UserListWrapper key={x.userId} onClick={() => handleSelectUser(x)}>
                                <div>
                                    <IconTextSearch>
                                        <Typography variant='p' component='div' sx={{ fontWeight: '600', color: '#fff', textTransform: 'uppercase', fontSize: '12px' }}>{lettersConvert(x.userName)}</Typography>
                                    </IconTextSearch>
                                </div>
                                <div>
                                    <p className='mt-0px userText'>{x.userName}</p>
                                </div>
                            </UserListWrapper>
                        ))}
                    </DropdownWrapperDiv>
                )} */}
            </div>
        </>
    )
}