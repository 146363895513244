import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import ModalLeftSidebar from './ModalLeftSide';
import ListItem from '@mui/material/ListItem';
import Checkboxes, { CheckedIcon, UnCheckedIcon } from '../Inputs/Checkboxes';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { CloseLeftCornor } from '../CloseIconWrap';
import { dynamicGetApi } from './../../Api';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import BoltIcon from '@mui/icons-material/Bolt';
import Checkbox from '@mui/material/Checkbox';
import { Box, Typography } from '@mui/material';
import NotesModal, { EditBtn, EditBtnWrapper, EditBtnPosition } from './NotesModal';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import CommentsModal from './CommentsModal';




function AbrechnungModal(props) {
    const [cookies, setCookie, removeCookie] = useCookies(['authToken']);
    let navigate = useNavigate();
    let dispatch = useDispatch();

    const { handleClose, planId } = props;

    const [modalData, setModalData] = useState({});
    const [showNotesMoadel, setShowNotesMoadel] = useState(false);
    const [comment, setComment] = useState(false);

    useEffect(() => {
        if (!!planId) {
            getData(planId);
        }
    }, [planId]);

    const getData = async (planId) => {
        let url = "api/kanban/getKanbanItemById?itemId=" + planId;
        let res = await dynamicGetApi(url, navigate, setCookie, dispatch);
        setModalData(res.data);
    }

    return (
        <>
        {comment && <CommentsModal setOpen={setComment} open={comment} planId={modalData.id} />}
            {/* {showNotesMoadel && <NotesModal planId={modalData.id} openNotesMoadel={showNotesMoadel} setOpenNotesModal={setShowNotesMoadel} />} */}
            <div>
                <Grid container spacing={4} className="grid-main">
                    <Grid item md={4} sm={12} xs={12} className="grid-4-col">
                        <div onClick={handleClose}>
                            <CloseLeftCornor />
                        </div>
                        <ModalLeftSidebar modalData={modalData} />
                    </Grid>
                    <Grid item md={8} sm={12} xs={12} className='model-p-mob'>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography variant="h4" component="div" className='title-model-right'>
                                Abrechnung
                            </Typography>
                            <div className="EditBtnPlan">
                                <EditBtnWrapper>
                                    <EditBtn onClick={() => setComment(true)}>
                                        <BorderColorIcon />
                                    </EditBtn>
                                </EditBtnWrapper>
                            </div>
                        </Box>
                        <ListItem sx={{ pl: 0 }}>
                            <Box>
                                <FormGroup>
                                    <FormControlLabel sx={{ marginLeft: '10px' }} disabled control={<Checkbox style={{ fontSize: 28, padding: "5px 10px 5px 5px" }} checkedIcon={<CheckedIcon />} icon={<UnCheckedIcon><BoltIcon sx={{ fontSize: '22px', color: '#ffb800' }} className='thunder-check' /></UnCheckedIcon>} />} label="Plan abrechnen" />
                                </FormGroup>
                            </Box>
                        </ListItem>

                    </Grid>
                </Grid>
            </div>
        </>
    )
}
export default AbrechnungModal;