import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import ForgetPassword from './ForgetPassword';
import EmailConfirmation from './EmailConfirmation';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { dynamicPostApi } from './../Api';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';


function ResetPassword() {
    const [cookies, setCookie, removeCookie] = useCookies(['authToken']);
    let dispatch = useDispatch();
    let navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            email: '',
            token: '',
            password: '',
            cpassword: ''
        },
        validationSchema: Yup.object().shape({
            password: Yup.string().required("Please enter Password"),
            cpassword: Yup.string().required("This field is required").oneOf([Yup.ref('password'), null], 'Passwords must match')
        }),
        onSubmit: async values => {
            delete values['cpassword'];
            let url = "api/account/setPassword";
            let result = await dynamicPostApi(values, url, navigate, setCookie,dispatch);
            if (result.data) {
                Swal.fire({
                    title: 'Password Changed',
                    text: "Password changed successfully!",
                    icon: 'success',
                    confirmButtonColor: '#3085d6',
                  }).then((result) => {
                    if (result.isConfirmed) {
                        navigate('/');
                    }
                  })
            }
        },
    });

    useEffect(() => {
        let currUrl = window.location.href;
        formik.setFieldValue("email", currUrl.split('&')[0].split('=')[1]);
        formik.setFieldValue("token", currUrl.split('&')[1].split('=')[1]);
    }, []);

    const loginCard = {
        boxShadow: '1px 1px 20px #0000001f',
    }

    return (
        <>
            <Container component="main" maxWidth="xs" className='w-md-550px login-mt'>
                <CssBaseline />
                <Card maxWidth="xs" style={loginCard} sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    borderRadius: '16px',
                }}>
                    <CardContent>
                        <Box>
                            <Box component="form" noValidate sx={{ mt: 1, px: 1 }}>
                                <Typography>
                                <Box sx={{ fontSize: 'h3.fontSize', textAlign: 'center', fontWeight: '700', color: '#120ec5', my: 4 }} className="Login-Heading">HKP-Hero</Box>
                                </Typography>

                                <FormLabel id="password">Passwort</FormLabel>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    placeholder='Passwort'
                                    name="password"
                                    type="password"
                                    id="password"
                                    size="small"
                                    sx={{ height: '45px' }}
                                    error={formik.errors.password && formik.touched.password ? true : false}
                                    helperText={formik.errors.password && formik.touched.password ? formik.errors.password : ""}
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                />

                                <FormLabel id="confirm-password">Confirm Passwort</FormLabel>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    placeholder='Confirm Passwort'
                                    name="cpassword"
                                    type="password"
                                    id="confirm-password"
                                    size="small"
                                    sx={{ height: '45px' }}
                                    error={formik.errors.cpassword && formik.touched.cpassword ? true : false}
                                    helperText={formik.errors.cpassword && formik.touched.cpassword ? formik.errors.cpassword : ""}
                                    value={formik.values.cpassword}
                                    onChange={formik.handleChange}
                                />

                                <Button
                                    type="button"
                                    fullWidth
                                    variant="contained"
                                    onClick={formik.handleSubmit}
                                    sx={{ mt: 6, mb: 3, py: '10px', borderRadius: '20px', backgroundColor: '#120ec5', textTransform: 'capitalize', "&:hover": { backgroundColor: '#090699' } }}
                                >
                                    Passwort festlegen
                                </Button>
                                <Box sx={{ display: 'flex', justifyContent: 'center', color: '#120ec5', mb: 3 }}>
                                    <Link variant="body2" className='over-underline' onClick={() => navigate("/")}>
                                        Zurück zur Anmeldung
                                    </Link>
                                </Box>
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
            </Container >

        </>
    );
}

export default ResetPassword;