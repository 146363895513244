import axios from "axios";
import { SERVER_URL, AWS_BUCKET_URL } from './Config';
import Swal from 'sweetalert2';
import { setUserName, setUserImage } from './Redux/Slice/userSlice';
import { setLoader } from './Redux/Slice/commonSlice';
import { saveAs } from 'file-saver';

export async function dynamicPostApi(data, Url, useNavigate, setCookie, dispatch) {
  dispatch(setLoader(true));
  const token = getCookie("authToken");
  return await axios({
    method: "Post",
    url: SERVER_URL + Url,
    headers: {
      Authorization: "Bearer " + token,
    },
    data: data
  })
    .then((response) => {
      dispatch(setLoader(false));
      return response;
    })
    .catch(function (error) {
      if (!!error.response) {
        if (error.response.status === 401) {
          useNavigate("/");
        }
        else if (error.response.status === 400) {
          debugger
          Swal.fire({
            icon: 'error',
            title: error.response.data,
          })
        }
        else if (error.response.status === 500) {
          Swal.fire({
            icon: 'error',
            title: 'Something went wrong...',
          })
        }
      }
    })
    .finally(() => {
      setTimeout(() => {
        dispatch(setLoader(false));
      }, 1000);
    });
}

export async function dynamicGetApi(Url, useNavigate, setCookie, dispatch) {
  dispatch(setLoader(true));
  const token = getCookie("authToken");
  return await axios({
    method: "Get",
    url: SERVER_URL + Url,
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {
      return response;
    })
    .catch(function (error) {
      dispatch(setLoader(false));
      if (error.response.status === 401) {
        useNavigate("/");
      }
      else if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: error.response.data,
        })
      }
      else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: 'Something went wrong...',
        })
      }
    })
    .finally(() => {
      setTimeout(() => {
        dispatch(setLoader(false));
      }, 1000);
    });
}

export async function loginApi(data, usecookie, useNavigate, setCookie, dispatch) {
  dispatch(setLoader(true));
  return await axios({
    method: "Post",
    url: SERVER_URL + "api/account/login",
    data: data
  })
    .then((response) => {
      if (response.status === 200) {
        usecookie('authToken', response.data.token, { path: '/', maxAge: 7200 });
        useNavigate("/home");
        dispatch(setUserName(response.data.userName));
        dispatch(setUserImage(AWS_BUCKET_URL + response.data.userImage));
        localStorage.setItem("UserName", response.data.userName);
        localStorage.setItem("UserImage", AWS_BUCKET_URL + response.data.userImage);
        localStorage.setItem("userRole", response.data.userRole);
        localStorage.setItem("practiceName", response.data.practiceName);
      }
      return response;
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        useNavigate("/login");
      }
      else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: 'Something went wrong...',
        })
      }
      else if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Incorrect username or password.',
        })
      }
    })
    .finally(() => {
      setTimeout(() => {
        dispatch(setLoader(false));
      }, 1000);
    });
}

export async function dynamicGetFileApi(Url, useNavigate, setCookie, dispatch, type, name) {
  dispatch(setLoader(true));
  const token = getCookie("authToken");
  return await axios({
    method: "Get",
    url: SERVER_URL + Url,
    headers: {
      Authorization: "Bearer " + token,
    },
    responseType: 'blob'
  })
    .then((res) => {
      const file = new Blob([res.data], { type: type });
      saveAs(file, name);
    })
    .catch(function (error) {
      dispatch(setLoader(false));
      if (error.response.status === 401) {
        useNavigate("/");
      }
      else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: 'Something went wrong...',
        })
      }
    })
    .finally(() => {
      setTimeout(() => {
        dispatch(setLoader(false));
      }, 1000);
    });
}

function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function AppLogout(removeCookie, useNavigate, setCookie) {
  removeCookie("authToken");
  removeCookie("selectedVersion");
  useNavigate("/");
  localStorage.removeItem("UserName",);
  localStorage.removeItem("UserImage",);
  localStorage.removeItem("userRole",);
  localStorage.removeItem("practiceName");
}