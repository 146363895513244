import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  userName: "",
  userimg: "",
  userimg1: "https://www.pngarts.com/files/6/User-Avatar-in-Suit-PNG.png",
  seletedUserId : null
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserName: (state, action) => {
      state.userName = action.payload
    },
    setUserImage:(state, action) => {
      state.userimg = action.payload
    },
    setSeletedUserId:(state, action) => {
      state.seletedUserId = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setUserName, setUserImage, setSeletedUserId } = userSlice.actions;

export default userSlice.reducer