import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoader: false,
  recall: false,
  isEdit: false,
  applyFilter: false,
  planFilter: "",
  practitionerFilter: "",
  valueFilter: "",
  timeFilter: "",
  sortFilter: "",
  disableButton: true,
  planStatusChanged: {},
  behandlungShiftCheck: false,
  popOver: false,
  popOverPatientName: '',
  isPrivatePlan: false,
  delayPlan: 2,
  searchValue: '',
  signalRconnection: '',
  seletedClinicId: null,
  conversationSearch: ''
}

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setLoader: (state, action) => {
      state.isLoader = action.payload
    },
    setRecall: (state, action) => {
      state.recall = action.payload
    },
    setIsEdit: (state, action) => {
      state.isEdit = action.payload
    },
    setApplyFilter: (state, action) => {
      state.applyFilter = action.payload
    },
    setPlanFilter: (state, action) => {
      state.planFilter = action.payload
    },
    setPractitionerFilter: (state, action) => {
      state.practitionerFilter = action.payload
    },
    setValueFilter: (state, action) => {
      state.valueFilter = action.payload
    },
    setTimeFilter: (state, action) => {
      state.timeFilter = action.payload
    },
    setSortFilter: (state, action) => {
      state.sortFilter = action.payload
    },
    setDisableButton: (state, action) => {
      state.disableButton = action.payload
    },
    setPlanStatusChanged: (state, action) => {
      state.planStatusChanged = action.payload
    },
    setBehandlungShiftCheck: (state, action) => {
      state.behandlungShiftCheck = action.payload
    },
    setPopOver: (state, action) => {
      state.popOver = action.payload
    },
    setPopOverPatientName: (state, action) => {
      state.popOverPatientName = action.payload
    },
    setIsPrivatePlan: (state, action) => {
      state.isPrivatePlan = action.payload
    },
    setDelayPlan: (state, action) => {
      state.delayPlan = action.payload
    },
    setSearchValue: (state, action) => {
      state.searchValue = action.payload
    },
    setSignalRconnection: (state, action) => {
      state.signalRconnection = action.payload
    },
    setSeletedClinicId: (state, action) => {
      state.seletedClinicId = action.payload
    },
    setConversationSearch: (state, action) => {
      state.conversationSearch = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setLoader, setRecall, setIsEdit, setApplyFilter, setPlanFilter, setPractitionerFilter, setValueFilter, setTimeFilter,
  setSortFilter, setDisableButton, setPlanStatusChanged, setBehandlungShiftCheck, setPopOver, setPopOverPatientName, setIsPrivatePlan,
  setDelayPlan, setSearchValue, setSignalRconnection, setSeletedClinicId, setConversationSearch } = commonSlice.actions;

export default commonSlice.reducer