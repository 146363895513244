import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import styled from 'styled-components';
import { theme } from '@mui/material/styles';
import { DropdownWrapper } from '../HeaderDropdownWrapper';




function PlanungPopover(props) {

    const{planungPopover, closePlanungPopover} = props;

    const CardHover = {
        maxWidth: '330px',
       }

    


    return ( 
        <>
         <div>
            <Popover
                id="mouse-over-popover"
                sx={{
                pointerEvents: 'none',
                }}
                open={planungPopover}
                anchorEl={planungPopover}
                anchorOrigin={{
                vertical: 'right',
                horizontal: 'right',
                }}
                transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
                }}
                onClose={() => closePlanungPopover("Planung")}
                marginThreshold={15}
                disableAutoFocus={true}
                disableEnforceFocus={true}
                className='CustomPopoverStyle'
            >
                <div style={CardHover}>
                    <div alignItems="flex-start">
                        <CardMedia
                        component="img"
                        height="auto"
                        sx={{width: '100px'}}
                        image="/images/planning-img.png"
                        alt="Planung Image"
                        />
                    </div>
                    <CardContent sx={{py:'0.5px !important',}}>
                        <Typography gutterBottom variant="h5" component="div" style={{fontWeight: 600}}>
                        Planung
                        </Typography>
                        <Typography variant="body2" color="" className='fs-17'>
                        Von der Planerstellung bis zur Übergabe an den Patienten oder die Kasse.
                        </Typography>
                        <Timeline position="right" sx={{mb: 0, pl: 0, pr: 0}}>
                            <TimelineItem className='timeline-no'>
                                <TimelineSeparator>
                                <TimelineDot className='card-dots badge-text-20'>
                                <span>1</span> 
                                </TimelineDot>
                                <TimelineConnector className='card-connect' />
                                </TimelineSeparator>
                                <TimelineContent className='fs-17' sx={{pt: 0, pr: 0, lineHeight: 1.3}}>Ankaufsvoranfragen stellen, Vorabzahlungen regeln und Einverständniserklärungen erneuern.</TimelineContent>
                            </TimelineItem>
                            <TimelineItem className='timeline-no'>
                                <TimelineSeparator>
                                <TimelineDot className='card-dots badge-text-20'>
                                <span>2</span>
                                </TimelineDot>
                                <TimelineConnector className='card-connect' />
                                </TimelineSeparator>
                                <TimelineContent className='fs-17' sx={{pt: 0, pr: 0, lineHeight: 1.3}}>Kostenvoranschläge für Laborkosten einholen und in die Planaufstellung übernehmen.</TimelineContent>
                            </TimelineItem>
                            <TimelineItem className='timeline-no'>
                                <TimelineSeparator>
                                <TimelineDot className='card-dots badge-text-20'>
                                <span>3</span>
                                </TimelineDot>
                                </TimelineSeparator>
                                <TimelineContent className='fs-17' sx={{pt: 0, pr: 0, lineHeight: 1.3}}>Plan drucken und dokumentieren, an wen der Plan ging.</TimelineContent>
                            </TimelineItem>
                        </Timeline>
                    </CardContent>
                </div>
            </Popover>
        </div>
        </>
     );
}

export default PlanungPopover;