import * as React from 'react';
import { styled } from '@mui/material/styles';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { ListItem } from '../HeaderDropdownWrapper';
import Avatar from '../Avatar';
import {AWS_BUCKET_URL} from './../../Config';




const UnCheckedIcon = styled('span')(({ theme }) => ({
    borderRadius: '50%',
    width: 18,
    height: 18,
    border:`2px solid #dadada`,
    backgroundColor: '#fff',
}));

const CheckedIcon = styled(UnCheckedIcon)({
  backgroundColor: '#fff',
  border:`2px solid #dadada`,
  '&:before': {
    display: 'block',
    width: 18,
    height: 18,
    backgroundImage: 'radial-gradient(#120ec5,#120ec5 28%,transparent 32%)',
    content: '""',
  }
});

// Inspired by blueprintjs

function CustomRadioButton(props) {
  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<CheckedIcon />}
      icon={<UnCheckedIcon />}
      {...props}
    />
  );
}



export default function CustomRadio(props) {
    const {radioList,defaultValue , showImage,onChange} = props;
    return (
      <FormControl>
        <RadioGroup
          value={defaultValue}
          aria-labelledby="demo-customized-radios"
          name="customized-radios"
          onChange={onChange}
        >
            {radioList?.map(x => 
             <ListItem className="radioBUtoonListItem">
              {showImage ? 
               <FormControlLabel value={x?.value} control={<CustomRadioButton />} label={<div className='d-flex align-items-center'><Avatar src={AWS_BUCKET_URL+x?.userImage} noMargin width={16} height={16} /> <span style={{marginLeft:"5px"}}>{x?.label}</span></div>} />
               : 
               <FormControlLabel value={x?.value} control={<CustomRadioButton />} label={x?.label} />
              }

            </ListItem>
          )}
        </RadioGroup>
      </FormControl>
    );
  }