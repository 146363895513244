import * as React from 'react';
import styled from 'styled-components';
import Avatar from './Avatar';
import { AWS_BUCKET_URL } from './../Config';
import moment from "moment";


const CardWrapper = styled.div`
background:#fff;
border-radius:10px;
padding:15px;
cursor:pointer;
&:hover{
  box-shadow: 0 2px 3px #c9c9c9;

}
`
const CardTitle = styled.div`
font-size:16px;
font-weight:500;
margin-bottom:5px;

`
const CardPrice = styled.div`
color:#747474;
margin-bottom:15px;
`
const LightText = styled.div`
color:#747474;
&.font-14{
  font-size:14px;
}
`

function KanbanCard(props) {
  return (
    <CardWrapper>
      <CardTitle>
        {props.cardData.patientName}
      </CardTitle>
      <CardPrice>
        {(props.cardData.planAmount).toLocaleString('de', {style: 'currency', currency: 'Eur'})}
      </CardPrice>
      <div className='d-flex align-items-center justify-content-between'>
        <div>
          <LightText className='font-14'>
            {props.cardData.planName}
          </LightText>
          <LightText className='font-14'>
            {moment(new Date(props.cardData.createdAt)).format("MMMM YYYY")}
          </LightText>
        </div>
        <Avatar src={AWS_BUCKET_URL + props.cardData.practitionerImage} noMargin width={30} height={30} />
      </div>
    </CardWrapper>
  )
}
export default KanbanCard;